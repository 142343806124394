// React and External Libraries
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

// MUI Components
import {
  Autocomplete as MuiAutocomplete,
  OutlinedInput,
  TextField as MuiTextField,
  ThemeProvider
} from '@mui/material';

// Theme Imports
import themeCriteria from 'theme/criteria';

// Style and asset imports
import {rootStyle} from './styles/reactHookForm';

const CustomTextField = React.forwardRef(({ error, onChange, name, ...props }, ref) => (
  <MuiTextField
    ref={ref}
    id={name} // Makes this component testable
    name={name}
    error={Boolean(error)}
    helperText={error}
    inputProps={{ style: { height: 35 } }}
    style={{ paddingTop: 5, paddingBottom: error ? 0 : 23 }}
    onChange={onChange}
    {...props}
  />
));

const TextField = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <ThemeProvider theme={themeCriteria}>
      <Controller
        name={name}
        control={control}
        defaultValue=''
        fullWidth={true}
        render={({ field }) => <CustomTextField margin='dense' type='text' {...field} {...props} />}
      />
    </ThemeProvider>
  );
};

const NumericSelect = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      variant='outlined'
      fullWidth
      render={({ field }) => (
        <CustomTextField
          InputProps={{ rootStyle }}
          margin='none'
          type='number'
          variant='outlined'
          {...field}
          {...props}
        />
      )}
    />
  );
};

const ColorField = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      fullWidth
      render={({ field }) => (
        <OutlinedInput style={{ height: 56 }} margin='none' type='color' {...field} {...props} />
      )}
    />
  );
};

const SelectField = ({ name, error, ...props }) => {
  const { control } = useFormContext();

  return (
    <ThemeProvider theme={themeCriteria}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value = '', onChange } }) => {
          return (
            <CustomTextField
              {...props}
              value={value}
              onChange={onChange}
              select
              id={name} // Makes this component testable
              name={name}
              error={error}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};

const Autocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  onChange: ignored,
  defaultValue,
  name,
  renderOption,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ onChange, ...props }) => (
        <MuiAutocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

export { TextField, NumericSelect, ColorField, SelectField, Autocomplete };
