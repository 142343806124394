// import {isArrayEqual} from '@/utils/array';
import _ from 'lodash';
import { formulaBuilderTypes } from './constants';

export const schemeChanged = function (newScheme, oldScheme) {
  // if first time save..
  if (oldScheme === null && newScheme) {
    return true;
  }

  if (oldScheme.name !== newScheme.name) {
    return true;
  }

  // collection
  if (oldScheme.collection !== newScheme.collection) {
    return true;
  }

  if (oldScheme.weight !== newScheme.weight) {
    return true;
  }

  // matchCase. This also includes filters check.
  if (!_.isEqual(oldScheme.match, newScheme.match)) {
    return true;
  }

  if (!_.isEqual(oldScheme.schemeCollectionConfig, newScheme.schemeCollectionConfig)) {
    return true;
  }

  if (!_.isEqual(oldScheme.aggregation, newScheme.aggregation)) {
    return true;
  }

  if (!_.isEqual(oldScheme.prioritizationCustomVariables, newScheme.prioritizationCustomVariables)) {
    return true;
  }

  if (oldScheme.bin_size !== newScheme.bin_size) {
    return true;
  }

  // policies
  if (newScheme.policies.length !== oldScheme.policies.length) {
    return true;
  } else if (newScheme.policies.length === oldScheme.policies.length) {

    const diff = oldScheme.policies.filter((d, i) => {
      return !_.isEqual(d, newScheme.policies[i]);
    }).map(d => d.id);
    return diff.length ? diff : false;
  }

  return false;
};

export const validateFormula = (formula, type) => {
  let valid = true

  if (formula !== '') {
    let formulaArray = formula.split('')
    let lastElement = formulaArray[formulaArray.length - 1]    
    switch (type) {
      case formulaBuilderTypes.TYPE_VARIABLE:
        if (lastElement === '"' || lastElement === ")" || !isNaN(lastElement)) {
          valid = false
        }
        break
      case formulaBuilderTypes.TYPE_OPERATOR:
        if (lastElement !== '"' && lastElement !== ')' && isNaN(lastElement)) {
          valid = false;
        }
        break
      case formulaBuilderTypes.TYPE_NUMBER:
        if (lastElement === '"' || lastElement === ")") {
          valid = false
        }
        break
      case formulaBuilderTypes.TYPE_FUNCTION:
        if (lastElement === '"' || lastElement === ")" || !isNaN(lastElement)) {
          valid = false
        }
        break
      default:
        break
    }
  }

  return valid
}

export const getVariableLabel = (variables, propName) => {
  let variable = variables.find(v => v.propName === propName);
  return variable && variable.label ? variable.label : propName
}
