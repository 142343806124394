// External library imports
import { useMutation, useQueryClient } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const newOrganization = async (data) =>
  axios.post(apiPaths.orgs, data, status200)
       .then((resp) => resp.data);

const useCreateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation((organization) => newOrganization(organization), {
    onSuccess: () => queryClient.resetQueries('organizations'),
    onError: (error) => error,
    onSettled: () => queryClient.invalidateQueries('organizations')
  });
};

export default useCreateOrganization;
