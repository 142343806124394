// External library imports
import { Button, Grid, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

// Style and asset imports
import {
  MuiContainerWrapper,
  MuiGridAction,
  MuiGridWrapper
} from './styles/error';

const NotFound = () => {

  return (
    <MuiContainerWrapper>
      <MuiGridWrapper
        container
        direction='column'
        spacing={2}
        justifyContent='center'
        alignContent='space-between'
      >
        <Grid item>
          <Typography variant='h5'>Error 404</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h3'>
            Whoops!
            <br />
            You’re lost at sea
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            The page you’re looking for doesn’t exist.
            <br />
            Use Location Intelligence to find your way home.
          </Typography>
        </Grid>
        <MuiGridAction item>
          <Link to='/' component={NavLink} underline='none'>
            <Button variant='contained' color='primary' size='large'>
              Take me home
            </Button>
          </Link>
        </MuiGridAction>
      </MuiGridWrapper>
    </MuiContainerWrapper>
  );
}

export default NotFound;
