// External library imports
import { scaleLinear } from 'd3-scale';
import { drag } from 'd3-drag';
import { pointer, selectAll } from 'd3-selection';
import tippy from 'tippy.js';

// Internal component and function imports
import getCustomLabel from '../../../utils/custom-labels';
import { formatKilo } from '../../../utils/formatters';
import { dollarSign, filterSign, percentIcon, tickCrossIcon } from '../../../utils/constants';

const renderSlider = (node) => {
  let width = node.width || 400;
  let height = node.height || 8;
  let container = node.container;
  let sliderGroup, sliderValue, circleGroup, progressRect, iconImage;
  let direction = node.direction || 'h';
  let sections = node.sections || [];
  let formatThousands = node.formatThousands || false;
  let icon = node.icon || null;
  let tooltip = node.tooltip || null; // this should either be an html string or null
  let priority = node.priority;
  let showProgress = node.showProgress || false;
  let color = node.color || '#1BBC9B';
  let tippyTooltip;

  const scale = scaleLinear().domain([node.min, node.max]).clamp(true);

  let currentValue = node.value;
  let trackWidth, trackHeight;

  let onChange = function () {};

  function main() {
    // calculation parameters
    let range, translation, backRectWidth, backRectHeight;

    if (direction === 'h') {
      range = [0, width];
      translation = [0, -height / 2];
      backRectWidth = width;
      backRectHeight = 30;
      trackWidth = width;
      trackHeight = 8;
    } else {
      range = [height, 0];
      translation = [-width / 2, 0];
      backRectWidth = 30;
      backRectHeight = height;
      trackWidth = 8;
      trackHeight = height;
    }

    scale.range(range);

    let currentTranslation = direction === 'h' ? [scale(currentValue), 0] : [0, scale(currentValue)];

    sliderGroup = container
      .append('g')
      .attr('class', 'slider')
      .attr('transform', `translate(${node.x || 0}, ${node.y || 0})`)
      .attr('opacity', node.visible ? 1 : 0)
      .attr('pointer-events', node.visible ? 'all' : 'none');

    if (icon) {
      const iconStr = getIconImageString();

      if (iconStr) {
        var iconSize = height + 5;

        iconImage = sliderGroup
          .append('image')
          .attr('xlink:href', iconStr)
          .attr('width', iconSize)
          .attr('height', iconSize)
          .attr('x', -iconSize - 10)
          .attr('y', -iconSize + 3);
      }
    }

    let slider = sliderGroup.append('g').attr('transform', `translate(${translation})`);

    let backRect = slider
      .append('rect')
      .attr('width', backRectWidth)
      .attr('height', backRectHeight)
      .attr('y', direction === 'h' ? -backRectHeight / 2 : 0)
      .attr('x', direction === 'h' ? 0 : -backRectWidth / 2)
      .attr('fill', 'transparent');

    slider
      .append('rect')
      .attr('width', trackWidth)
      .attr('height', trackHeight)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('x', direction === 'h' ? 0 : -4)
      .attr('y', direction === 'h' ? -4 : 0)
      .attr('fill', '#E1E5EC')
      .attr('cursor', 'pointer')
      .on('click', slide);

    if (showProgress) {
      progressRect = slider
        .append('rect')
        .attr('width', () => {
          var x = scale(currentValue);

          return priority === 'low' ? x : trackWidth - x;
        })
        .attr('x', priority === 'low' ? 0 : scale(currentValue))
        .attr('height', trackHeight)
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('y', -4)
        .attr('pointer-events', 'none')
        .attr('fill', color);
    }

    circleGroup = slider.append('g').attr('transform', `translate(${currentTranslation})`);

    circleGroup
      .append('circle')
      .attr('class', 'slider-thumb')
      .attr('id', node.nodeId + node.type)
      .attr('data-cy', 'amount-slider')
      .attr('r', 12)
      .attr('fill', '#fff')
      .attr('stroke', color)
      .attr('stroke-width', 1.5)
      .attr('cursor', 'pointer')
      .on('click', function () {
        selectAll('.slider-thumb').attr('tabindex', null);
        this.setAttribute('tabindex', 0);
        this.focus();
      })
      .on('focusin focus', function () {
        selectAll('.slider-thumb-outline').remove();

        circleGroup
          .append('circle')
          .attr('class', 'slider-thumb-outline')
          .attr('fill', 'transparent')
          .attr('r', 14.1)
          .attr('stroke', '#000')
          .attr('stroke-width', 3)
          .attr('stroke-opacity', 0.2)
          .attr('pointer-events', 'none');
      })
      .on('focusout blur', function () {
        selectAll('.slider-thumb-outline').remove();
      })
      .on('keydown', function (event) {
        if (event.key === 'ArrowRight') {
          if (direction === 'h') {
            currentValue = Math.min(currentValue + 1, node.max);
            updateSliderValue(currentValue);
          }
        } else if (event.key === 'ArrowLeft') {
          if (direction === 'h') {
            currentValue = Math.max(currentValue - 1, node.min);
            updateSliderValue(currentValue);
          }
        }
      })
      .call(drag().on('drag', slide));

    const template = `
        <div id='template-input-slider${node.nodeId + node.type}'>
          <input type='number' name='input-slider' id='input-slider${node.nodeId + node.type}' max="${node.max}" min="${node.min}"/>
          <button id='apply${node.nodeId + node.type}'>Apply</button>
        </div>
      `;
    tippy('#' + node.nodeId + node.type, {
      content: template,
      trigger: 'click',
      allowHTML: true,
      interactive: true,
      appendTo: document.body,
      placement: 'bottom-start',
      onShown(instance) {
        let inputS = instance.popper.querySelector('#input-slider' + node.nodeId + node.type);
        let button = instance.popper.querySelector('#apply' + node.nodeId + node.type);
        button.addEventListener('click', function () {
          var value = Number(inputS.value);
          if (value > node.min && value < node.max) {
            updateSliderValue(value);
            instance.hide();
          } else {
            inputS.value = '';
          }
        });
      },
    });

    sliderValue = circleGroup
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('y', 4)
      .attr('font-size', formatThousands ? '9px' : '10px')
      .attr('pointer-events', 'none')
      .text(() => {
        let num = Math.ceil(currentValue);

        return formatThousands ? formatKilo(num) : getCustomLabel(num, sections);

      });

    if (tooltip) {
      tippyTooltip = tippy(sliderGroup.node(), {
        theme: 'light-border',
        placement: 'left',
        interactive: false,
        arrow: true,
        content: tooltip,
      });

      window.tooltips.push(tippyTooltip);
    }

    function slide(event) {
      let cx = pointer(event, backRect.node())[direction === 'h' ? 0 : 1];

      let comparator = direction === 'h' ? width : height;

      if (cx < 0) cx = 0;
      else if (cx > comparator) cx = comparator;

      currentValue = scale.invert(cx);

      updateSliderValue(currentValue);
    }

    function updateSliderValue(currentValue) {
      let x = direction === 'h' ? scale(currentValue) : 0;
      let y = direction === 'h' ? 0 : scale(currentValue);

      let text = formatThousands
        ? formatKilo(Math.ceil(currentValue))
        : getCustomLabel(Math.ceil(currentValue), sections);

      sliderValue.text(text);

      circleGroup.attr('transform', `translate(${x}, ${y})`);

      if (progressRect) {
        progressRect
          .attr('width', () => {
            var x = scale(currentValue);
            return priority === 'low' ? x : trackWidth - x;
          })
          .attr('x', priority === 'low' ? 0 : scale(currentValue));
      }

      onChange(currentValue);
    }

    return main;
  }

  function getIconImageString() {
    let iconStr;

    if (icon === 'dollar' || icon === '$') {
      iconStr = dollarSign;
    } else if (icon === 'filter') {
      iconStr = filterSign;
    } else if (icon === 'percent' || icon === '%') {
      iconStr = percentIcon;
    } else if (icon === 'tick-cross' || icon === '✓/x') {
      iconStr = tickCrossIcon;
    }

    return iconStr;
  }

  main.updateIcon = function (_icon) {
    node.icon = _icon;
    icon = _icon;
    const iconStr = getIconImageString();
    if (iconStr && iconImage) {
      iconImage.attr('xlink:href', iconStr);
    }
    return main;
  };

  main.updateMinMax = function (min, max) {
    node.min = min;
    node.max = max;
    scale.domain([node.min, node.max]);
    return main;
  };

  main.updateTooltip = function (text) {
    tooltip = text;
    tippyTooltip.setContent(tooltip);
    return main;
  };

  main.update = function (value) {
    currentValue = value;

    sliderValue.text(getCustomLabel(Math.ceil(currentValue), sections));

    let x = direction === 'h' ? scale(currentValue) : 0;
    let y = direction === 'h' ? 0 : scale(currentValue);

    circleGroup.attr(
      'transform',
      `translate(
            ${x},
            ${y}
        )`
    );

    if (progressRect) {
      progressRect
        .attr('width', () => {
          let x = scale(currentValue);
          return priority === 'low' ? x : trackWidth - x;
        })
        .attr('x', priority === 'low' ? 0 : scale(currentValue));
    }
    return main;
  };

  main.updateColor = function (_color) {
    color = _color ? _color : '#1BBC9B';


    if (progressRect) {
      progressRect.attr('fill', color);
    }

    circleGroup.select('circle').attr('stroke', color);

    return main;
  };

  main.onChange = function (f) {
    onChange = f;
    return main;
  };

  main.show = function () {
    node.visible = true;
    sliderGroup.attr('opacity', 1).attr('pointer-events', 'all');
  };

  main.hide = function () {
    node.visible = false;
    sliderGroup.attr('opacity', 0).attr('pointer-events', 'none');
  };

  main.translate = function ([x, y]) {
    node.x = x;
    node.y = y;

    sliderGroup.transition().duration(750).attr('transform', `translate(${x}, ${y})`);
  };

  return main();
}

export default renderSlider;
