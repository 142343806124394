// External library imports
import { arc, pie } from 'd3-shape';
import { select, selectAll } from 'd3-selection';

// Internal component and function imports
import patternify from '../../../utils/patternify.js';
import { colors } from '../../../utils/colors';

const renderPie = (params) => {
  let pieData = generatePieData(params.value);
  let container = params.container;
  let pieGroup, pieTitle, slices, outlineCircle, path, texts;
  let datum = container.datum();

  const attrs = {
    innerRadius: params.pieInnerRadius || 20,
    outerRadius: params.pieOuterRadius || 70,
  };

  const arcGenerator = arc().innerRadius(attrs.innerRadius).outerRadius(attrs.outerRadius);

  const pieGenerator = pie()
    .sort(null)
    .startAngle(0 - Math.PI / 2)
    .endAngle(Math.PI * 1.5)
    .value((d) => d.value);

  let interval = null;

  let onPieClick = () => {};
  let onPieMouseover = () => {};
  let onPieMouseOut = () => {};

  const main = () => {
    pieGroup = patternify(container, 'g', 'pie-chart-group').attr(
      'transform',
      `translate(${params.translation})`
    );

    pieTitle = patternify(pieGroup, 'text', 'pie-label')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('font-size', '10px')
      .attr('fill', '#000')
      .attr('opacity', 0)
      .attr('y', -attrs.outerRadius - 6)
      .text(params.title);

    slices = patternify(pieGroup, 'g', 'slices')
      .attr('cursor', 'pointer')
      .on('click', () => {
        onPieClick();
      })
      .on('mouseover', () => {
        main.highlight(false);
        onPieMouseover();
      })
      .on('mouseout', () => {
        main.clearHighlight();
        onPieMouseOut();
      });

    outlineCircle = patternify(slices, 'circle', 'stroke-circle')
      .attr('r', attrs.outerRadius + 1)
      .attr('fill', 'transparent')
      .attr('stroke', colors.highlight)
      .attr('stroke-width', 2)
      .attr('opacity', 0);

    drawArcs();

    return main;
  }

  function drawArcs() {
    let arcs = patternify(slices, 'g', 'arc', pieGenerator(pieData));

    path = patternify(arcs, 'path', 'arc-path', (d) => [d])
      .attr('d', (d) => {
        return arcGenerator(d);
      })
      .attr('fill', (d) => {
        if (d.data.accepts) {
          if (params.node.children.length) {
            return colors.acceptedGreen;
          }

          return params.node.color;
        }

        return colors.notAccepted;
      });

    texts = patternify(arcs, 'text', 'arc-text', (d) => [d])
      .attr('transform', function (d) {
        return 'translate(' + arcGenerator.centroid(d) + ')';
      })
      .attr('dy', 4)
      .attr('font-size', '12px')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('fill', (d) => {
        if (d.data.accepts && params.node.colorPercent < 0.7) {
          return '#fff';
        }

        return '#000';
      })
      .text((d) => Math.round(d.value) + '%');
  }

  function generatePieData(value) {
    return [
      {
        value: value,
        accepts: true,
      },
      {
        value: 100 - value,
        accepts: false,
      },
    ].filter((d) => d.value);
  }

  main.updatePieColor = (color, textColor) => {
    path.attr('fill', (d) => {
      if (d.data.accepts) {
        if (color) {
          return (params.node.color = color);
        }

        if (params.node.children.length) {
          return colors.acceptedGreen;
        }

        return params.node.color;
      }

      return colors.notAccepted;
    });

    texts.attr('fill', (d) => {
      if (textColor) {
        return textColor;
      }

      if (d.data.accepts && params.node.colorPercent < 0.7) {
        return '#fff';
      }

      return '#000';
    });
  };

  main.update = (value) => {
    pieData = generatePieData(value);
    drawArcs();
    return main;
  };

  main.translate = (translation) => {
    params.translation = translation;
    pieGroup.transition().duration(750).attr('transform', `translate(${params.translation})`);
  };

  main.translateYBy = (dy) => {
    pieGroup
      .transition()
      .duration(750)
      .attr('transform', `translate(${params.translation[0]}, ${params.translation[1] + dy})`);
  };

  main.highlight = (animate = true) => {
    outlineCircle.attr('opacity', 1);

    let i = 0;

    if (animate) {
      animateFunc();

      interval = setTimeout(() => {
        animateFunc();
      }, 500);
    }

    function animateFunc() {
      slices
        .transition()
        .duration(500)
        .attr('transform', `translate(0, 0) scale(${i % 2 === 0 ? 1.08 : 1})`)
        .on('end', function () {
          if (!interval) {
            slices.attr('transform', `translate(0, 0) scale(1)`);
          }
        });
      i++;
    }

    let d = datum;

    while (d.parent) {
      select(`#link-${d.data.id}-${d.parent.data.id}`)
        .raise()
        .attr('stroke', colors.highlight)
        .attr('stroke-width', 2);

      d = d.parent;
    }

    const stack = select('#stack-' + params.id);

    if (!stack.empty()) {
      const d = stack.datum();

      const tooltip = stack.node()._tippy;

      if (tooltip) tooltip.show();

      stack
        .raise()
        .select('rect')
        .attr('stroke', d.scaledVal < 0.5 ? colors.highlightLight : colors.highlight)
        .attr('stroke-width', 2);
    }

    return main;
  };

  main.clearHighlight = () => {
    outlineCircle.attr('opacity', 0);

    if (interval) {
      clearInterval(interval);
      interval = null;
    }

    selectAll('.link').attr('stroke', colors.linkColor).attr('stroke-width', null);

    const stack = select('#stack-' + params.id);

    if (!stack.empty()) {
      const tooltip = stack.node()._tippy;

      if (tooltip) tooltip.hide();

      stack.select('rect').attr('stroke', null).attr('stroke-width', null);
    }

    return main;
  };

  main.onPieClick = (f) => {
    onPieClick = f;
    return main;
  };

  main.onPieMouseover = (f) => {
    onPieMouseover = f;
    return main;
  };

  main.onPieMouseOut = (f) => {
    onPieMouseOut = f;
    return main;
  };

  main.showTitle = () => pieTitle.attr('opacity', 1);

  main.hideTitle = () => pieTitle.attr('opacity', 0);

  return main();
}

export default renderPie;
