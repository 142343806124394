import {Container, Grid, styled} from "@mui/material";

export const MuiContainer = styled(Container)(({ theme }) => ({
  paddingTop: 20,
}));

export const MuiGridInputText = styled(Grid)(({ theme }) => ({
  marginTop:'15px !important',
  paddingTop: '0px!important',
  paddingBottom: '0px!important',
}));
