import {Button, Container, Grid, styled} from "@mui/material";

export const MuiGridButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  marginRight: '15px !important',
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  marginRight: '15px !important',
}));

export const MuiGridSearch = styled(Grid)(({ theme }) => ({
  padding: '8px 8px',
}));

export const userSearchStyle = {
  border: '2px solid',
  borderRadius:'5px',
  borderColor: 'gray',
  paddingTop: '8px',
  paddingBottom: '8px',
}
