// External library imports
import * as React from 'react';
import {IconButton} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const EditIcon = ({ onClick, className }) => (
  <IconButton aria-controls="edit-button" color='primary' className={className} onClick={onClick}>
    <EditOutlinedIcon />
  </IconButton>
);

export default EditIcon;
