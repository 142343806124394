import {Tooltip, styled} from "@mui/material";

export const ToolTip = styled(Tooltip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  fontSize: 11,
}));

export const typographyEffectStyle = {
  marginTop: 15
}

export const typographyFormulaStyle = {
  marginRight: '397px',
  width: 'max-content'
}

export const gridItemStyle = {
  marginTop: 15
}

export const gridItemFormulaStyle = {
  marginLeft: '360px',
  width: 'max-content'
}

export const textareaAutosizeStyle = {
  width: '100%',
  height: '116px'
}

export const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
}

export const divFormulaStyle = {
  display: 'flex',
  marginTop: 10,
  justifyContent: 'flex-end'
}

export const formControlStyle = {
  width:'100% !important'
}

export const textFieldStyle = {
  margin: '5px'
}

export const boxStyle = {
  flexGrow: 1,
  overflowX: 'scroll'
}

export const paperStyle = {
  p: 3
}

export const buttonStyle = {
  mt: 1,
  mr: 1
}
