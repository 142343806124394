// External library imports
import {styled, Grid, Container, Button } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import { ColorField } from 'components/common/ReactHooksFormFields';
import {SelectField, TextField} from '../../../common/ReactHooksFormFields';

export const MuiContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const MuiSelectField = styled(SelectField)(({ theme }) => ({
  paddingTop: '0px!important',
  paddingBottom: '0px!important',
}));

export const MuiColorField = styled(ColorField)(({ theme }) => ({
  width: 40,
  height: 56,
  '& .MuiInputBase-input': {
    padding: '0px',
    height: '24px',
    width: '24px',
    borderRadius: '4px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  paddingTop: '0px!important',
  paddingBottom: '0px!important',
  '& .MuiInputBase-root': {
    height: '56px',
    borderRadius:'5px'
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily:'Montserrat',
    letterSpacing:'0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily:'Montserrat',
    letterSpacing:'0.4px'
  },
  '& input': {
    fontSize: '16px',
    fontFamily:'Montserrat',
  },
  '& legend': {
    fontSize: '14px',
  },
}));

export const MuiGridInputText = styled(Grid)(({ theme }) => ({
  marginTop:'15px !important',
  paddingTop: '0px!important',
  paddingBottom: '10px!important',
}));

export const MuiDescriptionIcon = styled(DescriptionIcon)(({ theme }) => ({
  width: '100px !important',
  height: '100px !important',
  color: localStorage.getItem('colorPrimary'),
  fontSize: '40px !important',
}));

export const MuiLinkIcon = styled(LinkIcon)(({ theme }) => ({
  width: '100px !important',
  height: '100px !important',
  color: localStorage.getItem('colorPrimary'),
  fontSize: '40px !important',
}));

export const MuiDivGridContainer = styled("div")(({ theme }) => ({
  display: 'grid !important',
  gridTemplateColumns: 'repeat(3, 1fr) !important',
}));

export const MuiButtonMix = styled(Button)(({ theme }) => ({
  width: '35px !important',
  height: '50px !important',
  fontSize: '15px !important',
  margin: '5px !important',
  border: '1px solid #ccc !important',
  cursor: 'pointer !important',
  fontWeight: 'bold !important',
  background: '#eee',
  color: 'black !important',
  transition: 'background-color 0.3s',
  '&:hover': {
    background: 'rgba(100, 100, 100, 0.7) !important',
    color: 'black !important',
  },
}));

export const MuiButtonNumber = styled(Button)(({ theme }) => ({
  width: '35px !important',
  height: '50px !important',
  fontSize: '15px !important',
  margin: '5px !important',
  border: '1px solid #ccc !important',
  cursor: 'pointer !important',
  fontWeight: 'bold !important',
  background: 'orange !important',
  color: 'white !important',
  transition: 'background-color 0.3s',
  '&:hover': {
    background: 'rgba(100, 100, 100, 0.7) !important',
    color: 'black !important',
  },
}));

export const MuiDivGridNumberContainer = styled("div")(({ theme }) => ({
  display: 'grid !important',
  gridTemplateColumns: 'repeat(3, 1fr) !important',
}));

export const MuiDivGridArithContainer = styled("div")(({ theme }) => ({
  display: 'grid !important',
  gridTemplateColumns: 'repeat(1, 1fr) !important',
}));

export const MuiGridColorPickers = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  paddingLeft: '0px',
}));

export const MuiGridTextContainer = styled(Grid)(({ theme }) => ({
  height: 56,
}));

export const zeroButtonStyle = {
  gridColumn: 'span 3 !important',
}

export const colorTabStyle = {
  color:'white !important'
}

export const gridContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const gridItemStyle = {
  marginTop: 10
}

export const tableContainerStyle = {
  maxHeight: 444
}

export const chipStyle = {
  marginTop: 4,
  marginLeft: 10
}

export const gridPaddingStyle = {
  paddingTop: 10
}

export const buttonStyle = {
  marginLeft: 10
}

export const divStyle = {
  display: 'flex',
  alignItems: 'center'
}
export const gridCommonStyle = {
  borderBottom:'1px solid',
  borderTop:'1px solid',
  paddingBottom:'20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export const buttonActionStyle = {
  padding:'10px 16px 10px 16px',
  borderRadius: '20px',
  color:'#005F64',
  borderColor:'#005F64',
  height:'37px'
}
