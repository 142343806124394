// External library imports
import {Grid, Container, styled} from "@mui/material";

// Asset imports
import background404 from "../../../assets/img/404.svg";


export const MuiContainerWrapper = styled(Container)(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
}));

export const MuiGridWrapper = styled(Grid)(({ theme }) => ({
  backgroundImage: `url("${background404}")`,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  height: '100%',
}));

export const MuiGridAction = styled(Grid)(({ theme }) => ({
  marginTop: '24px',
}));
