// React and External Libraries
import * as React from 'react';
import {useLocation, useNavigate, useRoutes} from 'react-router-dom';

// Routing
import {routes, secureRoutes} from '../../routes';

// Custom Hooks and Contexts
import {useAuth} from 'components/providers/AuthProvider';
import {useLoading} from '../providers/LoadingProvider';

const Views = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated, user } = useAuth();
  const {setIsLoading} = useLoading();
  const selectedRoutes = useRoutes(isAuthenticated && user ? secureRoutes(user.type, user.org_id, user.orgs_user_level) : routes);

  React.useEffect(() => {
    setIsLoading(false)
    if (isAuthenticated && (pathname === '/' || pathname === '/login'))
      navigate('/orgs');
    else navigate(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{selectedRoutes}</div>;
};
export default Views;
