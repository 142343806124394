// External library imports
import * as React from 'react';
import { Fragment } from 'react';
import {
  Button, Button as MuiButton, TextareaAutosize, Chip,
  TextField, Grid, FormControl, Autocomplete
} from '@mui/material';
import { useSnackbar } from 'notistack';

// Internal component and function imports
import { useTranslation } from '../../../../../providers/TranslationProvider';
import Modal from 'components/common/Modal';
import {
  formulaOperators, formulaFunctions, formulaNumber, formulaBuilderTypes
} from '../../../../../../utils/constants';
import { validateFormula } from '../../../../../../utils/utils';

// Style and asset imports
import {
  MuiButtonMix, MuiButtonNumber, MuiDivGridArithContainer,
  MuiDivGridContainer, MuiDivGridNumberContainer, zeroButtonStyle
} from '../../../styles/formModal';
import {
  divFunctionStyle,
  divStyle,
  formStyle,
  gridItemStyle,
  inputVariablesStyle,
  ModalDialogCalcFormulaBuilder as StyledTextField,
  TextareaAutosizeStyle
} from './styles/modals';

export default function ModalDialogCalcFormulaBuilder(props) {
    const [open, setOpen] = React.useState(false);
    const [formValue, setFormValue] = React.useState('');
    const [numericalFields, setNumericalFields] = React.useState([]);
    const [factorFields, setFactorFields] = React.useState([]);
    const [statColumns, setStatColumns] = React.useState([]);
    const [variables, setVariables] = React.useState('');
    const [operators, setOperators] = React.useState('');
    const [functions, setFunctions] = React.useState('');
    const [numbers, setNumbers] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [name, setName] = React.useState('');
    const [labelTrue, setLabelTrue] = React.useState('');
    const [labelFalse, setLabelFalse] = React.useState('');
    const [buttonFunc, setbuttonFunc] = React.useState(true);
    const [params, setParams] = React.useState([])
    const [focusParam, setFocusParam] = React.useState(null);

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const fillLocalData = () => {
        setNumericalFields(
            props.variables.filter(d => d.category === 'numeric')
        );
        setFactorFields(
            props.variables.filter(d => d.category === 'factor')
        );
        setStatColumns(
            props.variables.filter(d => d.category === 'statistic')
        );
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setFormValue('');
        setVariables('');
        setFunctions('');
        setOperators('');
        setSelectedOptions([]);
        setInputValue('');
        setParams([]);
        setbuttonFunc(true);
    };

    const validateForm = () => {
        let isValid = false
        if (!props.isFromSegmentation) {
            isValid = name && formValue
        }

        if (props.isFromSegmentation) {
            isValid = name && formValue && labelTrue && labelFalse
        }

        return isValid
    }

    const buildFormula = (event, value) => {
        if (validateFormula(value, event.type)) {
            switch (event.type) {
                case formulaBuilderTypes.TYPE_VARIABLE:
                    if (value !== '') {
                        value += ` "${event.variable}"`
                    } else {
                        value += `"${event.variable}"`
                    }
                    break
                case formulaBuilderTypes.TYPE_OPERATOR:
                    if (value !== '') {
                        value += ` ${event.operator}`
                    }
                    break
                case formulaBuilderTypes.TYPE_FUNCTION:
                    if (value !== '') {
                        value += ` ${event.function}`
                    } else {
                        value = `${event.function}`
                    }
                    break
                case formulaBuilderTypes.TYPE_NUMBER:
                    let valueArray = value.split('')
                    let lastValue = valueArray[valueArray.length - 1]

                    if (!isNaN(lastValue)) {
                        value += `${event.number}`
                    } else {
                        value += ` ${event.number}`
                    }
                    break
                default:
                  break;
            }
            setFormValue(value);
            setVariables('');
            setOperators('');
            setNumbers('');
            setFunctions('');
        } else {
            enqueueSnackbar('Can`t aggregate 2 element of the same type in a row', {
                variant: 'error',
            });
        }
    }

    const handleFocus = (param, index) => setFocusParam({ ...param, index })

    const validateFuncForm = () => {
      let valid = true
      if (params.length === 0) {
        valid = false
      }

      let emptyParams = params.filter(p => p.value === '')
      if (emptyParams.length > 0) {
        valid = false
      }

      return valid
    }
    const buildFunction = () => `${functions}(${params.map(p => p.value).join(', ')})`

    const handleFuncSubmit = () => {
      if (validateFuncForm()) {
        buildFormula({
          function: buildFunction(),
          type: formulaBuilderTypes.TYPE_FUNCTION
        }, formValue)
        setVariables('');
        setOperators('');
        setNumbers('');
        setFunctions('');
        setParams([]);
        setbuttonFunc(true);
      }
    };

    const handleSubmit = () => {
      if (validateForm()) {
        props.onSaveFormula({
          value: formValue,
          name,
          labelFalse,
          labelTrue,
        })
        setName('')
        setLabelFalse('')
        setLabelTrue('')
        setFormValue('')
        handleClose()
      } else {
        enqueueSnackbar(t('fill_all_form'), { variant: 'error' })
      }
    };
    const handleOperatorClick = (selectedOperator) => setOperators(selectedOperator);

    const handleFunctionClick = (selectedFunction) => {
      setFunctions(selectedFunction)
      setbuttonFunc(false);
    };

    const handleNumberClick = (selectedNumber) => setNumbers(selectedNumber);

    const handleOptionClick = (selectedOption) => setVariables([selectedOption]);

    const handleChipClick = (option) => setVariables(option);

    const handleCancelClick = () => {
      setParams([]);
      setFunctions('');
      setbuttonFunc(true);
    };

    React.useEffect(() => {
    fillLocalData();
  }, [props.variables])

    React.useEffect(() => {
        if (buttonFunc === false) {
            if (variables !== '') {
                if (params[focusParam.index] && validateFormula(params[focusParam.index].value, formulaBuilderTypes.TYPE_VARIABLE)) {
                    if (params[focusParam.index].value !== '') {
                        params[focusParam.index].value += ` "${variables}"`
                    } else {
                        params[focusParam.index].value += `"${variables}"`
                    }
                    setParams(params)
                } else {
                    enqueueSnackbar('Can`t aggregate 2 element of the same type in a row', {
                        variant: 'error',
                    });
                }

                setVariables('')
            }
        }
        else {
            if (variables) {
                buildFormula({
                    variable: variables,
                    type: formulaBuilderTypes.TYPE_VARIABLE,
                },
                    formValue
                )
                setVariables('')
            }
        }
    }, [variables])

    React.useEffect(() => {
        if (buttonFunc === false) {
            if (operators) {
                if (params[focusParam.index] && validateFormula(params[focusParam.index].value, formulaBuilderTypes.TYPE_OPERATOR)) {
                    params[focusParam.index].value += ` ${operators}`
                    setParams(params)
                } else {
                    enqueueSnackbar('Can`t aggregate 2 operators in a row', {
                        variant: 'error',
                    });
                }
                setOperators('')
            }
        } else {
            if (operators) {
                buildFormula({
                    operator: operators,
                    type: formulaBuilderTypes.TYPE_OPERATOR,
                },
                    formValue
                )
                setOperators('')
            }
        }

    }, [operators])

    React.useEffect(() => {
        if (buttonFunc === false) {
            if (numbers) {
                if (params[focusParam.index] && validateFormula(params[focusParam.index].value, formulaBuilderTypes.TYPE_NUMBER)) {
                    params[focusParam.index].value += `${numbers}`
                    setParams(params)
                } else {
                    enqueueSnackbar('Can`t aggregate 2 elements in a row', {
                        variant: 'error',
                    });
                }
                setNumbers('')
            }
        } else {
            if (numbers) {
                buildFormula({
                    number: numbers,
                    type: formulaBuilderTypes.TYPE_NUMBER,
                },
                    formValue
                )

                setNumbers('')
            }
        }

    }, [numbers])

    React.useEffect(() => {
        if (functions !== '') {
            let func = formulaFunctions.find(formulaFunction =>
                formulaFunction.function === functions
            )

            let localParams = []

            for (let i = 0; i < func.params; i++) {
                localParams.push({
                    uid: 'param_' + Math.random().toString(36).substr(2, 4),
                    value: '',
                    enable: false,
                })
            }

            setFocusParam({ ...localParams[0], index: 0 })
            setParams(localParams)
        }
    }, [functions])

    const actions = (
        <>
            <MuiButton onClick={handleClose} variant="outlined">
                {t('cancel_btn')}
            </MuiButton>
            <Button onClick={handleSubmit} variant="contained" color="secondary" data-cy='btn_save_formula'>
                Save
            </Button>
        </>
    );

    return (
        <div>
            <Button variant="contained"
                color="secondary"
                disabled={props.prioritizationVariables && props.prioritizationVariables.length > 0}
                onClick={handleOpen}
                data-cy='btn_add_formula'>
                {props.isFromSegmentation === true
                    ? '+ Add Segmentation Variable'
                    : props.isFromSegmentation === false
                        ? '+ Add Prioritization Variable'
                        : '+ Add Formula'}
            </Button>
            <Modal
                open={open}
                title={props.isFromSegmentation === true
                    ? 'Segmentation Variable'
                    : props.isFromSegmentation === false
                        ? 'Prioritization Variable'
                        : 'Formula'}
                actions={actions}
                onClose={handleClose}
                maxWidth="md">

                <form
                    onSubmit={handleSubmit}
                    style={formStyle}>
                    <StyledTextField
                        fullWidth
                        data-cy='name'
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)} />
                    {props.isFromSegmentation && (
                        <>
                            <StyledTextField
                                fullWidth
                                label="Label for true"
                                variant="outlined"
                                value={labelTrue}
                                onChange={(e) => setLabelTrue(e.target.value)} />

                            <StyledTextField
                                fullWidth
                                label="Label for false"
                                variant="outlined"
                                value={labelFalse}
                                onChange={(e) => setLabelFalse(e.target.value)} />
                        </>
                    )}
                    <div style={divStyle} />
                    <TextareaAutosize
                        style={TextareaAutosizeStyle}
                        label="Formula Value"
                        type="formvalue"
                        variant="outlined"
                        required
                        value={formValue}
                        data-cy='formula_value'
                        onChange={(e) =>
                            setFormValue(e.target.value)
                        } />

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {params.map((param, index) => (
                                <Fragment key={param.uid}>
                                    <div style={divStyle}></div>
                                    <StyledTextField
                                        value={param.value}
                                        onFocus={() => handleFocus(param, index)}
                                        label={'Param ' + (index + 1)}
                                        variant="outlined"
                                        data-cy={'param_' + (index + 1)}
                                        onChange={(e) => {
                                            let updatedParams = [...params];
                                            updatedParams[index].value = e.target.value;
                                            setParams(updatedParams);
                                        }}
                                    />
                                </Fragment>
                            ))}
                            <div style={divFunctionStyle}>
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        color='secondary'
                                        style={{ display: buttonFunc ? 'none' : 'block' }}
                                        onClick={handleFuncSubmit}
                                        data-cy='btn_save_function'>
                                        Add Function
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        style={{ display: buttonFunc ? 'none' : 'block' }}
                                        onClick={handleCancelClick}
                                        data-cy='btn_cancel_function'>
                                        Cancel
                                    </Button>
                                </Grid>
                            </div>

                        </Grid>

                        <Grid item xs={12} style={gridItemStyle}>
                            <FormControl required variant="outlined" margin="normal" sx={inputVariablesStyle} >
                                <Autocomplete
                                    multiple
                                    id="demo-simple-select-outlined"
                                    size="small"
                                    options={[...numericalFields, ...factorFields, ...statColumns].map(v => v.propName)}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, values) => option === values}
                                    value={selectedOptions}
                                    onChange={(event, newValue) => {
                                      handleOptionClick(newValue);
                                      setSelectedOptions(newValue)
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(e, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Variables"
                                            name="variables"
                                            data-cy="variables"
                                            variant="outlined"
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                variant='outlined'
                                                label={option}
                                                {...getTagProps({ index })}
                                                onClick={() => handleChipClick(option)}
                                                onDelete={(e) => {
                                                    e.preventDefault(); // Evitar la eliminación
                                                }}
                                            />
                                        ))
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} style={gridItemStyle}>
                            <MuiDivGridContainer>
                                {formulaFunctions.map((func, index) => (
                                    <MuiButtonMix
                                        id='calc'
                                        key={index}
                                        onClick={() => handleFunctionClick(func.function)}
                                        disabled={buttonFunc === false}>
                                        {func.function}
                                    </MuiButtonMix>
                                ))}
                            </MuiDivGridContainer>
                        </Grid>

                        <Grid item xs={4} style={gridItemStyle}>
                            {formulaOperators.map((type, index) => (
                                <MuiDivGridContainer key={index}>
                                    {(type.label === 'Logical operators' || type.label === 'Comparison operators') &&
                                        type.operators.map((op, opIndex) => (
                                            <MuiButtonMix
                                                id='calc'
                                                key={opIndex}
                                                onClick={() => handleOperatorClick(op.operator)}>
                                                {type.label === 'Logical operators' ? op.label : op.operator}
                                            </MuiButtonMix>
                                        ))}
                                </MuiDivGridContainer>
                            ))}
                        </Grid>

                        <Grid item xs={3} style={gridItemStyle}>
                            <MuiDivGridNumberContainer>
                                {[...formulaNumber].reverse().map((num, index) => (
                                    <MuiButtonNumber
                                        id='calc'
                                        key={index}
                                        onClick={() => handleNumberClick(num.number)}
                                        sx={`${num.number === '0' ? zeroButtonStyle : ''}`}
                                    >
                                        {num.number}
                                    </MuiButtonNumber>
                                ))}
                            </MuiDivGridNumberContainer>

                        </Grid>

                        <Grid item xs={1} style={gridItemStyle}>
                            {formulaOperators.map((type, index) => (
                                <MuiDivGridArithContainer key={index}>
                                    {type.label === 'Arithmetic operators' &&
                                        type.operators.map((op, opIndex) => (
                                            <MuiButtonMix
                                                id='calc'
                                                key={opIndex}
                                                onClick={() => handleOperatorClick(op.operator)}>
                                                {op.operator}
                                            </MuiButtonMix>
                                        ))}
                                </MuiDivGridArithContainer>
                            ))}
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        </div>
    );

}
