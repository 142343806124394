import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'toast',
  initialState: {
    toast: {
      message: '',
      severity: 'success',
      open: false,
    }
  },
  reducers: {
    setToastProperty: (state, action) => {
      state.toast = { ...state.toast, [action.payload.key]: action.payload.value }
    },
    setToast: (state, action) => {
      state.toast = { ...action.payload }
    }
  },
});

export default slice.reducer;

export const setToastProperty = (payload) => ({
  type: 'toast/setToastProperty',
  payload,
})

export const setToast = (payload) => ({
  type: 'toast/setToast',
  payload,
})