import {
  AppBar,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  styled
} from "@mui/material";

export const MuiAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  zIndex: 1250,
  overflow: 'hidden',
  backgroundColor:`${theme.palette.primary.main} !important`,
  height: '64px'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  maxWidth: '100%',
  paddingLeft: '0px !important',
  '&.MuiContainer-root': {
    maxWidth: '100%',
  }
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  margin: `${theme.spacing(0, 0.75, 0, -1.25)} !important`,
  '& + hr': {
    marginRight: theme.spacing(1.5),
  },
}));

export const MuiDivider = styled(Divider)(({ theme }) => ({
  border: '0.1px solid white',
  height: '58px',
  marginRight: '27px',
  marginLeft: '45px',
  marginTop: '4px',
  marginBottom: '4px'

}));

export const MuiGridScheme = styled(Grid)(({ theme }) => ({
  width:'125%'
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '100%',
  width:'85%',
  '&.MuiContainer-root': {
    maxWidth: '100%',
  }
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  verticalAlign: 'bottom',
  flexGrow: 1,
  display: 'flex',
  color: theme.palette.common.white,
}));

export const drawerStyle = {
  minWidth: 260,
}
