// External library imports
import {useQuery} from 'react-query';

// API and Utility imports
import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchSchemes = ({ user_id, scheme_id }) => {
  return axios.post(apiPaths.scheme, { user_id: user_id, scheme_id: scheme_id }, status200)
              .then((resp) => resp.data);
}

const useScheme = (data) =>
  useQuery(['scheme', data.scheme_id], () => fetchSchemes(data), {
    enabled: data.enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
    placeholderData: {
      collection: {},
      scheme: {
        id: null,
        name: '',
        dataset: '',
        match: {
          in_dataset: [],
          custom: []
        },
        prioritizationCustomVariables: [],
        weight: '',
        policies: [],
        aggregation: {},
        schemeCollectionConfig: []
      }
    }
  });

export default useScheme;
