// External library imports
import * as React from 'react';
import { useSelector } from 'react-redux';
import { select } from 'd3-selection';
import { scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { stack, stackOrderNone } from 'd3-shape';
import { sum } from 'd3-array';
import tippy from 'tippy.js';

// Internal component and function imports
import patternify from '../../../../../utils/patternify';
import { formatKilo } from '../../../../../utils/formatters';

// Style and asset imports
import {divInitStyle, svgStyle} from "../styles/stackedBar";

const StackedBar = ({ tabId, nodes, budget, treeType, enabledTransition, costBarTitle }) => {
  const chart = React.useRef(null);
  const svg = React.useRef(null);

  const width = 223;
  const height = window.innerHeight - 100;
  const margin = {
    top: 30,
    left: 35,
    right: 10,
    bottom: 30,
  };
  let nodeMap = {};
  let stacks = [];
  const userTrans = useSelector((state) => state.app.userTranslations);

  const onResize = () => {
    draw();
  };

  const draw = () => {
    chart.current.html('');
    addAxes();
    addBar();
    addBudget();
  };

  function setStacks() {
    let obj = {};
    nodes.forEach((n) => {
      obj[n.data.id] = n.data.leafNode.getAcceptedCost();
    });
    stacks = stackGenerator([obj]);
  };

  const addAxes = () => {
    patternify(chart.current, 'g', 'xAxis')
      .attr('transform', `translate(${0}, ${chartHeight})`)
      .call(xAxis)
      .call((g) => g.selectAll('.tick').remove());

    patternify(chart.current, 'g', 'yAxis').call(yAxis);

    patternify(chart.current, 'text', 'yAxisLabel')
      .attr('x', chartWidth - 90)
      .attr('y', -10)
      .attr('text-anchor', 'middle')
      .attr('fill', 'black')
      .attr('font-family', 'Montserrat')
      .attr('font-size', '14px')
      .attr('font-weight', 'bold')
      .attr('dy', '0.15em')
      .text(costBarTitle);
  };

  const getText = (node, d) => {
    const datum = Math.ceil(node.data.leafNode.getUnitsAccepted());
    const cost = Math.ceil(d[0].data[d.key]);
    const text = treeType === 'aggregation' ? `$${formatKilo(cost)}` : `$${formatKilo(cost)} | ${formatKilo(datum)}`;

    return text;
  };

  function addBar () {
    const currentNodeMap = nodeMap;
    setStacks();

    const currentStackGroup = patternify(chart.current, 'g', 'strack-group').lower();

    stacks.forEach((d) => {
      d.id = d.key;
      d.scaledVal = nodeMap[d.key].data.colorPercent;
    });

    const currentStacks = patternify(currentStackGroup, 'g', 'stack-bar', stacks)
      .attr('transform', (d) => {
        return `translate(${xScale(xDomain[0])}, ${yScale(d[0][1])})`;
      })
      .attr('id', (d) => `stack-${d.key}`)
      .attr('cursor', 'pointer')
      .on('mouseover', function (d) {
        let node = nodeMap[d.key];
        let pie = node?.data.leafNode.components.pie;
        pie?.highlight();
      })
      .on('mouseout', function (d) {
        let node = nodeMap[d.key];
        let pie = node?.data.leafNode.components.pie;
        pie?.clearHighlight();
      });

    const costBar = patternify(currentStacks, 'rect', 'bar', (d) => [d])
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('y', (d) => Math.max(0, yScale(d[0][0]) - yScale(d[0][1])))
      .attr('width', xScale.bandwidth())
      .attr('fill', (d) => nodeMap[d.key].data.color)
      .style("filter", "drop-shadow(3px 2px 1px rgba(0, 0, 0, 0.4))");

    if (enabledTransition) {
      costBar.transition()
             .duration(1000)
             .attr('height', (d) => Math.max(0, yScale(d[0][0]) - yScale(d[0][1])))
             .attr('y', 0);
    } else {
      costBar.attr('height', (d) => Math.max(0, yScale(d[0][0]) - yScale(d[0][1])))
             .attr('y', 0);
    }

    const labelCostBar = patternify(currentStacks, 'text', 'label', (d) => [d])
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('font-size', '14px')
      .attr('font-weight', '600')
      .attr('y', 190);

    if (enabledTransition) {
      labelCostBar.transition()
        .duration(2000)
        .attr('x', xScale.bandwidth() / 2)
        .attr('y', (d) => (yScale(d[0][0]) - yScale(d[0][1])) / 2 + 4)
        .attr('opacity', (d) => {
          if (yScale(d[0][0]) - yScale(d[0][1]) < 15) return 0;
          return 1;
        })
        .attr('fill', (d) => {
          let n = d.scaledVal;
          return n < 0.7 ? '#fff' : '#000';
        })
        .text((d) => {
          const node = currentNodeMap[d.key];
          return getText(node, d);
        });
    } else {
      labelCostBar.attr('x', xScale.bandwidth() / 2)
        .attr('y', (d) => (yScale(d[0][0]) - yScale(d[0][1])) / 2 + 4)
        .attr('opacity', (d) => {
          if (yScale(d[0][0]) - yScale(d[0][1]) < 15) return 0;
          return 1;
        })
        .attr('fill', (d) => {
          let n = d.scaledVal;
          return n < 0.7 ? '#fff' : '#000';
        })
        .text((d) => {
          const node = currentNodeMap[d.key];
          return getText(node, d);
        });
    }

    currentStacks.each(function (d) {
      const node = currentNodeMap[d.key];
      const parent = node.parent;
      const datum = node.data;

      const html =
        `${parent && parent.depth > 0 ? parent.data.name + ' &' : ''} ${datum.name} ` +
        getText(node, d);

      tippy(this, {
        theme: 'light-border',
        arrow: true,
        content: html,
        placement: 'left',
      });
    });
  };

  function addBudget () {
    patternify(chart.current, 'line', 'budget-line')
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', yScale(budget))
      .attr('y2', yScale(budget))
      .attr('stroke', 'red')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '3 3');

    patternify(chart.current, 'text', 'budget-text')
      .attr('text-anchor', 'middle')
      .attr('x', chartWidth / 2)
      .attr('dy', -5)
      .attr('y', yScale(budget))
      .text(userTrans.budget)
      .attr('font-size', '10px')
      .attr('font-weight', '600')
      .attr('fill', 'red');
  };

  const fillNodeMap = () => {
    nodeMap = {};

    if (nodes) {
      nodes.forEach((n) => {
        nodeMap[n.data.id] = n;
      });
    }
  };

  const chartWidth = React.useMemo(
    () => width - margin.left - margin.right,
    [width, margin.left, margin.right]
  );

  const chartHeight = React.useMemo(
    () => height - margin.top - margin.bottom,
    [height, margin.top, margin.bottom]
  );

  const xDomain = React.useMemo(() => {
    return [userTrans.population];
  }, [userTrans]);

  const xScale = React.useMemo(() => {
    return scaleBand().paddingInner(0.2).domain(xDomain).range([0, chartWidth]);
  }, [xDomain, chartWidth]);

  const allSum = React.useMemo(() => {
    // find a total sum of node's accepted number * its monetary cost
    let totalSum = sum(
      nodes.map((d) => d.data),
      (d) => {
        return d.leafNode.getAcceptedCost();
      }
    );
    // take max between the totalSum and budget
    return Math.max(totalSum, budget) * 1.2;
  }, [budget, nodes]);

  const yScale = React.useMemo(() => {
    return scaleLinear().domain([0, allSum]).range([chartHeight, 0]);
  }, [allSum, chartHeight]);

  const xAxis = React.useMemo(() => axisBottom(xScale), [xScale]);
  const yAxis = React.useMemo(() => axisLeft(yScale).tickFormat(formatKilo), [yScale]);
  const stackGenerator = React.useMemo(() => {
    const keys = nodes?.map((d) => d.data.id);

    return stack().keys(keys).order(stackOrderNone);
  }, [nodes]);

  React.useEffect(() => {
    const container = document.querySelector('.stacked-bar-' + tabId);
    svg.current = select(container).select('.stacked-bar-chart');
    chart.current = svg.current.select('.chart');
    fillNodeMap();
    onResize();
  }, []);

  React.useEffect(() => {
    fillNodeMap();
    draw();
  }, [nodes, userTrans]);

  return (
    <div className={`stacked-bar-${tabId}`} style={divInitStyle}>
      <svg
        style={svgStyle}
        className='stacked-bar-chart'>
        <g className='chart' transform={`translate(${margin.left},${margin.top})`}></g>
      </svg>
    </div>
  );
};

export default StackedBar;
