// External library imports
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// Internal component and function imports
import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import useSchemesAssociatedDataset from 'api/hooks/useSchemesAssociatedDataset';
import useDeleteDataset from 'api/hooks/useDeleteDataset';
import ModalDelete from '../common/ModalDelete';

const DeleteDataset = () => {
  const [schemesAssociated, setSchemesAssociated] = React.useState(null)

  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuth();

  const Id = location.pathname.split('/')[2] === 'workspace' ?
    user.id : location.pathname.split('/')[2];

  const deleteMutation = useDeleteDataset();

  const { data } = useSchemesAssociatedDataset({
    user_id: Id,
    id: location.state?.collection,
  });

  const handleSubmit = () => {
    const data = {
      user_id: Id,
      name: location.state?.collection,
    };
    deleteMutation.mutateAsync(data);
  };

  React.useEffect(() => {
    if (data){
      setSchemesAssociated(data)
    }
  },[data])
   console.log(schemesAssociated)
  return (
    <ModalDelete
      schemesAssociated={schemesAssociated}
      deleteMutation={deleteMutation}
      onSubmit={handleSubmit}
      content={t('delete_dataset')}
      title={t('delete_dataset_title')}
      name={location.state?.name}
    />
  );
};

export default DeleteDataset;
