import {styled, Typography} from "@mui/material";

export const MuiTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Raleway',
  textAlign: 'start',
  width: 'auto',
  marginLeft: '8px'
}));

export const button ={
  fontSize: '14px',
  fontFamily: 'Montserrat'
}
export const body1 ={
  fontSize: '16px',
}
export const headline7 ={
  fontFamily: 'Raleway',
  fontSize: '29px',
  fontWeight: 'bold'
}
