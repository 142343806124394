// React and External Libraries
import React from 'react';

// Custom Hooks and Contexts
import { useLoading } from '../providers/LoadingProvider';

// Style Imports
import 'assets/scss/loader.css';

const ProsperiaLoader = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div className='loader-container'>
      <div className='loader'>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--text'></div>
      </div>
    </div>
  )
}

export default ProsperiaLoader;
