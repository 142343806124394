// External library imports
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button, CircularProgress, FormControlLabel, Grid, IconButton, Slider, Switch,
  TextField as MuiTextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '../common/DeleteIcon';
import { Tooltip } from 'antd';
import { extent } from 'd3-array';
import { useDebouncedCallback } from 'use-debounce';

// Internal component and function imports
import { alphabet } from '../../../../utils/constants';
import { loadTotalPopulationBins } from '../../../charts/shared/data';
import { useTranslation } from '../../../providers/TranslationProvider';
import SimpleHistogram from '../../../charts/simple-histogram';

// Style and asset imports
import {
  MuiGridHistogram, MuiSliderAxisY,
  buttonStyle, divInitStyle,
  firstGridStyle, gridItemStyle,
  secondGridStyle
} from "../styles/sections";


const ValueLabelComponent = ({ children, open, value }) => {
  return (
    <Tooltip open={open}
      enterTouchDelay={0}
      placement={children.props['aria-labelledby'] === 'y-axis-slider' ? 'left' : 'top'}
      title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
};

const Sections = ({
  propName,
  collectionName,
  currentSections,
  weightField,
  sectionData,
  schemeConfiguration,
  showAddSectionBlock = true,
  gridSizes = {
    othersLabel: 2,
    sliderLabel: 3,
  },
}) => {
  const [sections, setSections] = React.useState([]);
  const [sectionCounter, setSectionCounter] = React.useState(0);
  const [simpleHistogram, setSimpleHistogram] = React.useState(null);
  const [xExtent, setXExtent] = React.useState([1, 50]);
  const [dataYMaxRaw, setDataYMaxRaw] = React.useState(0);
  const [dataYMax, setDataYMax] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [dataXMinRaw, setDataXMinRaw] = React.useState(1);
  const [dataXMaxRaw, setDataXMaxRaw] = React.useState(50);
  const [histogramConfiguration, setHistogramConfiguration] = React.useState({
    xLabel: true,
    sliderLabel: true,
    binSize: 1,
  });
  const [minBoundary, setMinBoundary] = React.useState(0);
  const [maxBoundary, setMaxBoundary] = React.useState(0);
  const [loading, setLoading] = React.useState(false)
  const [ minBinSize, maxBinSize ] = React.useMemo(() => {
    return [
      Math.ceil((maxBoundary + (-1 * minBoundary)) / 200),
      Math.ceil((maxBoundary + (-1 * minBoundary)) / 5),
    ]
  }, [maxBoundary, minBoundary])
  const chart = React.useRef(null);

  const maxIntervalX = 200;
  const { t } = useTranslation();

  const addNewSection = () => {
    let allSections = [
      ...sections,
      {
        name: alphabet[sectionCounter % alphabet.length],
        value: Math.ceil((xExtent[1] - xExtent[0]) / 2),
      },
    ];

    setSections(allSections);
    setSectionCounter((prevSectionCounter) => prevSectionCounter + 1);
  };

  const fetchData = async () => {
    const req = {
      binsEndpoint: '/compute/bins',
      collection: collectionName,
      report_vars: {
        variables: [propName],
        groupby: [propName],
      },
    };

    req.binSize = histogramConfiguration.binSize;

    if (weightField) {
      req.weight = weightField;
    }

    let allData = await loadTotalPopulationBins(req).then((bins) => {
      setMinBoundary(bins[0].min_boundary);
      setMaxBoundary(bins[bins.length - 1].max_boundary);
      let minMax = extent(bins, (x) => +x.criteria);
      let yMinMax = extent(bins, (d) => +d.sum);
      let dataMap = new Map(bins.map((x) => [+x.criteria, x]));
      let arr = [];
      let dataMinRaw;
      let dataMaxRaw;
      let dataYMax;
      let dataYMaxRaw;

      for (let i = minMax[0]; i <= minMax[1]; i++) {
        const d = dataMap.get(i);

        arr.push({
          criteria: i,
          sum: d ? d.sum : 0,
        });
      }

      // this will be adjusted by user
      setXExtent([minMax[0], Math.min(minMax[1], minMax[0] + maxIntervalX)]);

      if (schemeConfiguration) {
        setXExtent(schemeConfiguration.xExtent)
      }

      // real values
      dataMinRaw = minMax[0];
      dataMaxRaw = minMax[1];

      dataYMax = Math.round(yMinMax[1] * 1.5);
      dataYMaxRaw = Math.round(yMinMax[1] * 1.5);
      setDataYMax(Math.round(yMinMax[1] * 1.5));
      setDataYMaxRaw(Math.round(yMinMax[1] * 1.5));

      setDataXMinRaw(dataMinRaw);
      setDataXMaxRaw(dataMaxRaw);
      setLoading(false)
      return {
        data: arr,
        xExtent,
        dataMinRaw,
        dataMaxRaw,
        dataYMax,
        dataYMaxRaw,
      };
    });

    setData(allData.data);

    if (chart.current) {
      chart.current.innerHTML = '';

      setTimeout(() => {
        setSimpleHistogram(
          SimpleHistogram({
            container: chart.current,
            containerSelector: '.chart-area',
            width: chart.current.offsetWidth,
            height: 300,
            data: allData.data,
            visible: true,
            fieldName: propName,
            sections: [],
            yDomain: [0, allData.dataYMaxRaw],
            xExtent: xExtent,
            yExtent: [0, allData.dataYMax],
            histogramConfig: histogramConfiguration,
            binSize: histogramConfiguration.binSize,
            fnSections: (data) => {
              setSections([...data]);
            },
          })
        );
      }, 0);
    }
  };

  const fetchDataDebounced = useDebouncedCallback(fetchData, 500)

  const onSectionUpdate = (e, index) => {
    let newSections = [...sections];
    newSections[index][e.target.name] = e.target.value;
    setSections(newSections);
    adjustSections();
  };

  const adjustSections = () => {
    const [min, max] = xExtent;

    let adjustedSections = sections.map((d) => ({
      ...d,
      value: Math.max(min, Math.min(max, d.value)),
    }));

    setSections(adjustedSections);
  };

  const onChangeYaxis = (event, newValue) => setDataYMax(newValue);

  const onChangeXAxis = (event, newXAxisValue) => setXExtent(newXAxisValue);

  const handleChangeConfiguration = (event, newValue, key = null) => {
    let param = { [event.target.name]: event.target.checked }

    if (key) {
      param = { [key]: newValue }
    }

    setHistogramConfiguration({
      ...histogramConfiguration,
      ...param,
    });

    if (key === 'binSize') {
      fetchDataDebounced()
    }
  };

  const deleteSection = (index) => {
    const currentSections = [...sections];
    currentSections.splice(index, 1);
    setSections(currentSections);
  };

  React.useEffect(() => {
    if (data.length === 0) {
      setLoading(true)
      fetchData();
    }
  }, [data]);

  React.useEffect(() => {
    if (data.length === 0 && !loading) {
      fetchData()
    }
  }, [loading]);

  React.useEffect(() => {
    if (simpleHistogram) {
      simpleHistogram.updateSections(sections);

      if (dataYMax) {
        simpleHistogram.updateYAxisExtent(0, dataYMax);
      }

      if (xExtent.length > 0) {
        simpleHistogram.updateXAxisExtent(xExtent[0], xExtent[1]);
      }

      simpleHistogram.showHideXAxis(histogramConfiguration.xLabel);
      simpleHistogram.getSlider().updateSliderLabel(histogramConfiguration.sliderLabel);

      sectionData({
        variable: propName,
        sections,
        xExtent: xExtent,
        yExtent: [0, dataYMax],
        histogramConfiguration,
      });
    }
  }, [dataYMax, xExtent, simpleHistogram, histogramConfiguration, sections]);

  React.useEffect(() => {
    if (schemeConfiguration) {
      setHistogramConfiguration({
        binSize: schemeConfiguration.binSize > 1
          ? schemeConfiguration.binSize
          : 1,
        xLabel: schemeConfiguration.xLabel,
        sliderLabel: schemeConfiguration.sliderLabel,
      })
      setXExtent(schemeConfiguration.xExtent)
      setDataYMax(schemeConfiguration.yExtent[1])
    }
  }, [schemeConfiguration]);

  React.useEffect(() => {
    if (currentSections && currentSections.length > 0) {
      setSections(currentSections)
    }
  }, [currentSections])

  return (
    <div style={divInitStyle}>
      {loading === false ? (
        <form>
          {showAddSectionBlock && (
            <>
              <Grid container>
                <Button
                  data-cy='add_new_section'
                  variant='contained'
                  color='primary'
                  style={buttonStyle}
                  onClick={() => addNewSection()}
                  startIcon={<AddIcon />}
                >
                  {t('add_new_section')}
                </Button>
              </Grid>
              {sections?.map((section, index) => (
                <Grid container spacing={2} key={'section-' + index}>
                  <Grid item xs={3}>
                    <MuiTextField
                      name='name'
                      label='Name'
                      data-cy={'section_name' + index}
                      margin='dense'
                      type='text'
                      variant='outlined'
                      value={section.name}
                      onInput={(e) => onSectionUpdate(e, index)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MuiTextField
                      name='value'
                      label='Below'
                      data-cy={'section_value' + index}
                      margin='dense'
                      type='number'
                      inputProps={{ min: xExtent[0], max: xExtent[1] }}
                      value={section.value}
                      variant='outlined'
                      onInput={(e) => onSectionUpdate(e, index)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => deleteSection(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          {data.length > 0 && (
            <div>
              <MuiGridHistogram container>
                <Grid item style={firstGridStyle}>
                  <MuiSliderAxisY
                    ValueLabelComponent={ValueLabelComponent}
                    step={1}
                    max={dataYMaxRaw}
                    orientation='vertical'
                    value={dataYMax}
                    aria-labelledby='y-axis-slider'
                    valueLabelDisplay='on'
                    onChange={(event, newValue) => onChangeYaxis(event, newValue)}
                  />
                </Grid>
                <Grid item xs={11}>
                  <div className='chart-area' ref={chart}></div>
                </Grid>
              </MuiGridHistogram>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} style={secondGridStyle}>
                  <Slider
                    ValueLabelComponent={ValueLabelComponent}
                    value={xExtent}
                    aria-labelledby='x-axis-slider'
                    valueLabelDisplay='on'
                    min={dataXMinRaw}
                    max={dataXMaxRaw}
                    onChange={(event, newValue) => onChangeXAxis(event, newValue)}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} style={gridItemStyle}>
                  <Slider
                    ValueLabelComponent={ValueLabelComponent}
                    value={histogramConfiguration.binSize}
                    aria-labelledby='bin-size-slider'
                    valueLabelDisplay='on'
                    min={minBinSize}
                    max={maxBinSize}
                    valueLabelFormat={(value) => `Bin size: ${value}`}
                    onChange={(event, newValue) => handleChangeConfiguration(event, newValue, 'binSize')}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={gridSizes.othersLabel}>
                  Others configurations:
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={gridSizes.sliderLabel}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={histogramConfiguration.sliderLabel}
                        onChange={handleChangeConfiguration}
                        color='primary'
                        name='sliderLabel'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={t('show_histogram_slider_label')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={histogramConfiguration.xLabel}
                        onChange={handleChangeConfiguration}
                        color='primary'
                        name='xLabel'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={t('show_x_axis_label')}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </form>
      ) : (
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid container justifyContent='center' item xs={6}>
            <CircularProgress/>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      )}
    </div>
  );
};

export default Sections;
