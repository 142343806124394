const renderTextButton = (params) => {
  let container = params.container;
  let btn,
    clickFunc = function () {};

  const main = () => {
    btn = container
      .append('g')
      .attr('opacity', params.visible ? 1 : 0)
      .attr('pointer-events', params.visible ? 'all' : 'none')
      .attr('transform', `translate(${params.translation})`);

    let div = document.createElement('div');
    div.innerHTML = params.html;
    div.style.position = 'absolute';
    div.style.visibility = 'hidden';
    div.style.whiteSpace = 'nowrap';
    div.style.height = 'auto';
    div.style.width = 'auto';
    document.body.appendChild(div);

    let width = div.clientWidth + 2;
    let height = div.clientHeight + 4;

    document.body.removeChild(div);

    btn
      .append('foreignObject')
      .attr('width', width)
      .attr('height', height)
      .attr('x', -width / 2)
      .attr('y', -height / 2)
      .html(params.html);

    btn.on('click', (d) => {
      clickFunc(d);
    });

    return main;
  }

  main.show = () => {
    params.visible = true;
    btn
      .attr('opacity', params.visible ? 1 : 0)
      .attr('pointer-events', params.visible ? 'all' : 'none');
  };

  main.hide = () => {
    params.visible = false;
    btn
      .attr('opacity', params.visible ? 1 : 0)
      .attr('pointer-events', params.visible ? 'all' : 'none');
  };

  main.translate = (translation) => {
    params.translation = translation;
    btn.transition().duration(750).attr('transform', `translate(${params.translation})`);
  };

  main.click = (f) => {
    clickFunc = f;
    return main;
  };

  main.getNode = () => btn.node();

  return main();
}

export default renderTextButton;
