// External library imports
import * as React from 'react';
import { CardMedia} from '@mui/material';

// Style and asset imports
import {
  MuiCardContent,
  MuiCardRoot, MuiSkeleton, MuiSkeletonMedia
} from './styles/card';

const CardSkeleton = () => {
  return (
    <MuiCardRoot>
      <CardMedia>
        <MuiSkeletonMedia variant='rect'/>
      </CardMedia>
      <MuiCardContent>
        <MuiSkeleton variant='rect'/>
      </MuiCardContent>
    </MuiCardRoot>
  );
};
export default CardSkeleton;
