import {Button, Container, Grid, styled} from "@mui/material";

export const MuiButtonSpacing = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(4),
  justifySelf: 'end',
  borderRadius: '16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height: '32px'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(-1),
  paddingTop: theme.spacing(0.4),
  paddingBottom: theme.spacing(3),
  alignItems: 'left',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
}));

export const MuiGridButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0, 1, 1, 1),
  justifyContent: 'flex-end',
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important'
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height:'32px'
}));
