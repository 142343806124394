import {Grid, styled, Typography} from '@mui/material';

export const MuiGridImgContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

export const MuiGridPaper = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
}));

export const MuiImg = styled("img")(({ theme }) => ({
  width: 248,
  backgroundColor: 'white',
  height: 248,
  borderRadius:'19px',
  border:'3px solid',
  borderColor: theme.palette.primary.main,
  objectFit: 'scale-down'
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(3),
  marginTop: theme.spacing(5),
  alignItems: 'left',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
}));

export const MuiGridButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginTop: '15px !important',
  marginBottom: '10px',
  justifyContent: 'flex-end',
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontSize: 29,
  fontWeight:700,
  marginTop:theme.spacing(4),
  marginLeft:theme.spacing(7),
  marginBottom:theme.spacing(2),
  fontStyle:'normal',
  fontFamily:'Raleway',
  color:'#212121',
}));

export const containerStyle = {
  maxWidth: '1360px !important'
}
