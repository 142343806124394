// External library imports
import * as React from 'react';
import {
  Alert,
  AppBar,
  Button,
  Button as MuiButton,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Radio,
  Tab,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField as MuiTextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useSnackbar} from 'notistack';
import {useLocation} from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// API and Utility imports
import {
  getFilters,
  getImportJob,
  uploadCollectionS3
} from '../../../../api/uploadCollection';
import axios from '../../../../api/axios/axiosInstance';
import useCreateScheme from '../../../../api/hooks/useCreateScheme';

// Custom Hooks and Contexts
import {useTranslation} from 'components/providers/TranslationProvider';
import {useAuth} from '../../../providers/AuthProvider';

// Internal component and function imports
import Modal from 'components/common/Modal';
import MoreModal from './MoreModal';
import {TextField} from 'components/common/ReactHooksFormFields';
import Toast from 'components/common/Toast';
import TabPanel from "./TabPanel";
import allowedURLPatterns from "./AllowedURLPatterns";

// Style and asset imports
import {
  buttonStyle,
  colorTabStyle,
  divStyle,
  gridContainerStyle,
  gridItemStyle,
  MuiContainer,
  MuiDescriptionIcon,
  MuiGridInputText,
  MuiLinkIcon,
  tableContainerStyle
} from '../styles/formModal';
import themeCriteria from 'theme/criteria';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FormModal = ({ open, onClose }) => {
  const [value, setValue] = React.useState(0);
  const [uploadOption, setUploadOption] = React.useState('image_file');
  const [showContent, setShowContent] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [url, setUrl] = React.useState('');
  const [variables, setVariables] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [uploadMessages, setUploadMessages] = React.useState([]);
  const [uploadErrors, setUploadErrors] = React.useState([]);
  const [urlCollectionName, setUrlCollectionName] = React.useState('');
  const [sectionMap, setSectionMap] = React.useState([]);
  const [field, setField] = React.useState(null);
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [openMore, setOpenMore] = React.useState(false);

  const location = useLocation();
  const { user } = useAuth();
  const userId = location.pathname.split('/')[2] === 'workspace' ?
                 user.id : location.pathname.split('/')[2];
  const { mutateAsync: createScheme } = useCreateScheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()
  const schema = yup.object().shape({
    file: yup.mixed().when([], {
      is: () => uploadOption === 'image_file',
      then: () => yup.string().required('file required'),
      otherwise: () => yup.string().notRequired()
    }),
    url: yup.string()
      .when([], {
        is: () => uploadOption === 'url',
        then: () => yup.string().required('url_is_required'),
        otherwise: () => yup.string().notRequired()
      }),
    datasetName: yup.string().required('dataset_name_required'),
  });
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { handleSubmit, formState:{ errors, isValid }, reset, getValues} = methods;
  let varsConfig = null

  const handleChange = (event, newValue) => setValue(newValue);

  const handleOnClickUploadOption = (option) => {
    setVariables(null);
    reset({
      datasetName: '',
      file: '',
      url: ''
    });
    setUploadOption(option);
    setShowContent(true);
  };

  const handleCloseToast = () => setOpenToast(false);

  const handleUrlChange = (event) => {
    const inputUrlValue = event.target.value;
    setUrl(inputUrlValue);
    methods.setValue('url', inputUrlValue)
  };

  const handleFilesChange = (event) => {
    methods.setValue('file', event.target.files)
    setUploadedFiles([...event.target.files]);
    methods.trigger('datasetName')
  };

  const getDatasetVariables = async (collectionName, importJobId) => {
    try {
      let axiosResp = await axios.get(
        `/collection/get-dataset-variables?datasetName=${collectionName}&idImportedJob=${importJobId}`
      );

      if (axiosResp && axiosResp.status === 200) {
        let response = axiosResp.data;
        let currentVariables = null;

        if (response.status === 'ok') {
          currentVariables = Object.entries(response.data).map((v) => ({
            label: v[0],
            propName: v[0],
            category: v[0] === 'weight' ? 'weight' : 'ignore',
            type: v[1],
          }));
          setVariables(currentVariables);
        }
      }
    } catch (error) {}
  };

  const handleFileUploadFromS3 = async (formData) => {
    setUploading(true);
    let importJobId;

    if (getValues('datasetName')) {
      const data = {
        user_id: userId,
        datasetName: [userId, getValues('datasetName').trim()].join('_'),
        s3Info: {
          url: formData.url.trim(),
        },
      };

      if (varsConfig) {
        data.variables = varsConfig
        data.datasetName = getValues('datasetName')
      }

      uploadCollectionS3(data).then((res) => {
        if (res && res.status === 200) {
          if (!varsConfig) {
            setUploadMessages([t('starting_file_process')]);
            importJobId = res.data.data;
            let isFirstTimeQueryingJobStatus = true;

            // To control failed requests.
            let intervalMaxFailedRequest = 10;
            let intervalFailedRequestCount = 0;

            // Periodically query import job status.
            let intervalHandler = setInterval(() => {
              if (intervalFailedRequestCount === intervalMaxFailedRequest) {
                setUploading(false);
                clearInterval(intervalHandler);
              }

              getImportJob(importJobId)
                .then(async (res) => {
                  if (res && res.status === 200) {
                    if (isFirstTimeQueryingJobStatus) {
                      setUploadMessages([]);
                      isFirstTimeQueryingJobStatus = false;
                    }

                    let importJob = res.data.data;

                    if (importJob.status === 'in_progress') {
                      setUploadMessages([t('importing')]);
                    }

                    if (importJob.status === 'failed') {
                      setUploadMessages([]);
                      setUploadErrors(importJob.jobErrors);
                      setUploading(false);
                      clearInterval(intervalHandler);
                    }

                    if (importJob.status === 'completed' || importJob.status === 'success' || importJob.status === 'warning') {
                      setUploading(false);
                      setUploadMessages([t('import_complete')]);
                      clearInterval(intervalHandler);

                      // Get the dataset variables from the backend.
                      await getDatasetVariables(importJob.collectionName, importJobId);

                      setUrlCollectionName(importJob.collectionName);
                      setValue(1);
                    }
                  } else {
                    intervalFailedRequestCount++;
                  }
                })
                .catch((error) => {
                  intervalFailedRequestCount++;
                });
            }, 2000);
          } else {
            if (res.data.status === 'error') {
              enqueueSnackbar(res.data.msg, { variant: 'error' })
              setUploading(false);
            } else {
              enqueueSnackbar('Import of dataset with configuration completed', { variant: 'success' })
              setUploading(false);
              onClose();
            }
          }
        }
      });
    }
  };

  const handleUpload = handleSubmit(async (data) => {
    setUploading(true);

    if (uploadOption === 'image_file') {
      let file = uploadedFiles[0];
      const allowedFileTypes = ['.csv', '.json', '.geojson', '.parquet'];
      const fileExtension = file.name.split('.').pop();

      if (!allowedFileTypes.includes('.' + fileExtension)) {
        enqueueSnackbar('Tipo de archivo no admitido', { variant: 'error' });
        setUploading(false);
      }
      else {
        let formData = new FormData();
        let url = `${process.env.REACT_APP_API_URL}/users/upload-file`
        formData.append('image_file', file);
        let axiosResp = await axios.post(url, formData).then((resp) => resp.data);
        if (axiosResp) {
          let dataUrl = {
            url: axiosResp.file
          };
          await handleFileUploadFromS3(dataUrl);
        }
      }

    } else if (uploadOption === 'url') {
      let isValid = false;
      for (const pattern of allowedURLPatterns) {
        if (pattern.test(getValues('url'))) {
          isValid = true;
          break;
        }
      }
      if (!isValid) {
        enqueueSnackbar('La URL no es válida', { variant: 'error' });
        setUploading(false);
      } else {
        await handleFileUploadFromS3(data);
      }
    }
  });

  const changeVariableLabel = (data, index, value) => {
    let newVariables = variables.map((v) => {
      if (v.propName === data.propName) {
        v.label = value;
      }
      return v;
    });

    setVariables(newVariables);
  };

  const changeVariables = (data, index, value) => {
    let newVariables = variables.map((v) => {
      if (v.propName === data.propName) {
        v.category = value;
      }

      return v;
    });

    setVariables(newVariables);
  };

  const getAllFilters = (filterVars) => {
    return Promise.all(
      filterVars.map((v) => {
        return getFilters({
          filter: v,
          collection: urlCollectionName,
        });
      })
    ).then((res) => {
      let filters = [];

      res
        .map((d) => d.data)
        .forEach((r, i) => {
          let v = filterVars[i];

          if (v.category === 'numeric') {
            filters.push({
              type: 'numerical',
              text: v.label || v.propName,
              value: v.propName,
              min: r.length > 0 && r[0] ? r[0].min : 0,
              max: r.length > 0 && r[0] ? r[0].max : 0,
            });
          } else if (v.category === 'categorical') {
            let groups = r && r.length > 0 ? r.map((d) => d._id) : [];
            filters.push({
              type: 'categorical',
              text: v.label || v.propName,
              value: v.propName,
              values: groups,
            });
          }
        });

      return filters;
    });
  };

  const onSave = async () => {
    let filterVars = variables.filter((d) => d.category !== 'ignore');

    if (filterVars.length === 0) {
      setError(t('no_variables_warning'));
      setOpenToast(true);
      return;
    }

    if (filterVars.filter((d) => d.category === 'weight').length === 0) {
      setError(t('no_weight_warning'));
      setOpenToast(true);
      return;
    }

    let numericVariables = variables.filter((d) => d.category === 'numeric');
    let categoricalVariables = variables.filter((d) => d.category === 'categorical');

    if (numericVariables.length === 0) {
      setError(t('no_numerical_field'));
      setOpenToast(true);
      return;
    }

    let filters = await getAllFilters(
      filterVars.filter((d) => d.category === 'numeric' || d.category === 'categorical')
    );

    const isMoreThan200 = filters
      .filter((d) => d.type === 'numerical')
      .filter((d) => {
        return Math.abs(d.max - d.min) > 200;
      });

    if (isMoreThan200.length > 0) {
      const propNames = isMoreThan200.map((d) => d.value).join(', ');
      setError(t('more_than_200_bins_warning').replace('{propNames}', propNames));
      setOpenToast(true);
      return;
    }

    let sections = [];
    let extents = [];
    let binSizes = [];
    let histogramConfigs = [];

    numericVariables.forEach((d) => {
      const m = sectionMap[d.propName];

      if (m) {
        sections.push({
          sections: m.sections,
          variable: m.variable,
        });

        extents.push({
          xExtent: m.xExtent,
          yExtent: m.yExtent,
          variable: m.variable,
        });

        binSizes.push({
          variable: m.variable,
          binSize: m.binSize,
        });

        histogramConfigs.push({
          variable: m.variable,
          xLabel: m.histogramConfig.xLabel,
          sliderLabel: m.histogramConfig.sliderLabel,
        });
      }
    });

    let shapeFiles = [];

    categoricalVariables.forEach((d) => {
      if (sectionMap[d.propName]) {
        shapeFiles.push({
          collection: sectionMap[d.propName].shapeFile,
          variable: d.propName,
        });
      }
    });

    let payload = {
      collection: {
        name: getValues('datasetName'),
        collection: urlCollectionName,
        variables: filterVars,
        filters: filters,
        sections: sections,
        extents: extents,
        shapeFiles: shapeFiles,
        binSizes,
        histogramConfigs,
      },
    };

    if (userId) {
      payload.user_id = userId;
    }

    setUploading(true);
    await createScheme(payload);
    setUploading(false);
    onClose();
  };

  const showMoreSettings = (variable) => {
    if (variable.category === 'numeric' || variable.category === 'categorical') {
      setField(variable);
      setOpenMore(true);
    }
  };

  const handleConfigFileChange = (ev, isFromCategorization = true) => {
    const fr = new FileReader();
    fr.onload = e => {
      const configuration = JSON.parse(e.target.result)

      if (Object.keys(configuration).length > 0) {
        let invalidConfigPositions = []
        for (const [key, config] of Object.entries(configuration)) {
          if (!Object.keys(config).includes('category')
          ) {
            invalidConfigPositions.push(key)
          }
        }

        if (invalidConfigPositions.length === 0) {
          if (isFromCategorization) {
            let vars = []

            for (const [key, conf] of Object.entries(configuration)) {
              vars.push({
                propName: key,
                category: conf.category,
                label: conf.label ? conf.label : key,
              })
            }

            setVariables(vars)
          } else {
            varsConfig = configuration
            handleUpload()
          }
        } else {
          enqueueSnackbar(
            'Invalid configuration for this variables: ' + invalidConfigPositions.join(', '),
            { variant: 'error' }
          )
        }
      } else {
        enqueueSnackbar('Invalid configuration file', { variant: 'error' })
      }

      ev.target.value = null;
    }

    if (ev.target.files.length > 0 && ev.target.files[0]) {
      fr.readAsText(ev.target.files[0]);
    }
  }

  const handleConfigExport = () => {
    const configurationDataToExportJson = JSON.stringify(variables);

    const anchorLinkDonwload = document.createElement('a');
    anchorLinkDonwload.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(configurationDataToExportJson);
    anchorLinkDonwload.download = `$variable_configuration_export.json`;

    anchorLinkDonwload.click();
  }

  const handleCloseMoreModal = () => setOpenMore(false);

  const handleSaveMoreModal = (moreData) => {
    setSectionMap((prevSectionMap) => ({
      ...prevSectionMap,
      [field.propName]: {
        sections: moreData.sections.map((d) => ({ name: d.name, value: +d.value })),
        xExtent: moreData.xExtent,
        yExtent: moreData.yExtent,
        variable: field.propName,
        shapeFile: moreData.shapeFile,
        histogramConfig: moreData.histogramConfig,
      },
    }));
    setOpenMore(false);
  };

  const mapForm = (
    <ThemeProvider theme={themeCriteria}>
      <AppBar position='static'>
        <Tabs
          indicatorColor='secondary'
          value={value}
          onChange={(e, newValue) => handleChange(e, newValue)}
        >
          <Tab label={t('dataset_upload_options')} {...a11yProps(0)}
               sx={colorTabStyle}/>
          <Tab
            label={t('dataset_variable_configuration')}
            {...a11yProps(1)}
            disabled={variables === null || uploading}
            sx={colorTabStyle}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MuiContainer display='flex'>
          <ThemeProvider theme={themeCriteria}>
            {uploadErrors.map((error, index) => (
              <Alert severity='error' key={index} data-cy='importing-errors'>
                {error}
              </Alert>
            ))}
            {uploadMessages.map((message, index) => (
              <Alert severity='success' key={index} data-cy='importing-messages'>
                {message}
              </Alert>
            ))}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <CardActionArea
                    data-cy='card_option_file'
                    value='image_file'
                    onClick={() => handleOnClickUploadOption('image_file')}
                  >
                    <Grid
                      container
                      direction='column'
                      style={gridContainerStyle}
                    >
                      <MuiDescriptionIcon/>

                      <Typography variant='caption' color='textSecondary'>
                        Tipos de archivos admitidos: .csv,.json,.geojson,.parket
                      </Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardActionArea
                    data-cy='card_option_url'
                    value='url'
                    onClick={() => handleOnClickUploadOption('url')}
                  >
                    <Grid
                      container
                      direction='column'
                      style={gridContainerStyle}
                    >
                      <MuiLinkIcon/>

                      <Typography variant='caption' color='textSecondary'>
                        Tipos de links admitidos: s3, drive, public link
                      </Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            {showContent ? (<>
              <Grid container spacing={2}>
                {uploadOption === 'image_file' ? (
                  <Grid item xs={12}>
                    <MuiGridInputText item xs={12}>
                      <MuiTextField
                        name='file'
                        margin='dense'
                        type='file'
                        variant='outlined'
                        data-cy='image_file'
                        inputProps={{ style: { height: 35 }, accept: '.csv,.json,.geojson,.parket' }}
                        style={{ paddingTop: 5, paddingBottom: error ? 0 : 23 }}
                        onChange={(e) => handleFilesChange(e)}
                        fullWidth
                        error={t(errors.file?.message)}
                      />
                    </MuiGridInputText>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <MuiGridInputText item xs={12}>
                        <TextField
                          disabled={uploadOption === 'image_file'}
                          name='url'
                          autoFocus
                          margin='dense'
                          type='text'
                          label={t('url_label')}
                          data-cy='url'
                          variant='outlined'
                          fullWidth
                          value={url}
                          error={t(errors.url?.message)}
                          onChange={handleUrlChange}
                        />
                      </MuiGridInputText>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <MuiGridInputText item xs={12}>
                <TextField
                  name='datasetName'
                  margin='dense'
                  type='text'
                  label={t('name_the_dataset')}
                  data-cy='dataset_name'
                  variant='outlined'
                  fullWidth
                  error={t(errors.datasetName?.message)}
                />
              </MuiGridInputText>
              <Grid item xs={12} container justifyContent='flex-end'>
                <input
                  accept=".json"
                  style={{ display: 'none' }}
                  id="contained-button-file-dataset"
                  multiple
                  type="file"
                  onChange={(e) => handleConfigFileChange(e, false)}
                />
                <label htmlFor="contained-button-file-dataset">
                  <Button
                    variant='contained'
                    color='primary'
                    component="span"
                    data-cy='upload_dataset_with_config_options'
                    disabled={!isValid || uploading}
                    startIcon={uploading && <CircularProgress size={20} color='secondary' />}>
                    Upload with configuration
                  </Button>
                </label>

                <Button
                  style={buttonStyle}
                  onClick={handleUpload}
                  variant='contained'
                  color='primary'
                  data-cy='upload_dataset_options'
                  disabled={!isValid || uploading}
                  startIcon={uploading && <CircularProgress size={20} color='secondary' />}>
                  Upload
                </Button>
              </Grid>
            </>) : (<Grid item xs={12}></Grid>)}
          </ThemeProvider>
        </MuiContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {variables !== null && !uploading && (
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={tableContainerStyle}>
                <MuiTable stickyHeader size="small" aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align='right'>{t('numeric')}</TableCell>
                      <TableCell align='right'>{t('categorical')}</TableCell>
                      <TableCell align='right'>{t('factor_column')}</TableCell>
                      <TableCell align='right'>{t('statistic_column')}</TableCell>
                      <TableCell align='right'>{t('weight_column')}</TableCell>
                      <TableCell align='right'>{t('ignore')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {variables?.map((d, index) => (
                      <TableRow key={`d-${index}`} data-cy='var_row'>
                        <TableCell component='th' scope='row'>
                          <div style={divStyle}>
                            <MuiTextField
                              style={{ marginRight: 10 }}
                              label="Label"
                              id="outlined-size-small"
                              defaultValue={d.label ? d.label : d.propName}
                              variant="outlined"
                              onChange={(e) => changeVariableLabel(d, index, e.target.value)}
                              size="small" />
                            {d.propName}
                          </div>
                        </TableCell>
                        <TableCell align='right' data-cy={`td_numeric_${d.propName}`}>
                          <Radio
                            checked={d.category === 'numeric'}
                            value='numeric'
                            data-cy={'radio_numeric_' + d.propName}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right' data-cy={`td_categorical_${d.propName}`}>
                          <Radio
                            checked={d.category === 'categorical'}
                            value='categorical'
                            data-cy={`radio_categorical_${d.propName}`}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right' data-cy={`td_factor_${d.propName}`}>
                          <Radio
                            checked={d.category === 'factor'}
                            value='factor'
                            data-cy={`radio_factor_${d.propName}`}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right' data-cy={`td_statistic_${d.propName}`}>
                          <Radio
                            checked={d.category === 'statistic'}
                            value='statistic'
                            name={d.propName}
                            data-cy={`radio_statistic_${d.propName}`}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right' data-cy={`td_weight_${d.propName}`}>
                          <Radio
                            checked={d.category === 'weight'}
                            value='weight'
                            data-cy={`radio_weight_${d.propName}`}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Radio
                            checked={d.category === 'ignore'}
                            value='ignore'
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <IconButton
                            onClick={() => showMoreSettings(d)}
                            data-cy={`more_settings_${d.propName}`}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Grid>
            <Grid item xs={12} style={gridItemStyle} container justifyContent='flex-end'>
              <input
                accept=".json"
                style={{ display: 'none' }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => handleConfigFileChange(e)}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained"
                  color="primary"
                  data-cy='config_variable_file'
                  component="span">
                  Import Configuration
                </Button>
              </label>

              <Button
                style={{ marginLeft: 10 }}
                data-cy='config_variable_export'
                variant="contained"
                color="primary"
                onClick={() => handleConfigExport()}>
                Export Configuration
              </Button>
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </ThemeProvider>
  );

  const actions = (
    <>
      <MuiButton
        onClick={onClose}
        variant='text'
        disabled={uploading}
        data-cy='close_upload_dataset'
        sx={{color:'#005F64', borderRadius: '20px',}}
      >
        {t('cancel_btn')}
      </MuiButton>
      <MuiButton
        disabled={uploading || !variables}
        onClick={() => onSave()}
        sx={{color:'#005F64', borderRadius: '20px',}}
        variant='outlined'
        data-cy='save_upload_dataset'
        startIcon={uploading && <CircularProgress size={20} color='secondary' />}
      >
        {t('save_generic_btn')}
      </MuiButton>
    </>
  );

  return (
    <FormProvider {...methods}>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        horizontal='center'
        vertical='top'
        severity='error'
        open={openToast}
      />
      <form>
        <Modal
          open={open}
          onClose={onClose}
          title={t('upload_new_dataset')}
          maxWidth={'lg'}
          actions={actions}
        >
          {mapForm}
        </Modal>
      </form>
      {openMore && (
        <MoreModal
          open={openMore}
          field={field}
          weightField={variables.find((v) => v.category === 'weight').propName}
          collectionName={urlCollectionName}
          onSave={(data) => handleSaveMoreModal(data)}
          onClose={() => handleCloseMoreModal()}
        ></MoreModal>
      )}
    </FormProvider>
  );
};

export default FormModal;
