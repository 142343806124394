// External library imports
import React from 'react';
import {CssBaseline} from '@mui/material';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

// Custom Hooks and Contexts
import TranslationProvider from 'components/providers/TranslationProvider';
import AuthProvider from './components/providers/AuthProvider';
import CustomThemeProvider from 'components/providers/CustomThemeProvider';
import LoadingProvider from './components/providers/LoadingProvider';

// Internal component and function imports
import Views from './components/common/Views';
import ProsperiaLoader from './components/common/ProsperiaLoader';
import {MuiGrid} from "./appStyle";
import Header from "./components/common/Header";
import {useLocation} from "react-router-dom";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex = 3) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const App = () => {
  const location = useLocation();
  const NoHeaderRoutes = ["/login", "/reset", "/forgot-password", "/"];
  const showHeader = !NoHeaderRoutes.includes(location.pathname);

  return (
    <CustomThemeProvider>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <TranslationProvider>
          <AuthProvider>
            <LoadingProvider>
              <MuiGrid container direction='column'>
                {showHeader && <Header />}
                <Views />
                <ProsperiaLoader />
              </MuiGrid>
            </LoadingProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </AuthProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </CustomThemeProvider>
  );
};

export default App;
