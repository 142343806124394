// External library imports
import { useQuery } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchSchemes = (id) =>
  axios.post(apiPaths.schemes, { user_id: id }, status200)
       .then((resp) => resp.data);

const useSchemes = (id) =>
  useQuery(['schemes', id], () => fetchSchemes(id), {
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

export default useSchemes;
