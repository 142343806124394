import {Box, ThemeProvider} from '@mui/material';
import {
  MuiGridBox,
  MuiTab,
  MuiToolbar
} from '../organization/styles/organization';
import themeCriteria from '../../../../theme/criteria';
import {NavLink, useLocation} from 'react-router-dom';
import * as React from 'react';
import {useTranslation} from '../../../providers/TranslationProvider';
import {usePermissions} from '../../../hooks/usePermissions';
import {useTheme} from '../../../providers/CustomThemeProvider';
import {MuiBoxTab, MuiTabs} from "./styles/tabs";


const TabsCustoms = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { theme: currentTheme } = useTheme();
  const { hasPermissionFor } = usePermissions();

  const isSpecificationView = location.pathname.split('/').includes('specification')
  const isWorkspaceView = location.pathname.split('/').includes('workspace')
  const prefixPath = location.pathname.split('/').slice(0, 3).join('/');

  const tabStyle = {
    color: currentTheme.palette.primary.main,
  }

  const activeTab = location.pathname.split('/')[3];
  const createTab = (label, value, to) => ({ label, value, to });

  const initializeTabs = () => {
    const tabs = [
      createTab(t('scenarios'), 'scenarios', '/scenarios'),
    ];
    if (hasPermissionFor('create_datasets')) {
      tabs.push(createTab(t('datasets'), 'datasets', '/datasets'));
    }
    if (hasPermissionFor('create_users') && !isWorkspaceView) {
      tabs.push(createTab(t('users'), 'users', '/users'));
    }
    return tabs;
  };
  const getTabValue = () => activeTab || 'scenarios';
  const tabs = initializeTabs();
  return (
    <>
      {!isSpecificationView && (
        <Box my={3} display="flex" justifyContent="center" sx={{height:'45px'}}>
        <MuiToolbar>
          <MuiGridBox container spacing={4}>
            <ThemeProvider theme={themeCriteria}>
              <MuiBoxTab>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <MuiTabs
                  value={getTabValue()}
                  orientation='horizontal'
                >
                  {tabs.map((t) => (
                    <MuiTab
                      style={tabStyle}
                      label={t.label}
                      value={t.value}
                      key={t.value}
                      component={NavLink}
                      to={`${prefixPath}${t.to}`}
                      data-cy={t.value}
                    />
                  ))}
                </MuiTabs>
                </Box>
              </MuiBoxTab>
            </ThemeProvider>
          </MuiGridBox>
        </MuiToolbar>
        </Box>
      )}
    </>
  )
}

export default TabsCustoms;
