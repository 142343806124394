// External library imports
import * as React from 'react';
import { IconButton } from '@mui/material';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon
} from '@mui/icons-material';
import {
  actionsComponentStyle,
  iconStyle,
  MuiTablePagination
} from "./styles/table";

const TPagination = ({
    data,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFirstPage,
    handlePreviousPage,
    handleNextPage,
    handleLastPage
}) => {
    return (
        <MuiTablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={(data?.length || 0)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() => (
              <div style={actionsComponentStyle}>
                <IconButton onClick={handleFirstPage} disabled={{ page } === 0}>
                  <FirstPageIcon sx={iconStyle} />
                </IconButton>
                <IconButton onClick={handlePreviousPage} disabled={{ page } === 0}>
                  <KeyboardArrowLeft sx={iconStyle}/>
                </IconButton>
                <IconButton onClick={handleNextPage} disabled={{ page } >= Math.ceil(({ data }?.length || 0) / { rowsPerPage }) - 1}>
                  <KeyboardArrowRight sx={iconStyle}/>
                </IconButton>
                <IconButton onClick={handleLastPage} disabled={{ page } >= Math.ceil(({ data }?.length || 0) / { rowsPerPage }) - 1}>
                  <LastPageIcon sx={iconStyle}/>
                </IconButton>
              </div>
            )}
        />
    );

};
export default TPagination;
