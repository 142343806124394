// External library imports
import {Container, Grid, styled, Tab, Toolbar} from "@mui/material";

export const MuiContainerNavBar = styled(Container)(({ theme }) => ({
  boxShadow: 'none',
  overflow: 'hidden',
  marginBottom: 24,
  maxWidth: '1435px !important',
}));

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: '#FAFAFA',
}));

export const MuiGridBox = styled(Grid)(({ theme }) => ({
  marginTop: '5px !important',
}));

export const MuiGridImgContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

export const MuiImage  = styled("img")(({ theme }) => ({
  width: 248,
  backgroundColor: 'white',
  height: 248,
  borderRadius:'19px',
  border:'3px solid',
  borderColor: theme.palette.primary.main,
  objectFit: 'scale-down',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  paddingTop: 20,
}));

export const MuiTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  paddingLeft: theme.spacing(1),
  marginLeft: theme.spacing(3),
  fontFamily: 'Montserrat',
  fontSize: '14px'
}));

export const textOrg ={
  textAlign: 'end',
  margin: '0px 48px 16px 0px'
}
