// External library imports
import * as React from 'react';

// Internal component and function imports
import { usePermissions } from 'components/hooks/usePermissions';
import NewUser from './NewUser';
import {MuiGridButtonContainer} from './styles/user';

const ActionBar = () => {
  const { hasPermissionFor } = usePermissions();

  return (
    <MuiGridButtonContainer container>
      {hasPermissionFor('create_users') && <NewUser />}
    </MuiGridButtonContainer>
  );
};

export default ActionBar;
