// External library imports
import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ModalDialogCalcFormulaBuilder from './modal/ModalDialogCalcFormulaBuilder';

const ThirdStep = ({ scheme, variables, setScheme }) => {
  const [showPrioritizationSelect, setShowPrioritizationSelect] = React.useState(false)

  const handleOnSaveFormula = (e) => {
    setScheme(prevScheme => ({
      ...prevScheme,
      prioritizationCustomVariables: [{
        formula: e.value,
        name: e.name,
      }]
    }))
  }

  React.useEffect(() => {
    if (scheme.prioritizationCustomVariables && scheme.prioritizationCustomVariables.length > 0) {
      setShowPrioritizationSelect(true)
    }
  }, [scheme.prioritizationCustomVariables])

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        {showPrioritizationSelect ? (
          <>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={scheme.prioritizationCustomVariables}
                getOptionLabel={(option) => option.name}
                value={scheme.prioritizationCustomVariables}
                onChange={(e, values) => {
                  setScheme(prevScheme => ({
                    ...prevScheme,
                    prioritizationCustomVariables: values
                  }))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Option"
                  />
                )}
              />
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end'>
              <ModalDialogCalcFormulaBuilder
                isFromSegmentation={false}
                onSaveFormula={(e) => handleOnSaveFormula(e)}
                prioritizationVariables={scheme.prioritizationCustomVariables}
                variables={variables}/>
            </Grid>
          </>
        ) : (
          <Grid container item xs={12} justifyContent='flex-end'>
            <Button variant="contained"
              color="secondary"
              onClick={() => setShowPrioritizationSelect(true)}
              data-cy='btn_add_prio_var'>
              {'+ Add Prioritization Variable'}
            </Button>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default ThirdStep;
