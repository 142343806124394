import { getRandomId } from './formatter';

export const getNormalPolicy = (policy) => {
  if (!policy.affected_variables) policy.affected_variables = [];
  if (!policy.regimes) policy.regimes = [];
  if (!policy.prioritization || policy.prioritization.length === 0) {
    policy.prioritization = [];
    policy.prioritization.push({
      name: '',
      prioritize_low: true,
      formula: ''
    })
  }

  const hasCost = policy.affected_variables.find(d => d.name === 'cost');

  // add cost if missing
  if (!hasCost) {
    policy.affected_variables.unshift({
      name: 'cost',
      effects: [{
        type: 'factor',
        label: '',
        value: ''
      }]
    })
  }

  if (policy.regimes.length === 0) {
    policy.regimes.push({
      regime_label: 'cost',
      slider_definition: {
        slider_label: 'cost',
        symbol: '$',
        min: 0,
        max: 0,
        'default': 0
      },
      effects: [
        {
          variable: 'cost',
          effect: ''
        }
      ]
    })
  }

  return Object.assign({
    id: getRandomId(),
    name: 'Policy',

    previous_policies: [],

    segmentation_variables: {
      in_dataset: [],
      custom: [],
      dataset_custom: [],
      max_depth: 3
    },

    affected_variables: [],

    regimes: [],

    statistics: [],

    budget: 0,
    methodology: '',

    // additional fields
    methodology_json: {},
    tree_state: null,
    map_state: null
  }, policy);
}

export const getAggrPolicy = (policy) => {
  return Object.assign({
    id: getRandomId(),
    name: 'Aggregation',
    prioritization: [],
    segmentation_variables: {
      in_dataset: [],
      geographic: [],
      custom: [],
      dataset_custom: [],
      max_depth: 3
    },

    multi_policy_formulas: [],
    statistics: [],

    budget: 0,
    methodology: '',

    // additional fields
    methodology_json: {},
    tree_state: null,
    map_state: null
  }, policy);
}
