import {radioClasses} from "@mui/material/Radio";
import {Drawer, ListItemText, styled} from "@mui/material";

export const MuiDrawer = styled(Drawer)(({ theme }) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(7) + 1,
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(7) + 1,
  },
}));

export const MuiListItem = styled(Drawer)(({ theme }) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(7) + 1,
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(7) + 1,
  },
}));

export const MuiDivRoot  = styled("div")(({ theme }) => ({
  display: 'flex',
  zIndex: 0,
}));

export const MuiDivToolbar = styled("div")(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const listItemTextCustomStyle = styled(ListItemText)(({ theme }) => ({
  fontWeight: '100 !important',
  fontSize: '13px !important',
}));

export const fixMenuIconsStyle = {
  marginTop: 5,
}

export const boxStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '20px',
  top: '20%',
  marginLeft: '70px',
  zIndex: '1',
}

export const listStyle = {
  paddingTop: 80
}

export const listItemTextStyle = {
  textAlign: 'center'
}

export const gridContainerStyle = {
  textAlign: 'left', paddingLeft:'5px'
}

export const listItemStyle = {
  textAlign: 'center',
  color: 'white',
  display: 'none'
}

export const radioGroupStyle = {
  flexDirection: 'row',
  gap: 2,
  [`& .${radioClasses.checked}`]: {
    [`& .${radioClasses.action}`]: {
      inset: -1,
      border: '3px solid',
      borderColor: 'primary.500',
    },
  },
  [`& .${radioClasses.radio}`]: {
    display: 'contents',
    '& > svg': {
      zIndex: 2,
      position: 'absolute',
      top: '-8px',
      right: '-8px',
      bgcolor: 'background.body',
      borderRadius: '50%',
    },
  }
}

export const selectDrawerStyle = {
  width: 250,
  color: 'white',
  textAlign: 'center',
}

export const drawerStyle = {
  position: 'absolute'
}

export const listItemIconStyle = {
  minWidth:'35px',
  marginLeft: 'unset'
}

export const paddingCommonStyle = {
  paddingRight:'0px !important'
}

export const gridHeaderStyle = {
  marginTop:'30px'
}
