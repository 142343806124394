// External library imports
import { axisLeft, axisBottom } from 'd3-axis';
import { select } from 'd3-selection';
import { min, max, range } from 'd3-array';
import { scaleLinear, scaleBand } from 'd3-scale';

// Internal function and utility imports
import { colors, styleMap } from '../../../utils/colors';
import { getVariableLabel } from '../../../utils/utils';
import patternify from '../../../utils/patternify.js';
import addSections from '../add-sections-band';
import renderSlider from './step-slider';

const renderHistogram = (params) => {
  let width = params.width || 400,
    height = params.height || 250,
    margin = {
      top: 15,
      right: 5,
      bottom: 45,
      left: 45,
    };

  let title = params.fieldName;
  let container = params.container;
  let criteriaPriority = params.criteriaPriority || 'low';
  let update;
  let chart;
  let stackesContainer;
  let bars;
  let addSlider;
  let sections = params.sections;
  let yAxis;
  let xAxisTicks = 15;
  let config = params.config;

  let data = params.data.map((d) => {
    return {
      ...d,
      value: d.sum,
      current: d.hasOwnProperty('current') ? d.current : d.sum,
    };
  });

  let minCriteria = min(data, (d) => d.criteria);
  let maxCriteria = max(data, (d) => d.criteria);


  // backward compatibility fix for older configured sections.
  // making sure section values are not out of min and max criteria range.
  sections.forEach((d) => {
    if (d.value < minCriteria) {
      d.value = minCriteria;
    } else if (d.value > maxCriteria) {
      d.value = maxCriteria;
    }
  });

  let bands = range(minCriteria, maxCriteria + 1);

  if (criteriaPriority === 'low') {
    bands.unshift(minCriteria - 1 !== -1 ? minCriteria - 1 : 0);
  } else {
    bands.push(maxCriteria + 1);
  }

  let currentValue = Math.max(bands[0], Math.min(bands[bands.length - 1], params.currentValue));

  // scales
  let m = max(data, (d) => Math.max(d.value, d.current));
  let domain = params.maxDomain ? [0, Math.min(params.maxDomain, m)] : [0, m];
  let x = scaleBand().domain(bands);
  let y = scaleLinear().domain(domain).clamp(true);

  let onUpdate = () => {};

  let compare = (d) => {
    return criteriaPriority === 'high' ? d.criteria >= currentValue : d.criteria <= currentValue;
  };

  let styleAccessor = (d, prop) => {
    const style = { ...styleMap[d.type] };

    if (d.type === 'current') {
      style.fill = params.color;
    }

    if (compare(d)) return style[prop];

    if (prop === 'fill' && d.type === 'current') return style[prop];

    return prop === 'fill' ? colors.notAccepted : style[prop];
  };

  let bandWidth = 0;
  let bandWidthPriority = 0;

  const main = () => {
    // calculations
    let chartWidth = width - margin.left - margin.right;
    let chartHeight = height - margin.top - margin.bottom;

    x.range([0, chartWidth]);
    y.range([chartHeight, 0]);

    bandWidth = x.bandwidth();
    bandWidthPriority = criteriaPriority === 'low' ? bandWidth : 0;

    // drawing
    chart = patternify(container, 'g', 'histogram')
      .attr('pointer-events', 'none')
      .attr(
        'transform',
        `translate(${margin.left + params.translation[0]}, ${margin.top + params.translation[1]})`
      )
      .attr('opacity', params.visible ? 1 : 0);

    patternify(chart, 'text', 'x-axis-title')
      .attr(
        'transform',
        `translate(
                ${chartWidth / 2}, ${chartHeight + margin.bottom + 6}
            )`
      )
      .style('text-anchor', 'middle')
      .text(params.variables ? getVariableLabel(params.variables, title) : title);

    let xAxis = patternify(chart, 'g', 'x-axis')
      .attr('transform', `translate(0, ${chartHeight - 10})`)
      .call(axisBottom(x).tickPadding(15).ticks(xAxisTicks));

    yAxis = patternify(chart, 'g', 'y-axis').call(axisLeft(y).ticks(5));

    yAxis.selectAll('path').remove();
    xAxis.selectAll('path').remove();
    xAxis.selectAll('line').remove();

    stackesContainer = patternify(chart, 'g', 'stacks');

    drawBars();

    let currentLine = patternify(chart, 'line', 'current-line')
      .attr('x1', x(currentValue) + bandWidthPriority)
      .attr('x2', x(currentValue) + bandWidthPriority)
      .attr('y1', 0)
      .attr('y2', chartHeight)
      .attr('stroke', '#2F353B')
      .attr('stroke-width', '1.5px')
      .attr('stroke-dasharray', '5 5');

    addSlider = function (sliderParams) {
      return renderSlider(
        Object.assign(sliderParams, {
          container: chart,
          x: 0,
          y: chartHeight + 8,
          width: chartWidth,
          sections: sections,
          scale: x,
          bandWidth: bandWidthPriority,
          config,
        })
      );
    };

    update = () => {
      chart
        .selectAll('.stack-bar')
        .attr('stroke', (d) => styleAccessor(d, 'stroke'))
        .attr('stroke-dasharray', (d) => styleAccessor(d, 'strokeDasharray'))
        .attr('fill', (d) => styleAccessor(d, 'fill'));

      currentLine
        .attr('x1', x(currentValue) + bandWidthPriority)
        .attr('x2', x(currentValue) + bandWidthPriority);

      onUpdate(currentLine);
    };

    if (config && config.xLabel) {
      const factor = Math.max(5, Math.floor(bands.length / xAxisTicks));
      xAxis.selectAll('text').style('display', (d, i) => {
        return i % factor === 0 || i === bands.length - 1 ? null : 'none';
      });
    } else {
      xAxis.selectAll('text').remove();
    }

    if (sections && sections.length) {
      chart
        .selectAll('.x-axis-title')
        .attr('transform', `translate(${chartWidth / 2}, ${chartHeight + margin.bottom - 8})`);

      addSections({
        chart,
        sections,
        xScale: x,
        height,
        margin,
      });
    }

    return main;
  }

  function drawBars() {
    let stacks = patternify(stackesContainer, 'g', 'stack', data);

    bars = patternify(stacks, 'rect', 'stack-bar', (d) => {
      return [
        { criteria: d.criteria, type: 'current', value: d.current || 0 },
        { criteria: d.criteria, type: 'original', value: d.sum || 0 },
      ];
    })
      .attr('data-criteria', (d) => d.criteria)
      .attr('x', (d) => x(d.criteria) + 0.75)
      .attr("width", Math.max(1, bandWidth - 1.5))
      .attr('y', y(0))
      .attr('height', 0)
      .each(function(d) {
        if (d.criteria !== 'not found' && d.criteria) {
             select(this)
            .transition().duration(1300)
            .attr('y', y(d.value))
            .attr('height', d => y(0) - y(d.value));
        }
      })
      .attr('rx', 2)
      .attr('stroke-width', 0.5)
      .attr('stroke', (d) => styleAccessor(d, 'stroke'))
      .attr('stroke-dasharray', (d) => styleAccessor(d, 'strokeDasharray'))
      .attr('fill', (d) => styleAccessor(d, 'fill'))
      .style("filter", "drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.2))");

  }

  main.updateData = (newData) => {
    data = newData;
    const m = max(data, (d) => Math.max(d.current, d.sum));
    const domain = params.maxDomain ? [0, Math.min(params.maxDomain, m)] : [0, m];

    y.domain(domain);
    yAxis.call(axisLeft(y).ticks(5));
    yAxis.selectAll('path').remove();
    drawBars();
  };

  main.addSlider = (sliderParams) => {
    if (typeof addSlider === 'function') {
      return addSlider(sliderParams);
    }
  };

  main.update = (value) => {
    currentValue = Math.ceil(value);
    update();
    return main;
  };

  main.onUpdate = (f) => {
    onUpdate = f;
    return main;
  };

  main.updateColor = (color) => {
    params.color = color;

    bars.attr('fill', (d) => styleAccessor(d, 'fill'));
  };

  main.show = () => {
    params.visible = true;
    chart
      .attr('opacity', params.visible ? 1 : 0)
      .attr('pointer-events', params.visible ? 'all' : 'none');
  };

  main.hide = () => {
    params.visible = false;
    chart
      .attr('opacity', params.visible ? 1 : 0)
      .attr('pointer-events', params.visible ? 'all' : 'none');
  };

  return main();
}

export default renderHistogram;
