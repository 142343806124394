import { getRandomId } from './formatters';

// get node level data, accessible as node.data
export const getNodeData = ({
  bins, // actual bins data from /compute endpoint
  name, // node name
  value, // node threshold value
  splitBy, // split options
  parent, // parent node
  nodeKey, // the group key that node represents
  regime, // selected regime
  original_data, // pre data, without merged.
  nodeSegCustomType,
}) => {
  let amount = regime ? regime.slider_definition.default : 0;

  if (parent) {
    amount = parent.data.amount;
  }

  return {
    isRoot: parent === null,
    id: `node-${getRandomId()}`,
    nodeKey: nodeKey,
    name: name,
    bins: bins,
    __original_data: JSON.parse(JSON.stringify(original_data || bins)),
    value: value, // threshold
    splitBy: splitBy,
    children: [],
    regime: regime,
    amount: amount,
    fakePercent: {
      pie_percent_pre: null,
      pie_percent_post: null,
      siblings_sum_pre: null,
      siblings_sum_post: null,
    },
    nodeSegCustomType,
  };
};
