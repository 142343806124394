import {styled, Tabs} from "@mui/material";

export const MuiTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(-4),
  padding: theme.spacing(0),
  borderBottom:'1px solid',
  borderColor: localStorage.getItem('colorSecondary'),
  '& .Mui-selected': {
    fontSize: 14,
    fontFamily:'Montserrat',
    color: localStorage.getItem('colorSecondary'),
    transition: 'font-size 10ms, font-weight 10ms',
  },
  '& .MuiTabs-indicator': {
    height: 3,
    width: '0',
    borderRadius: '10px 10px 0 0',
    transition: 'width 0.3s',
    backgroundColor: localStorage.getItem('colorSecondary'),
    marginBottom: theme.spacing(0)
  },
  '& .MuiTabs-indicator[data-indicator-color="secondary"]': {
    backgroundColor: localStorage.getItem('colorSecondary'),
  },
}));

export const MuiBoxTab= styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
