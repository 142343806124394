import {Grid, Slider, styled} from "@mui/material";

export const MuiGridHistogram = styled(Grid)(({ theme }) => ({
  marginTop: '15px',
}));

export const MuiSliderAxisY = styled(Slider)(({ theme }) => ({
  height: '92% !important',
}));

export const divInitStyle = {
  width: '100%'
}

export const gridItemStyle = {
  margin: '4% 6% 0 13%'
}

export const firstGridStyle = {
  margin: '3% 0 6% 0'
}

export const buttonStyle = {
  marginBottom: '30px'
}

export const secondGridStyle = {
  margin: '3% 6% 0 13%'
}
