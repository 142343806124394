import {Paper, styled, TextField} from "@mui/material";

export const MuiDivPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: 600,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3, 4, 3),
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}));

export const ModalDialogAddFunction = styled(TextField)(({ theme }) => ({
  margin: '1rem',
}));

export const ModalDialogPolice = styled(TextField)(({ theme }) => ({
  margin: '1rem',
}));

export const ModalDialogPriorization = styled(TextField)(({ theme }) => ({
  margin: '1rem',
  width: '500px'
}));

export const ModalDialogCalcFormulaBuilder = styled(TextField)(({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '1rem'
}));

export const ModalDialogFormulaBuilder = styled(TextField)(({ theme }) => ({
  margin: '1rem',
}));

export const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 !important'
}

export const formPaddingStyle = {
  width: 500
}

export const textPaddingStyle = {
  width: 240
}

export const buttonMarginStyle = {
  margin: '20px'
}

export const TypographyStyle = {
  marginRight: '397px',
  width: 'max-content'
}

export const TextareaAutosizeStyled = {
  width: '502px',
  height: '116px'
}

export const TextFieldStyle = {
  width: 500
}

export const divStyle = {
  display: 'flex',
  marginTop: 10,
  justifyContent: 'flex-end'
}

export const gridItemStyle = {
  marginTop: '1rem'
}

export const gridFormulaStyle = {
  marginLeft: '360px',
  width: 'max-content'
}

export const typographyFormulaStyle = {
  marginRight: '397px',
  width: 'max-content'
}

export const textareaAutosizeStyle = {
  width: '502px',
  height: '116px'
}

export const widthCommonStyle = {
  width: 500
}

export const buttonStyle = {
  margin: '2rem'
}

export const divFunctionStyle = {
  display: 'flex',
  alignItems: 'center'
}

export const inputVariablesStyle = {
  width:'100% !important'
}

export const TextareaAutosizeStyle = {
  width: '100%',
  height: '116px'
}
