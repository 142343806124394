// External library imports
import * as React from 'react';
import {Grid, Tab} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {SnackbarProvider} from 'notistack';

// Custom Hooks and Contexts
import {useAuth} from '../../../../providers/AuthProvider';
import useScheme from '../../../../../api/hooks/useScheme';
import {useLoading} from '../../../../providers/LoadingProvider';

// Redux Actions and Store
import {
  setRequestPolicies,
  setTargetCollection,
  setTargetScheme
} from '../../../../../store/appSlice';

// Internal component and function imports
import Toast from '../../../../common/Toast';
import TreePage from './TreePage';
import Drawer from '../../../../common/Drawer';

// Style and asset imports
import {
  MuiContainer,
  MuiPaperTabsContainer,
  MuiTabs,
  MuiToolbar
} from '../styles/treeView';

const DEFAULT_PRIORITIZATION = 'ingreso del hogar';

//remember add to state the scheme name
const TreeView = () => {
  const [severity, setSeverity] = React.useState('success')
  const [toastMsg, setToastMsg] = React.useState(null)
  const [openToast, setOpenToast] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [aggrTreeUpdateEnabled, setAggrTreeUpdateEnabled] = React.useState(false);
  const [tabs, setTabs] = React.useState([]);
  const [allTabRoots, setAllTabRoots] = React.useState({})
  const [localPolicies, setLocalPolicies] = React.useState([]);
  const [policiesToAggregate, setPoliciesToAggregate] = React.useState([]);
  let treePageRefs = React.useRef([])

  const { user } = useAuth();
  const location = useLocation();
  const orgId = location.pathname.split('/')[2] === 'workspace' ?
    user.id : location.pathname.split('/')[2];
  const {data: scenario, isLoading} = useScheme({
    user_id: orgId,
    scheme_id: location.pathname.split('/')[4],
    enabled: true
  });
  const dispatch = useDispatch();
  const { setIsLoading } = useLoading();
  let scheme = {};

  const handleCloseToast = () => {
    setOpenToast(false);
    setSeverity('success')
    setToastMsg(null);
  };

  const onTreeUpdate = (name, leaves) => {
    if (leaves.length > 0 && name) {
      setLocalPolicies(prevPolicies => {
        return prevPolicies.map(p => {
          if (p.name === name) {
            return { ...p, leaves };
          }
          return p;
        });
      })

      const currentTab = tabs[activeTab];

      if (currentTab && currentTab.type !== "aggregation") {
        setAggrTreeUpdateEnabled(false)
      }
    }
  }
  const updateAggrTree = (tabIndex) => {
    const currentTab = tabs[tabIndex];

    if (currentTab.type === 'aggregation') {
      treePageRefs.current.updateAggrTree();
    }
  }

  const handleTabsChange = (event, newValue) => {
    setActiveTab(newValue);
    const currentTab = tabs[newValue];
    // if switching from policy tab to aggr tab, it automatically update the aggr tree
    if (currentTab.type === 'aggregation') {
      updateAggrTree(newValue);
    }
  };

  const onPoliciesSelected = (policiesSelected) =>
    setPoliciesToAggregate(policiesSelected.filter(p => p.selected).map(p => p.id))

  const getSingleConfig = (
    policy,
    {
      type,
      dataset,
      match,
      categoricalFilters,
      numericalFilters,
      schemeSections,
      shapeFiles,
      extents,
      weight,
      otherPolicies,
      binSize,
      histogramConfig,
    }
  ) => {
    const prioritization = policy.prioritization[0];
    const selectionType = '1d';

    let sections = schemeSections.filter((d) => d.variable === prioritization.name)[0];
    let extent = extents.find((d) => d.variable === prioritization.name);

    if (!extent) {
      extent = extents.find((d) => d.variable === DEFAULT_PRIORITIZATION);
    }

    if (!sections) {
      sections = schemeSections.filter((d) => d.variable === DEFAULT_PRIORITIZATION)[0];
    }

    const binsEndpoint = selectionType === '1d' ? '/compute/bins' : '/compute/bins2d';

    let maps = [];
    let mapConfig = null;

    if (type === 'aggregation') {
      maps = policy.segmentation_variables.geographic.map((d) => {
        return {
          collection: shapeFiles[d],
          field: d,
        };
      });

      if (maps.length > 0) {
        const map_state = policy.map_state || {};
        let { mapField: field, collection } = map_state;

        // if map settings was not saved yet, just use first one from maps
        if (!field || !collection) {
          field = maps[0].field;
          collection = maps[0].collection;
        }

        mapConfig = {
          field: field,
          collection: collection,
        };
      }
    }

    let otherPolicyVars = [];

    if (type === 'aggregation' && otherPolicies) {
      otherPolicyVars = otherPolicies.flatMap((d) => {
        return d.report_vars.variables;
      });
    }

    const report_vars = {
      variables: Array.from(
        new Set([
          ...policy.statistics.map((d) => d.stat_var),
          ...(policy.affected_variables || []).flatMap((d) => d.effects.map((d) => d.value)),
          ...(policy.regimes || []).flatMap((d) => d.effects.map((d) => d.variable)),
          ...otherPolicyVars, // report vars from other policies in case of aggregation policy
        ])
      ),
    };

    if (scheme.prioritizationCustomVariables && scheme.prioritizationCustomVariables.length > 0) {
      let customPrioritizationVar = scheme.prioritizationCustomVariables.find(
        (p) => p.name === prioritization.name
      );
      if (customPrioritizationVar) {
        report_vars['groupby_custom'] = [customPrioritizationVar];
      } else {
        report_vars['groupby'] = [prioritization.name];
      }
    } else {
      report_vars['groupby'] = [prioritization.name];
    }

    const priority = policy.prioritization.map((d) => (d.prioritize_low ? 'low' : 'high'));
    const criteriaPriority = selectionType === '1d' ? priority[0] : priority;

    const affectedVarMap = new Map(
      (policy.affected_variables || []).map((d) => [d.name, d.effects])
    );

    const regimes = (policy.regimes || []).map((d) => {
      return {
        ...d,
        effects: d.effects.map((x) => {
          const av = affectedVarMap.get(x.variable);
          return {
            ...x,
            avEffect: (av || []).find((m) => m.label === x.effect),
          };
        }),
      };
    });

    return {
      ...policy,
      regimes,
      type,
      criteria: policy.prioritization.map((d) => d.name),
      report_vars,
      weight,
      match,
      collection: dataset,
      binsEndpoint,
      selectionType, // 1d or 2d
      criteriaPriority,
      sections: histogramConfig && histogramConfig.sections
        ? JSON.parse(JSON.stringify(histogramConfig.sections))
        : sections ? JSON.parse(JSON.stringify(sections.sections)) : [],
      categoricalFilters: categoricalFilters,
      numericalFilters: numericalFilters,
      chart_view: { type: policy.chart_view || 'tree', mapConfig: mapConfig },
      maps, // map variables and collections
      xExtent: histogramConfig && histogramConfig.xExtent
        ? JSON.parse(JSON.stringify(histogramConfig.xExtent))
        : extent ? extent.xExtent : null,
      yExtent: histogramConfig && histogramConfig.yExtent
        ? JSON.parse(JSON.stringify(histogramConfig.yExtent))
        : extent ? extent.yExtent : null,
      binSize,
      histogramConfig: histogramConfig ? JSON.parse(JSON.stringify(histogramConfig)) : null,
      defaultThresholdValue: 0,
    };
  };

  const initChartConfig = React.useCallback((scenario) => {
    let newTabs = [];

    if (scenario) {
      const scheme = scenario.scheme;
      const collection = scenario.collection;
      dispatch(setTargetCollection(scenario.collection))
      dispatch(setTargetScheme(scenario.scheme))

      if (scheme && collection) {
        let categoricalFilters = {};
        let numericalFilters = {};

        if (collection && collection.filters) {
          collection.filters.forEach((d) => {
            if (d.type === 'categorical') {
              categoricalFilters[d.value] = d.values;
            } else if (d.type === 'numerical') {
              numericalFilters[d.value] = { min: d.min, max: d.max };
            }
          });
        }

        let shapeFiles = {};

        if (collection && collection.shapeFiles) {
          collection.shapeFiles.forEach((d) => {
            shapeFiles[d.variable] = d.collection;
          });
        }

        // NORMAL POLICIES
        newTabs = scheme.policies.map((d, i) => {
          let binSize = collection.binSizes.find((bs) => bs.variable === d.prioritization[0].name);
          let histogramConfig = collection.histogramConfigs.find(
            (hc) => hc.variable === d.prioritization[0].name
          );

          if (scheme.schemeCollectionConfig && scheme.schemeCollectionConfig.length > 0) {
            binSize = histogramConfig = scheme.schemeCollectionConfig.find(
              (config) => config.variable === d.prioritization[0].name
            );
          }

          return {
            id: 'tab-' + i,
            policy_id: d.id,
            policy_name: 'pol_' + i,
            name: d.name,
            type: 'normal',
            config: getSingleConfig(d, {
              type: 'normal',
              dataset: scheme.dataset,
              match: scheme.match,
              weight: scheme.weight,
              categoricalFilters,
              numericalFilters,
              schemeSections: collection.sections || [],
              shapeFiles,
              extents: collection.extents || [],
              binSize: scheme.bin_size || binSize ? binSize.binSize : 1,
              histogramConfig,
            }),
          };
        });

        // AGGREGATION
        const aggregation = scheme.aggregation;
        const otherPolicies = newTabs.map((d) => d.config);

        if (aggregation && Object.keys(aggregation).length > 0) {
          let binSize = collection.binSizes.find(
            (bs) => bs.variable === aggregation.prioritization[0].name
          );
          let histogramConfig = collection.histogramConfigs.find(
            (hc) => hc.variable === aggregation.prioritization[0].name
          );

          if (scheme.schemeCollectionConfig && scheme.schemeCollectionConfig.length > 0) {
            binSize = histogramConfig = scheme.schemeCollectionConfig.find(
              (config) => config.variable === aggregation.prioritization[0].name
            );
          }

          newTabs.push({
            id: 'aggregation-tab',
            policy_id: aggregation.id,
            policy_name: 'aggr',
            name: aggregation.name,
            type: 'aggregation',
            config: getSingleConfig(aggregation, {
              type: 'aggregation',
              dataset: scheme.dataset,
              match: scheme.match,
              weight: scheme.weight,
              categoricalFilters,
              numericalFilters,
              schemeSections: collection.sections || [],
              shapeFiles,
              extents: collection.extents || [],
              otherPolicies: otherPolicies,
              binSize: scheme.bin_size || binSize ? binSize.binSize : 1,
              histogramConfig,
            }),
          });
        }

        setLocalPolicies(
          newTabs
            .filter((d) => d.type === 'normal')
            .map((d) => {
              let elegibility = `"${d.config.criteria[0]}" ${
                d.config.criteriaPriority === 'low' ? '<=' : '>='
              } $threshold$`;
              if (
                d.config.prioritization[0].formula &&
                d.config.prioritization[0].formula.length > 0 &&
                d.config.prioritization[0].name === d.config.criteria[0]
              ) {
                elegibility = `${d.config.prioritization[0].formula} ${
                  d.config.criteriaPriority === 'low' ? '<=' : '>='
                } $threshold$`;
              }

              return {
                id: d.policy_id,
                name: d.policy_name,
                elegibility,
                leaves: [],
              };
            })
        )
      }
    }

    setTabs(newTabs);
  }, [location.pathname]);


  React.useEffect(() => {
    initChartConfig(scenario)
  }, [scenario?.scheme])

  React.useEffect(() => {
   setIsLoading(isLoading);
  }, [isLoading])

  React.useEffect(() => {
    if (localPolicies && localPolicies.length > 0) {
      setPoliciesToAggregate(localPolicies.map(p => p.id))
    }
  }, [localPolicies])

  React.useEffect(() => {
    dispatch(setRequestPolicies(localPolicies.filter(p => policiesToAggregate.indexOf(p.id) > -1)));
  }, [policiesToAggregate])

  return (
    <SnackbarProvider maxSnack={10}>
      <MuiContainer>
        <Toast
          message={toastMsg}
          handleClose={handleCloseToast}
          severity={severity}
          horizontal='left'
          vertical='bottom'
          open={openToast}
        />
        {scenario?.scheme.dataset !== '' && (
          <MuiToolbar>
            <Grid container>
              <MuiPaperTabsContainer elevation={0} variant='outlined'>
                <MuiTabs
                  variant='scrollable'
                  scrollButtons='auto'
                  value={activeTab}
                  onChange={($event, value) => handleTabsChange($event, value)}
                  orientation='horizontal'
                  indicatorColor='secondary'>
                  {tabs.map((t, index) => (
                    <Tab label={t.name} value={index} key={t.policy_id} data-cy={t.name} />
                  ))}
                </MuiTabs>
              </MuiPaperTabsContainer>
              {tabs.map((tab, index) => (
                <TreePage
                  activeTab={activeTab}
                  index={index}
                  tabData={tab}
                  key={tab.policy_id}
                  chartView={tab.config.chart_view}
                  onTreeUpdate={(leaves) => onTreeUpdate(tab.policy_name, leaves)}
                  ref={treePageRefs}
                  aggrTreeUpdateEnabled={aggrTreeUpdateEnabled}
                  setAggrTreeUpdateEnabled={setAggrTreeUpdateEnabled}
                  setAllTabRoots={setAllTabRoots}
                ></TreePage>
              ))}
              {tabs.length > 0 &&
                <Drawer
                  activeTab={activeTab}
                  tabs={tabs}
                  scheme={scenario.scheme}
                  onPoliciesSelected={(policiesSelected) => onPoliciesSelected(policiesSelected)}
                  updateAggrTree={() => updateAggrTree(activeTab)}
                  setOpenToast={setOpenToast}
                  setSeverity={setSeverity}
                  setToastMsg={setToastMsg}
                  allTabRoots={allTabRoots}/>
              }
            </Grid>
          </MuiToolbar>
        )}
      </MuiContainer>
    </SnackbarProvider>
  );
};

export default TreeView;
