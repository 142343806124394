// Internal component and function imports
import axios from './axios/axiosInstance';
import apiPaths from './apiPaths';

const {
  upload_collection_dataset: uploadCollectionDatasetPath,
  upload_collection_s3: uploadCollectionS3Path,
  get_import_job: getImportJobPath,
  get_filters: getFiltersPath,
} = apiPaths

const uploadCollection = (data, collection) =>
  axios.post(uploadCollectionDatasetPath, { data, collection });

export const uploadCollectionS3 = (data) =>
  axios.post(uploadCollectionS3Path, data)

export const getImportJob = (id) =>
  axios.get(`${getImportJobPath}?id=${id}`)

export const getFilters = (data) =>
  axios.post(getFiltersPath, data)

export default uploadCollection;
