// External library imports
import * as React from 'react';
import {hierarchy, select, tree, zoom, zoomIdentity, zoomTransform} from 'd3';
import 'd3-transition';
import {ascending} from 'd3-array';
import {useSelector} from 'react-redux';

// Utility imports
import patternify from '../../../../../utils/patternify';
import {getNodeData} from '../../../../../utils/datum';
import {
  mergeBins,
  sumBins,
  transformInitialData
} from '../../../../../utils/array';
import {colors, colorScale} from '../../../../../utils/colors';
import {getColors} from '../../../../../utils/color-codes';
import {diagonal} from '../../../../../utils/chart';
import {getRandomId} from '../../../../../utils/formatter';

// Data and Chart imports
import {
  loadChildren,
  loadPostPolicyData,
  updateAggrTree
} from '../../../../charts/shared/data';
import LeafNode from '../../../../charts/tree/leaf-node';
import LeafNode2d from '../../../../charts/tree/leaf-node-2d';

// Custom Hooks and Contexts
import {useLoading} from '../../../../providers/LoadingProvider';
import {useTranslation} from '../../../../providers/TranslationProvider';

// Style and asset imports
import '../../../../../assets/scss/tree.scss';
import '../../../../../assets/scss/main.scss';

const Tree = ({
                treeConfiguration,
                initialData,
                onNodesUpdate,
                aggrTreeUpdateEnabled,
                setAggrTreeUpdateEnabled,
                setAllTabRoots,
                setEnabledTransition
              }) => {
  const treeRef = React.useRef();
  const svg = React.useRef(null);
  const localNodes = React.useRef([]);
  const root = React.useRef(null);
  const treeData = React.useRef(null);

  let localContainer = null;
  let chart = null;
  let centerGroup = null;
  let localZoomTransform = {x: 0, y: 0, k: 1};
  let width = 500;
  let height = window.innerHeight - 60;
  let duration = 750;
  let maxLevels = treeConfiguration.segmentation_variables.max_depth || 4;
  let chartMargin = {top: 50, left: 0, right: 0, bottom: 0};
  let dimensions = {
    pieInnerRadius: 25,
    pieOuterRadius: 70,
    pieHistPadding: 30,
    histogramWidth: 400,
    histogramHeight: 200,
    nodePadding: 25,
  };
  let currentColors = {linkColor: colors.linkColor};
  let linkGroup = null;
  let labelsGroup = null;
  let nodeGroup = null;
  const regimeSliderDy = 35;
  const {userTrans, lng} = useTranslation();
  const requestPolicies = useSelector((state) => state.app.requestPolicies);
  const policiesRef = React.useRef(requestPolicies)
  const chartWidth = window.innerWidth //width - chartMargin.left - chartMargin.right,
  const {setIsLoading} = useLoading();
  const targetCollection = useSelector(state => state.app.targetCollection)

  const whitespace = React.useMemo(() => {
    return (treeConfiguration.selectionType === '2d' ? 58 : 34) + regimeSliderDy;
  }, [treeConfiguration.selectionType, regimeSliderDy]);

  const localZoom = React.useMemo(() => {
    return zoom().scaleExtent([0.5, 1]).on('zoom', (d) => zoomed(d));
  }, []);

  const treemap = React.useMemo(() => {
    return tree()
      .nodeSize([dimensions.pieOuterRadius * 2 + 50, dimensions.pieOuterRadius * 2 + 20])
      .separation((a, b) => {
        return a.parent === b.parent ? 1 : 1.1;
      });
  }, [dimensions.pieOuterRadius, dimensions.nodePadding]);

  const levelPadding = React.useMemo(() => {
    return treeConfiguration.type === 'normal' ? 125 : 97;
  }, [treeConfiguration.type]);

  const criteriaRange = React.useMemo(() => {
    return treeConfiguration.numericalFilters[treeConfiguration.criteria[0]];
  }, [treeConfiguration.numericalFilters, treeConfiguration.criteria]);

  const chartHeight = React.useMemo(() => {
    return height - chartMargin.top - chartMargin.bottom;
  }, [chartMargin.top, chartMargin.bottom, height]);

  const dy = React.useMemo(() => {
    return (dimensions.nodePadding + dimensions.pieOuterRadius) * 2 +
      (treeConfiguration.type === 'normal' ? 35 : 7) +
      (treeConfiguration.selectionType === '2d' ? 35 : 0) +
      35;
  }, [
    dimensions.pieOuterRadius,
    dimensions.nodePadding,
    treeConfiguration.type,
    treeConfiguration.selectionType,
  ]);

  const zoomed = (event) => {
    // Get d3 event's transform object
    localZoomTransform = event.transform;

    // Reposition and rescale chart accordingly
    chart.attr('transform', localZoomTransform);

    if (window.tooltips) {
      window.tooltips.forEach((t) => t.hide());
    }
  };

  const getRelativyScale = (x) => {
    const m = -0.4;
    const b = 2.0;
    if (x === 1) return (m * x + b) - 0.20;

    if (x < 1 && x >= 0.9) return (m * x + b) - 0.15;


    if (x < 0.9 && x >= 0.8) return (m * x + b);


    if (x < 0.8 && x >= 0.7) return (m * x + b) + 0.25;


    if (x < 0.7 && x >= 0.6) return (m * x + b) + 0.45;


    if (x < 0.6 && x > 0.5) return (m * x + b) + 0.70;


    if (x === 0.5) return (m * x + b) + 0.9;
  };

  const getOrdinateY = (d, pointRef, coordinate) => {
    let point;
    if (d.data.isRoot) {
      point = coordinate.k === 1 ? pointRef + 20 - coordinate.y / coordinate.k
        : pointRef + 30 - (coordinate.y / coordinate.k - 20)
    } else {
      point = pointRef + 40 - coordinate.y / coordinate.k
    }
    return point
  }

  const focusedSegmentation = (node) => {
    let svgWidth = svg.current.attr("width");
    let centerX = svgWidth / 2;
    let relativeDisplacementX = centerX - node.parent?.x + 350
    let relativeDisplacementY = -node.parent?.y + 200

    if (node.parent !== null && node.parent.data.isRoot && node.parent.children[0].x < 52) {
      svg.current
        .call(localZoom.transform, zoomIdentity.scale(0.65).translate(centerX / 2, 0));
    } else if (node.parent !== null && !node.parent.data.isRoot && node.parent.children[0].x < 52) {
      svg.current
        .call(localZoom.transform, zoomIdentity.scale(0.7).translate(relativeDisplacementX, relativeDisplacementY));
    } else if (node.parent !== null && !node.parent.data.isRoot && node.parent.children[node.parent.children.length - 1].x > 1265) {
      if (node.parent !== null && !node.parent.data.isRoot && node.parent.children[0].x > 870 && node.parent.children[0].x < 1240) {
        svg.current
          .call(localZoom.transform, zoomIdentity.scale(0.7).translate(relativeDisplacementX, relativeDisplacementY));
      } else {
        svg.current
          .call(localZoom.transform, zoomIdentity.scale(0.7).translate(relativeDisplacementX, relativeDisplacementY));
      }
    } else if (node.y > height - 31) {
      svg.current.call(localZoom.transform, zoomIdentity.scale(0.7).translate(relativeDisplacementX, relativeDisplacementY));
    } else if (node.data.isRoot) {
      svg.current.call(localZoom.transform, zoomIdentity.scale(0.95));
    }
    return `translate(${node.x}, ${node.y})`;
  }

  const updateTree = (source, triggerUpdate, isCollapse) => {
    // Compute the new tree layout.
    let nodes = treemap(root.current).descendants();
    localNodes.current = nodes;

    // Normalize for fixed-depth.
    nodes.forEach((d) => {
      d.x += chartWidth / 2;
      d.y =
        d.depth *
        (dimensions.pieOuterRadius * 2 +
          levelPadding +
          regimeSliderDy +
          (treeConfiguration.selectionType === '2d' ? 35 : 0));

      let colorCode = getColors(nodes)[d.data.id];

      if (d.data.children.length || colorCode === undefined) {
        d.data.color = colors.acceptedGreen;
        d.data.colorPercent = 0.5;
      } else {
        d.data.color = colorScale(colorCode);
        d.data.colorPercent = colorCode;
      }
    });

    if (isCollapse) {
      let newNode = nodes.filter((d) => d.data.id === source.data.id)[0];
      source.x0 = newNode.x;
      source.y0 = newNode.y;
    }

    let links = nodes.slice(1);

    addNodes(source, nodes);
    addLinks(source, links);

    chart
      .selectAll('g.node')
      .filter((d) => d.data.id === source.data.id)
      .raise();

    labelsGroup.html('');

    setTimeout(() => {
      appendLabels(nodes);
    }, duration);

    if (triggerUpdate) {
      nodes.forEach((d) => {
        const data = d.data;
        const level = d.depth;

        let components = data.leafNode.components;
        let selectionType = treeConfiguration.selectionType;

        if (data.expanded) {
          if (selectionType === '1d') {
            if (components.thresholdSlider) {
              components.thresholdSlider.hide();
            }
          } else {
            components.editBtnX.hide();
            components.editBtnY.hide();
          }

          components.splitBtn.hide();
          components.collapseBtn.show();

          if (components.regimeSlider) {
            components.regimeSlider.hide();
          }
        } else {
          if (selectionType === '1d') {
            if (components.thresholdSlider) {
              components.thresholdSlider.show();
            }
          } else {
            components.editBtnX.show();
            components.editBtnY.show();
          }

          if (data.splitBy.length && level < maxLevels - 1) {
            components.splitBtn.show();
          }

          components.collapseBtn.hide();

          if (components.regimeSlider) {
            components.regimeSlider.show();
          }
        }

        data.leafNode.updateColor(d.data.color);
      });
    }

    setNodes(localNodes.current.filter((d) => d.data.children.length === 0));

    if (source.data.isRoot && source.data.children.length > 0) {
      hideRootSliderGap();
    }
  };

  const getHierarchy = () => hierarchy(treeData.current, (d) => d.children);

  const addLinks = (sourceNode, links) => {
    let linkSource = {
      x: sourceNode.x0,
      y: sourceNode.y0 + dy,
    };

    // Update the links...
    let link = linkGroup.selectAll('path.link').data(links, (d) => d.data.id);

    // Enter any new links at the parent's previous position.
    let linkEnter = link
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', currentColors.linkColor)
      .attr('d', diagonal(linkSource, linkSource));

    // UPDATE
    let linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(duration)
      .attr('d', (d) => {
        return diagonal(
          {
            x: d.x,
            y: d.y - 30,
          },
          {
            x: d.parent.x,
            y: d.parent.y + dy - 2,
          }
        );
      })
      .attr('id', (d) => {
        return `link-${d.data.id}-${d.parent.data.id}`;
      });

    // Remove any exiting links
    link
      .exit()
      .transition()
      .duration(duration)
      .attr('d', diagonal(linkSource, linkSource))
      .remove();
  };

  const addNodes = (source, nodes) => {
    // Update the nodes...
    let node = nodeGroup.selectAll('g.node').data(nodes, (d) => d.data.id);

    // Enter any new modes at the parent's previous position.
    let nodeEnter = node
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('id', (d) => d.data.id)
      .attr('transform', `translate(${source.x0}, ${source.y0}) scale(0.7)`);

    nodeEnter.each(function (d) {
      let container = select(this);
      const criteriaRangeArr = criteriaRange ? [criteriaRange.min, criteriaRange.max] : null;
      const criteria =
        treeConfiguration.selectionType === '1d'
          ? treeConfiguration.criteria[0]
          : treeConfiguration.criteria;

      delete d.data.loading

      d.data.leafNode = (treeConfiguration.selectionType === '2d' ? LeafNode2d : LeafNode)({
        container,
        setEnabledTransition,
        criteriaRange: criteriaRangeArr, // full range
        visualizationRange: treeConfiguration.xExtent || criteriaRangeArr, // visualization range defined on upload dataset
        populationRange: criteriaRangeArr, // filter defined in specifications
        maxDomain: treeConfiguration.yExtent ? treeConfiguration.yExtent[1] : null,
        node: d.data,
        criteria: criteria,
        sections: treeConfiguration.sections,
        statistics: treeConfiguration.statistics,

        parent: d.parent,
        dimensions: dimensions,
        translations: userTrans,
        hideSplitBtn: d.depth >= maxLevels - 1,
        criteriaPriority: treeConfiguration.criteriaPriority,

        treeParams: {
          chartHeight: chartHeight,
          chartWidth: chartWidth,
          margin: chartMargin,
          zoomTransform: localZoomTransform,
        },
        binSize: treeConfiguration.binSize || 1,

        histogramConfig: treeConfiguration.histogramConfig
          ? treeConfiguration.histogramConfig
          : null,
        regimes: treeConfiguration.regimes,
        variables: targetCollection.variables,
        treeType: treeConfiguration.type,
        onHistogramUpdateClick: (threshold, callback) => {
          refreshHistogram(d, Math.ceil(threshold)).then(() => {
            callback();
            setNodes(localNodes.current.filter((x) => x.data.children.length === 0));
          });
        },
      });
    });

    // UPDATE
    let nodeUpdate = nodeEnter.merge(node);

    nodeUpdate.each((d) => {
      if (d.data.leafNode) {
        d.data.leafNode
          .onSplit((selected, value) => {
            handleSplit(d, selected, value);
          })
          .onCollapse(() => {
            handleCollapse(d);
          })
          .onUpdate(() => {
            setNodes(localNodes.current.filter((x) => x.data.children.length === 0));
            setAllTabRoots(prev => ({
              ...prev,
              [treeConfiguration.id]: JSON.parse(JSON.stringify(root.current.data)),
            }))
          })
          .onRegimeSliderUpdate((value) => {
            d.data.amount = value;
            setNodes(localNodes.current.filter((x) => x.data.children.length === 0));
          })
          .onEditViewOpen(({container, nodeHeight, othersOpacity = 0.3}) => {
            const transform = zoomTransform(svg.current.node());
            patternify(localContainer, 'svg', 'svg-chart').on('.zoom', null)
            nodeGroup
              .selectAll('g.node')
              .filter((x) => x !== d)
              .attr('opacity', othersOpacity)
              .attr('pointer-events', 'none');

            labelsGroup.attr('opacity', othersOpacity);
            linkGroup.attr('opacity', othersOpacity);

            container
              .transition()
              .duration(duration)
              .attr('transform', () => {
                const pointRef = (chartHeight - nodeHeight) / 4 - whitespace;
                const x = width / 2;
                const y = getOrdinateY(d, pointRef, transform);
                const scale = getRelativyScale(transform.k)
                const invertedX = transform.invertX(x);
                return `translate(${invertedX}, ${y}) scale(${scale})`;
              });
          })
          .onEditViewClose(({container}) => {
            patternify(localContainer, 'svg', 'svg-chart').call(localZoom);
            nodeGroup.selectAll('g.node').attr('opacity', null).attr('pointer-events', null);

            labelsGroup.attr('opacity', null);
            linkGroup.attr('opacity', null);

            container
              .transition()
              .duration(duration)
              .attr('transform', (x) => `translate(${x.x}, ${x.y}) scale(1)`);
          });
      }
    });

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(duration)
      .attr('transform', d => focusedSegmentation(d));

    // Remove any exiting nodes
    node
      .exit()
      .transition()
      .duration(duration)
      .attr('transform', function () {
        return `translate(${source.x0}, ${source.y0})`;
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  };

  const appendLabels = (nodes) => {
    nodes.forEach((node) => {
      if (node.data.expanded) {
        let sx = node.x;
        let sy = node.y + dy - 8;
        let variable = targetCollection.variables.find(variable => variable.propName === node.data.selected)
        let selected = variable.label || variable.propName;

        labelsGroup
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('font-weight', 'bold')
          .attr('x', sx)
          .attr('y', sy)
          .text(selected);

        node.children.forEach((c) => {
          let x = c.x;
          let y = c.y - 15;

          labelsGroup
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('x', x)
            .attr('y', y)
            .text(c.data.name)
            .attr('fill', '#666666')
            .attr('font-size', '15px');
        });
      }
    });
  };

  const setNodes = (nodes) => {
    const newNodes = nodes.slice().sort((a, b) => {
      if (a.depth < b.depth && b.parent) {
        return a.x - b.parent.x;
      } else if (a.depth > b.depth && a.parent) {
        return a.parent.x - b.x;
      }
      return a.x - b.x;
    });
    onNodesUpdate(newNodes);
  };

  const hideRootSliderGap = () => {
    if (treeConfiguration.type !== 'normal') return;

    svg.current
      .select('.root-title')
      .transition()
      .duration(750)
      .attr('y', whitespace - 18);

    centerGroup
      .transition()
      .duration(750)
      .attr('transform', `translate(${chartMargin.left}, ${chartMargin.top - whitespace})`);
  };

  const refreshHistogram = async (node, threshold) => {
    if (treeConfiguration.selectionType !== '1d') {
      return;
    }

    try {
      setIsLoading(true)
      const data = await loadPostPolicyData(node, threshold, treeConfiguration, requestPolicies);
      setIsLoading(false)

      node.data.bins = mergeBins(node.data.__original_data, data, criteriaRange);
      node.data.leafNode.updateHistogram(node.data.bins);
    } catch (error) {
      setIsLoading(false)
    }
  };

  const handleSplit = async (node, selected, value) => {
    const group_id = selected;
    const data = await localLoadChildren(group_id, node, value);

    if (data) {
      const children = data.map((d) => {
        return getNodeData({
          bins: d.bins,
          original_data: d.original_data,
          name: d.key,
          nodeKey: d.key,
          value: value,
          splitBy: node.data.splitBy.filter((d) => d !== selected),
          parent: node,
          regime: node.data.regime,
          nodeSegCustomType: d.nodeSegCustomType,
        });
      });

      // update parent's children property
      node.data['children'] = children.sort((a, b) => ascending(a.name, b.name));

      node.data.expanded = true;
      node.data.selected = selected;

      // recompute root hierarchy
      root.current = getHierarchy();

      updateTree(node, true, false);
      setAllTabRoots(prev => ({
        ...prev,
        [treeConfiguration.id]: JSON.parse(JSON.stringify(root.current.data)),
      }))
    }
  };

  const localLoadChildren = async (group_id, node, value) => {
    const match = treeConfiguration.match.in_dataset.find((d) => {
      return Object.keys(d).indexOf(group_id) > -1;
    });

    // get the categorical filters
    let groupCategories;

    if (match) {
      groupCategories = match[group_id];
    } else {
      groupCategories = treeConfiguration.categoricalFilters[group_id];
    }
    let data;

    try {
      setIsLoading(true)
      data = await loadChildren(
        group_id,
        node,
        value,
        treeConfiguration,
        policiesRef.current,
        groupCategories
      );
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }

    return data;
  };

  const handleCollapse = (node) => {
    const newData = sumBins(node.data.children.map((d) => d.bins));

    node.data.children = [];
    node.data.expanded = false;

    // recompute root hierarchy
    root.current = getHierarchy();

    updateTree(node, true, true);

    setAllTabRoots(prev => ({
      ...prev,
      [treeConfiguration.id]: JSON.parse(JSON.stringify(root.current.data)),
    }))

    if (node.data.isRoot) {
      showRootSliderGap();
    }

    if (treeConfiguration.type === 'aggregation') {
      node.data.bins = newData;
      node.data.leafNode.updateHistogram(newData);
    } else {
      refreshHistogram(node, node.data.value);
    }
  };

  const showRootSliderGap = () => {
    if (treeConfiguration.type !== 'normal') return;

    svg.current.select('.root-title').transition().duration(750).attr('y', -18);

    centerGroup
      .transition()
      .duration(750)
      .attr('transform', `translate(${chartMargin.left}, ${chartMargin.top})`);
  };

  const setWidth = () => {
    // 223 is cost bar width
    // 48 is sidebar width
    width = window.innerWidth - 223 - 48;
  };

  const setHeight = () => height = window.innerHeight - 110;

  const drawSvg = () => {
    return patternify(localContainer, 'svg', 'svg-chart')
      .attr('width', width)
      .attr('height', height)
      .style('cursor', 'grab')
      .style('font-family', 'sans-serif')
      .call(localZoom)
      .on('dblclick.zoom', null);
  };

  const drawChart = () => {
    chart = patternify(svg.current, 'g', 'chart').html('');

    centerGroup = patternify(chart, 'g', 'center-group').attr(
      'transform',
      `translate(${chartMargin.left}, ${chartMargin.top})`
    );

    linkGroup = centerGroup.append('g').attr('class', 'link-group');

    labelsGroup = centerGroup.append('g').attr('class', 'labels-group');

    nodeGroup = centerGroup.append('g').attr('class', 'node-group');

    root.current = getHierarchy();
    root.current.x0 = chartWidth / 2;
    root.current.y0 = 0;
    updateTree(root.current);
    setAllTabRoots(prev => ({
      ...prev,
      [treeConfiguration.id]: JSON.parse(JSON.stringify(root.current.data)),
    }))
  };

  const getChildNodes = () =>
    localNodes.current.filter(d => d.data.children.length === 0)

  const updateAllChildNodes = () => {
    const children = getChildNodes();

    setIsLoading(true)

    if (children.length === 1 && children[0].depth === 0) {
      return refreshHistogram(children[0], children[0].data.value).then(() => {
        setNodes(children);
      });
    }

    return updateAggrTree(children, treeConfiguration, requestPolicies).then(resp => {
      const map = new Map(resp.map(d => [d.nodeId, d.data]));
      children.forEach(node => {
        node.data.loading = false;
        const newData = map.get(node.data.id);

        if (newData) {
          node.data.bins = newData;
          node.data.leafNode.updateHistogram(newData);
        }
      });
      setNodes(children);
      setIsLoading(false)
      return true;
    });
  }

  React.useEffect(() => {
    if (aggrTreeUpdateEnabled && treeConfiguration.type === 'aggregation') {
      setAggrTreeUpdateEnabled(false)
      updateAllChildNodes()
    }
  }, [aggrTreeUpdateEnabled])

  React.useEffect(() => {
    policiesRef.current = requestPolicies;
  }, [requestPolicies]);

  React.useEffect(() => {
    async function initializeTree() {
      const divElement = treeRef.current;
      setWidth();
      setHeight();
      treeData.current = (treeConfiguration.tree_state && Object.keys(treeConfiguration.tree_state).length > 0) ? treeConfiguration.tree_state :
        getNodeData({
          bins: transformInitialData(initialData),
          original_data: initialData,
          name: userTrans.root_title,
          value: treeConfiguration.defaultThresholdValue,
          splitBy: treeConfiguration.segmentation_variables.in_dataset,
          parent: null,
          nodeKey: treeConfiguration.criteria[0],
          regime: treeConfiguration.regimes[0],
        });

      if (divElement) {
        localContainer = select(divElement);
        svg.current = drawSvg();
        drawChart();

        select(window).on(`resize.${getRandomId()}`, () => {
          setWidth();
          setHeight();
          drawSvg();
        });
      }
    }

    initializeTree()
    return () => {
      treeRef.current = null;
      localNodes.current = null;
      svg.current = null;
      treeData.current = null;
      root.current = null;
    };
  }, []);

  React.useEffect(() => {
    drawChart()
  }, [lng])

  return (
    <div ref={treeRef} className='tree'>
    </div>
  );
};
export default Tree;
