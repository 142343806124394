// External library imports
import { useQuery } from 'react-query';

// API and Utility imports
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import { status200 } from '../status.utils';


const fetchCollection = ({ user_id, collection }) =>
  axios.post(apiPaths.get_collection, { user_id, collection }, status200)
       .then((resp) => resp.data);

const useCollection = (data) =>
  useQuery(['collection', data.collection], () => fetchCollection(data), {
    enabled: data.enabled,
    refetchOnWindowFocus: false,
  });

export default useCollection;
