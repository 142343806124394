// External library imports
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import * as _ from 'lodash';

// Internal component and function imports
import StepForm from '../../stepper/StepForm';
import useScheme from '../../../../../api/hooks/useScheme';
import { useAuth } from '../../../../providers/AuthProvider';
import {setTargetCollection, setTargetScheme} from '../../../../../store/appSlice';
import useSchemes from '../../../../../api/hooks/useSchemes';
import Loader from '../../../../common/Loader';

const Specification = () => {
  const [isLoading, setIsLoading] = React.useState()

  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const orgId = location.pathname.split('/')[2] === 'workspace' ?
    user.id : location.pathname.split('/')[2];
  const isWorkspace = location.pathname.split('/')[2] === 'workspace'
  const scenario = useScheme({
    user_id: orgId,
    scheme_id: location.pathname.split('/')[4],
    enabled: location.pathname.split('/')[4] !== 'create'
  });
  const schemes = useSchemes(orgId)

  React.useEffect(() => {
    if (scenario.data) {
      let scheme = _.cloneDeep(scenario.data.scheme);
      scheme.policies.forEach((d) => {
        if(d.cost_bar_title === undefined) d.cost_bar_title = ''
      });
      dispatch(setTargetCollection(scenario.data.collection))
      dispatch(setTargetScheme(scheme))
    }
  }, [scenario.data])

  React.useEffect(() => {
    setIsLoading(true)
    if (location.pathname.split('/')[4] === 'create') {
      setIsLoading(false)
    }
  }, [])

  React.useEffect(() => {
    if (scenario.data.scheme && scenario.data.scheme.dataset && scenario.data.scheme.dataset !== '') {
      setIsLoading(false)
    }
  }, [scenario.data.scheme, scenario.data.scheme.dataset])

  React.useEffect(() => {
    if (location.pathname.split('/')[4] === 'create') {
      setIsLoading(schemes.isLoading)
    }
  }, [schemes.isLoading])

  return (
    <SnackbarProvider maxSnack={10}>
      {(scenario.data && schemes.data && !isLoading)
        ? <StepForm scenario={scenario.data}
            schemes={schemes.data}
            orgId={orgId}
            isWorkspace={isWorkspace}/>
        : <Loader/>
      }
    </SnackbarProvider>
  );
};

export default Specification;
