// Hooks and Contexts
import { useAuth } from '../providers/AuthProvider';

export const usePermissions = () => {
  const { user } = useAuth();

  const hasPermissionFor = (permission) => Boolean(user.permissions[permission]);

  return { hasPermissionFor };
};
