// External library imports
import { useMutation, useQueryClient } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const copyScheme = (data) => {
  return axios.post(apiPaths.copy_scheme, data, status200)
              .then((resp) => resp.data);
};

const useCopyScheme = () => {
  const queryClient = useQueryClient();

  return useMutation((values) => copyScheme(values), {
    onSuccess: () => queryClient.resetQueries('schemes'),
    onError: (error) => error,
    onSettled: () => queryClient.invalidateQueries('schemes')
  });
};
export default useCopyScheme;
