// External library imports
import * as React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';

// Internal component and function imports
import {useTranslation} from 'components/providers/TranslationProvider';
import {useAuth} from 'components/providers/AuthProvider';
import Button from 'components/common/Button';
import Toast from 'components/common/Toast';
import GoogleBrand from 'assets/img/google.png';
import MicrosoftBrand from 'assets/img/microsoft.png';

// Style and asset imports
import {
  buttonSocial,
  buttonStyle,
  circularProgressStyle, inputAdornmentStyle,
  langContainer,
  MuiButtonColored,
  MuiDivPaper,
  MuiForm,
  MuiGoogleButton,
  MuiGridContainerButtons,
  MuiTextField,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon,
  spacingStyle,
  styleTitle,
  subTitleStyle,
  textOR
} from './styles/login';
import {MuiTypography} from "../../common/styles/global";
import {MdLockOutline, MdOutlinePerson} from "react-icons/md";
import LanguageSelector from "../../common/LanguageSelector";

const schema = yup.object().shape({
  email: yup.string().email('email_required').required('required'),
  password: yup.string().min(4, 'min_4_characters').required('required'),
});

const Login = () => {
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [redirectURL, setRedirectURL] = React.useState('/orgs');
  const [showPassword, setShowPassword] = React.useState(false);

  const { t } = useTranslation();
  const { login, isPending, loginWithSocial, openDialog, setOpenDialog } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { handleSubmit, formState: { errors, isValid }} = methods;

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleCloseToast = () => setOpenToast(false);

  const handleError = (e) => {
    setError(t(e));
    setOpenToast(true);
  };

  const onSubmit = (data) => login(data, redirectURL, handleError);

  const loginSocial = (provider) => loginWithSocial(provider, redirectURL, handleError);

  const handleClose = () => setOpenDialog(false);

  const GoogleIcon = () => {
    return (
      <img src={GoogleBrand} alt="Google"/>
    );
  }

  const MicrosoftIcon=() => {
    return (
      <img src={MicrosoftBrand} alt="Microsoft"/>
    );
  }

  React.useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath !== '/login') {
      setRedirectURL(currentPath);
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <MuiDivPaper >
      <Grid item sx={langContainer}>
        <LanguageSelector />
      </Grid>
      <MuiTypography sx={styleTitle}>
        Criter<span style={{fontWeight:300}}>ia</span>
      </MuiTypography>
      <Typography variant="h6" sx={subTitleStyle}>
        {t('login_subtitle')}
      </Typography>
      <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />

      <Grid container justifyContent="center" sx={{marginTop: '25px'}}>
        <Grid item >
          <MuiGoogleButton
            variant='outlined'
            color='primary'
            size='large'
            disabled={isPending}
            startIcon={<GoogleIcon />}
            onClick={() => loginSocial('google')}
          >
            {isPending ? (
              <CircularProgress style={circularProgressStyle} size={26} />
            ) : (
              <Typography sx={buttonSocial}>{t('login_google')}</Typography>
            )}
          </MuiGoogleButton>
        </Grid>
        <Grid item>
          <MuiGoogleButton
          variant='outlined'
          color='primary'
          size='large'
          disabled={isPending}
          startIcon={<MicrosoftIcon/>}
          onClick={() => loginSocial('microsoft')}
        >
          {isPending ? (
            <CircularProgress style={circularProgressStyle} size={26} />
          ) : (
            <Typography sx={buttonSocial}>{t('login_microsoft')}</Typography>
          )}
        </MuiGoogleButton></Grid>


      <Grid item >
        <MuiTypography sx={textOR}>{t('login_or')}</MuiTypography>
      </Grid>

      <FormProvider {...methods}>
        <MuiForm onSubmit={handleSubmit(onSubmit)} style={{width: '312px'}}>
          <Grid container>
            <Grid item>
              <MuiTextField
                variant='outlined'
                size="small"
                fullWidth
                label={t('email')}
                placeholder="email@example.com"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdOutlinePerson style={inputAdornmentStyle}/>
                    </InputAdornment>
                  ),
                }}
                name='email'
                autoComplete='email'
                error={t(errors.email?.message)}
              />
            </Grid>
            <Grid item style={{ marginBottom: '28px', marginTop:'-5px'}}>
                <MuiTextField
                  variant='outlined'
                  size="small"
                  fullWidth
                  name='password'
                  placeholder="************"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdLockOutline style={inputAdornmentStyle}/>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{color: '#6793A5'}}
                          onClick={handleClickShowPassword}>
                          {showPassword ? <MuiVisibilityOffIcon/> : <MuiVisibilityIcon/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  error={t(errors.password?.message)}
                  label={t('password')}
                />
            </Grid>
          </Grid>
          <MuiGridContainerButtons container sx={spacingStyle}>
            <Grid item>
              <Button
                sx={buttonStyle}
                variant="text"
                component={NavLink}
                to='/forgot-password'
              >
                {t('forgot_password')}
              </Button>
            </Grid>
            <MuiButtonColored
              loading={isPending}
              type='submit'
              data-cy='login'
            >
              {isPending ? t('login...') : t('login')}
            </MuiButtonColored>
          </MuiGridContainerButtons>
        </MuiForm>
        {/*<Typography style={{fontFamily:'Poppins', fontSize: '10px', width:'312px'}}>Al continuar aceptas las <strong>Condiciones de servicio</strong> de CRITERIA y reconoces que leíste nuestra <strong>Política de Privacidad</strong></Typography>*/}
      </FormProvider>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('login_user_not_allowed')
              ? t('login_user_not_allowed')
              : 'The user is not register on the platform. Please contact support'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </MuiDivPaper>
  );
};
export default Login;
