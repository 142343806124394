import {Button, styled} from "@mui/material";

export const MuiButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height: '32px'
}));
