// External library imports
import { nest } from 'd3-collection';

// Internal component and function imports
import axios from '../../../api/axios/axiosInstance';
import { getMatch, getMatchArray } from '../../../utils/chart';
import { mergeBins } from '../../../utils/array';

/**
 * Generates base request data that is used in both, pre and post requests
 * @param {Object} param0 group_id, node, config and categories
 */

const DEFAULT_PRIORITIZATION = 'ingreso del hogar';

const getBaseRequestData = ({group_id, node, config, groupCategories}) => {
  // generate match by drill up to parents and find subsets
  const match = node ? getMatch(node, config) : {};

  if (config.match && config.match.in_dataset) {
    // add in_dataset match defined in specifications
    config.match.in_dataset.forEach((o) => {
      const type = o.type;

      Object.keys(o)
        .filter((d) => d !== 'type')
        .forEach((k) => {
          if (type === 'categorical') {
            match[k] = {$in: o[k]};
          } else {
            const [min, max] = o[k];
            match[k] = {
              $gte: min,
              $lte: max,
            };
          }
        });
    });
  }

  // generate match query
  let baseRequest = {
    collection: config.collection,
    match: match,
    weight: config.weight,
    report_vars: config.report_vars,
  };

  // group_id needed only for segmentation
  if (group_id && groupCategories) {
    baseRequest.leaves = groupCategories.map((d) => {
      let group = {[group_id]: d, type: 'variable'};
      if (d instanceof Object) {
        group = {
          [d.name]: d.labelTrue,
          complement: d.labelFalse,
          type: 'formula',
          formula: d.value,
        };
      }
      return group;
    });
  }

  baseRequest.bin_size = config.binSize;

  return baseRequest;
};

/**
 * Generates request data for pre policy
 * @param {Object} param0 group_id, node, config and categories
 */
const getPreRequestData = ({group_id, node, config, groupCategories}) => {
  const base = getBaseRequestData({
    group_id,
    node,
    config,
    groupCategories,
  });

  return {
    ...base,
    // add some additional parameters here
  };
};

/**
 * Generates request data for post policy
 * @param {Object} param0 group_id, node, config, categories, policies, threshold
 */
const getPostRequestData = ({
                              group_id,
                              node,
                              config,
                              groupCategories,
                              policies,
                              threshold
                            }) => {
  const base = getBaseRequestData({
    group_id,
    node,
    config,
    groupCategories,
  });

  if (config.type === 'normal') {
    // <all affected variables. Formulas are constructed from the effects and regimes fields, according to the
    // regime selected.
    // * If effect.type == factor, then the default formula is "<factor_name>" * $amount$ .
    // * If effect.type == formula, then use the string formula directly

    const regime = node.data.regime;

    const affected_variables = regime.effects
      .map((d) => {
        const effect = d.avEffect;
        return {
          name: d.variable,
          formula: effect.type === 'factor' ? `"${effect.value}" * $amount$` : effect.value,
        };
      })
      .filter((d) => d);

    let elegibility = `"${config.criteria[0]}" ${
      config.criteriaPriority === 'low' ? '<=' : '>='
    } $threshold$`;
    if (
      config.prioritization[0].formula &&
      config.prioritization[0].formula.length > 0 &&
      config.prioritization[0].name === config.criteria[0]
    ) {
      elegibility = `${config.prioritization[0].formula} ${
        config.criteriaPriority === 'low' ? '<=' : '>='
      } $threshold$`;
    }

    return {
      ...base,
      policies: [
        {
          id: 'pol',
          name: 'pol',
          elegibility,
          leaves: [
            {
              subset: [],
              params: {
                amount: node.data.amount,
                threshold,
              },
              affected_variables,
            },
          ],
        },
      ],
    };
  } else {
    return {
      ...base,
      policies,
    };
  }
};

/**
 * Loads children of a node. Used when splitting.
 * @param {String} group_id segmentation variable
 * @param {Object} node tree node
 * @param {Number} threshold threshold value of node
 * @param {Object} config tree configuration parameters
 * @param {Array} policies other single policy trees. set only when aggregation tree
 * @param {Array} groupCategories group categories of segmentation variable
 */
export const loadChildren = (group_id, node, threshold, config, policies, groupCategories) => {
  let criteriaRange = config.numericalFilters[config.criteria[0]];

  if (!criteriaRange) {
    criteriaRange = config.numericalFilters[DEFAULT_PRIORITIZATION];
  }

  let groupCategoriesCustom = null;

  if (group_id.endsWith('_custom')) {
    let key = group_id.replace(/_custom$/gi, '');
    groupCategoriesCustom = config.segmentation_variables.dataset_custom[key];
  }

  if (!groupCategories) {
    groupCategories = [groupCategoriesCustom];
  }

  const prePolReq = getPreRequestData({
    group_id,
    node,
    config,
    groupCategories,
  });

  const requests = [prePolReq];

  const postPolReq = getPostRequestData({
    group_id,
    node,
    config,
    groupCategories,
    policies,
    threshold,
  });

  requests.push(postPolReq);

  return Promise.all(
    requests.map((req) => {
      return axios.post(config.binsEndpoint, req);
    })
  ).then((resp) => {
    let pre = {};
    let post = {};
    let result = [];

    if (resp[0]) {
      const res = nest()
        .key((d) => d.subgroup)
        .entries(resp[0].data);
      res.forEach((d) => (pre[d.key] = d.values));
    }

    if (resp[1]) {
      const res = nest()
        .key((d) => d.subgroup)
        .entries(resp[1].data);
      res.forEach((d) => (post[d.key] = d.values));
    }

    let groupCustom = {};
    if (group_id.endsWith('_custom')) {
      group_id = group_id.replace(/_custom$/gi, '');
      for (let i = 0; i < groupCategories.length; i++) {
        groupCustom[groupCategories[i].labelTrue] = true;
        groupCustom[groupCategories[i].labelFalse] = false;
      }
      groupCategories = Object.keys(groupCustom);
    }

    for (let i = 0; i < groupCategories.length; i++) {
      let cat = groupCategories[i];
      let key = `${group_id}_${cat}`;
      let nodeSegCustomType = groupCustom ? groupCustom[cat] : null;

      if (pre[key] && post[key]) {
        result.push({
          key: cat,
          bins: mergeBins(pre[key], post[key], criteriaRange),
          original_data: pre[key], // important, need to be here
          nodeSegCustomType,
        });
      } else if (pre[key]) {
        result.push({
          key: cat,
          bins: pre[key],
          original_data: pre[key],
          nodeSegCustomType,
        });
      }
    }

    if (!result.length) {
      throw new Error('No Data');
    }

    return result;
  });
}

/**
 * Updates all leaf nodes of aggregation tree
 * @param {Array} nodes leaf nodes
 * @param {Object} config tree configuration parameters
 * @param {Array} policies other single policy trees. set only when aggregation tree
 */
export const updateAggrTree = (nodes, config, policies) => {
  const criteriaRange = config.numericalFilters[config.criteria[0]];

  const request_data = getBaseRequestData({config});

  request_data.policies = policies;
  request_data.leaves = [];

  const pre_data = [];

  if (nodes) {
    nodes.forEach((node) => {
      const matchArr = getMatchArray(node);

      if (matchArr) {
        if (!request_data.leaves) {
          request_data.leaves = [];
        }
        request_data.leaves = request_data.leaves.concat(matchArr);

        let group_name = '';

        group_name += matchArr
          .map((x) => {
            return Object.keys(x)
              .filter((k) => k !== 'type')
              .map((k) => [k, x[k]].join('_'));
          })
          .join('_');

        pre_data.push({
          nodeId: node.data.id,
          group_name,
          data: node.data.__original_data,
        });
      }
    });
  }

  return axios.post(config.binsEndpoint, request_data).then(({data}) => {
    const grouped = nest()
      .key((d) => d.subgroup)
      .entries(data);
    const map = new Map(grouped.map((d) => [d.key, d.values]));

    return pre_data.map((d) => {
      let mergedData = [];
      const values = map.get(d.group_name);

      if (values) {
        mergedData = mergeBins(d.data, values, criteriaRange);
      }

      return {
        nodeId: d.nodeId,
        data: mergedData,
      };
    });
  });
}

/**
 * Loads post policy data. Used when refreshing histogram.
 * @param {Object} node tree node
 * @param {Number} threshold threshold value of node
 * @param {Object} config tree configuration parameters
 * @param {Array} requestPolicies policies in case of aggregation tree
 */
export const loadPostPolicyData = (node, threshold, config, requestPolicies) => {
  const postPolReq = getPostRequestData({
    node,
    config,
    policies: requestPolicies,
    threshold,
  });
  return axios.post(config.binsEndpoint, postPolReq).then((resp) => resp.data);
}

/**
 * Loads total population bins.
 * Used to load initial data when tree is rendered first time.
 * @param {Object} config tree configuration parameters
 */
export const loadTotalPopulationBins = (config) => {
  const base = getBaseRequestData({config});

  return axios.post(config.binsEndpoint, base).then((resp) => {
    return resp.data.map((d) => {
      return {
        ...d,
        current: d.sum,
      };
    });
  });
}

export const addMissingBins = (data) => {
  let newObject = {}

  for (const key in data[0]) {
    newObject[key] = 0
  }

  for (let i = 0; i <= 100; i++) {
    if (i !== 1) {
      const existCriteria = data.find(d => d.criteria === i)

      if (!existCriteria) {
        newObject.criteria = i
        data.push({...newObject})
      }
    }
  }

  data.sort((a, b) => a.criteria - b.criteria)

  return [...data];
}
