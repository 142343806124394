// External library imports
import {Button, Grid, styled} from "@mui/material";

export const MuiGridHeaderContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  marginTop: 56,
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginTop: 20,
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '32px',
  width: '100%',

  [theme.breakpoints.only('xl')]: {
    columnGap: '15px',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  [theme.breakpoints.only('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [theme.breakpoints.only('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.only('xs')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

export const containerStyle = {
  maxWidth: '1500px !important',
  width: '90% !important',
  '@media (min-width: 1920px)': {
    maxWidth: '1650px !important',
  },
}

export const toolbarStyle = {
  paddingLeft:'0px !important'
}
export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius:'20px',
  padding:'10px 24px 10px 16px',
  height: '38px'
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignContent: 'center',
}));
