import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchSchemes = ({ user_id, id }) => {
  return axios
    .post(apiPaths.schemes_associated, { user_id: user_id, collection_id: id }, status200)
    .then((resp) => resp.data);
}

const useSchemesAssociatedDataset = (data) =>
  useQuery(['collection', data.id], () => fetchSchemes(data), {
    refetchOnWindowFocus: false,
  });

export default useSchemesAssociatedDataset;
