import '../assets/scss/tree.scss';
import { getVariableLabel } from './utils';

const convertStylesToString = (styles) => {
  return Object.keys(styles)
    .map(key => {
      const formattedKey = key.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);
      return `${formattedKey}: ${styles[key]}`;
    })
    .join('; ');
}

export const getTooltipHtml = (
  { options, nodeId, headerText, btnText, btnClass, selectedIndex, variables },
  callback
) => {

  const colorLine = localStorage.getItem('colorSecondary')

  const strongStyles = {
    borderBottom: '2px solid',
    borderBottomColor: colorLine,
    textAlign: 'center'
  };
  const divStyles = {
    textAlign: 'center',
  };

  const splitStyles = {
    scrollableDiv: 'max-height: 250px; overflow-y: auto;',
    textLeft: 'text-align: left;',
    margiTop: 'margin-top: 8px;',
  };

  const headerTextStyles = convertStylesToString(strongStyles);

  const divHeaderStyles = convertStylesToString(divStyles);

  const segmentationWithScroll = options.length > 20
    ? `${splitStyles.scrollableDiv} ${splitStyles.textLeft} ${splitStyles.margiTop}`
    : `${splitStyles.textLeft} ${splitStyles.margiTop}`;

  let tooltip = document.createElement('div');
  tooltip.id = `form-${nodeId}`;
  tooltip.innerHTML = `
      <div class='pop-up'>
          <div class="mb-2" style="${divHeaderStyles}"><strong style="${headerTextStyles}">${headerText}</strong></div>
          <div style="${segmentationWithScroll}">
              ${options
                .map((d, i) => {
                  return `
                      <div class="form-check">
                          <input class="form-check-input" type="radio" value="${d}" ${
                    i === selectedIndex ? 'checked' : ''
                  } name="checkboxes" />

                          <label class="form-check-label">
                              ${variables ? getVariableLabel(variables, d) : d}
                          </label>
                      </div>`;
                })
                .join('')}
          </div>
      </div>
  `;

  let button = document.createElement('button');
  button.innerHTML = btnText;
  button.setAttribute('class', 'mt-2 w-100 btn btn-sm btn-split ' + btnClass);
  tooltip.appendChild(button);

  button.addEventListener('click', function () {
    let splitFormId = `#form-${nodeId}`;
    let selected = document.querySelector(splitFormId + ' input[name="checkboxes"]:checked').value;
    callback(selected);
  });

  return tooltip;
};
