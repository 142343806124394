// External library imports
import React from 'react';
import {Button, FormControl, Grid, MenuItem, TextField} from '@mui/material';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';

// Internal component and function imports
import {setTargetCollection,} from '../../../../../store/appSlice';
import useCollection from '../../../../../api/hooks/useCollection';
import {useTranslation} from '../../../../providers/TranslationProvider';
import {getRandomId} from '../../../../../utils/formatter';
import formulaValidator from './../../../../../api/formulaValidator'

const FirstStep = ({
  scheme,
  setScheme,
  collections,
  weightFields,
  isCreating,
  orgId,
  handleExport,
}) => {
  const [ isImporting, setIsImporting ] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const {data: collection, isLoading} = useCollection({
    user_id: orgId,
    collection: scheme.dataset,
    enabled: scheme.dataset !== '' && isCreating,
  })
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleChange = (event) => {
    setScheme(previousScheme => ({
      ...previousScheme,
      [event.target.name]: event.target.value,
    }))
  };

  const handleKeyDown = (event) => {
    const { value } = event.target;
    if (value.length >= 80 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const errorMsg = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'error',
      preventDuplicate: true,
    })
  }

  const validatePrioritization = (prioritization) => {
    const ExistsNumericalFilter = collection.variables.find((v) => v.propName === prioritization.name && v.category === 'numeric')
    return prioritization !== undefined && prioritization.name !== undefined && prioritization.name.length > 0 && ExistsNumericalFilter !== undefined;
  }

  const hasErrorRegime = (regime) => {
    return regime.some(x => {
      return (x.regime_label === undefined || x.regime_label.length === 0 ||
        x.slider_definition === undefined || !(parseInt(x.slider_definition.default) >= 0) ||
        !(parseInt(x.slider_definition.min) >= 0) || !(parseInt(x.slider_definition.max) >= 0) ||
        x.slider_definition.symbol === undefined || x.slider_definition.symbol.length === 0 ||
        x.slider_definition.slider_label === undefined || x.slider_definition.slider_label.length === 0 ||
        x.effects.length === 0 || x.effects.some(y => {
          return (y.effect === undefined || y.effect.length === 0 ||
            y.variable === undefined || y.variable.length === 0)
        }))
    });
  }

  const hasErrorStatistic = (statistic) => {
    return statistic.some(x => x.label === undefined || x.label.length === 0
      || x.section === undefined || x.stat_var === undefined
      || x.stat_var.length === 0 || x.statistic === undefined
      || x.statistic.length === 0
    )
  }

  const hasErrorAffectedVariable = (affected) => {
    return affected.some(x => {
      return (x.name === undefined || x.name.length === 0 || x.effects.length === 0 ||
        x.effects.some(y => {
          return (y.label === undefined || y.label.length === 0 || y.type === undefined ||
            y.type.length === 0 || y.value === undefined || y.value.length === 0)
        }))
    })
  }

  const handleImportScenario = async (ev) => {
    const fr = new FileReader();
    fr.onload = async e => {
      try {
        setIsImporting(true)
        const schemeData = JSON.parse(e.target.result)
        schemeData['id'] = getRandomId()
        schemeData['dataset'] = scheme.dataset
        let sections = collection.sections
        let errors = []

        if (scheme.weight === '') {
          errors.push("Choose weight variable.")
        }

        // validate policies
        schemeData.policies.forEach((x) => {
          x.id = getRandomId()

          if (x.prioritization.length === 0 || validatePrioritization(x.prioritization[0]) === false) {
            errors.push('You need to provide existing prioritization variable for policy ' + x.name)
          }

          if ((x.regimes && x.regimes.length === 0) || hasErrorRegime(x.regimes)) {
            errors.push('You need at least one regime and specified the required fields')
          }

          if ((x.statistics && x.statistics.length === 0) || hasErrorStatistic(x.statistics)) {
            errors.push('You need at least one statistic and specified the required fields')
          }

          if ((x.affected_variables && x.affected_variables.length === 0)
            || hasErrorAffectedVariable(x.affected_variables)
          ) {
            errors.push('You need at least one affected variable and specified the required fields')
          }

          if (x.name === '') {
            errors.push('You need fill the name of the policy')
          }
        });

        if (schemeData.policies.length === 0) {
          errors.push("You need at least one policy")
        }

        let notExists = false
        let statisticName = '';
        for (const policie of schemeData.policies) {
          let statistics = policie.statistics

          for (const statistic of statistics) {
            // eslint-disable-next-line
            let sectionExists = sections.find(section => {
              for (const s of section.sections) {
                if (statistic.section === s.value || statistic.section <= -1) {
                  return section;
                }
              }
            })

            if (!sectionExists) {
              statisticName = statistic.label
              notExists = true
              break
            }
          }

          if (notExists) {
            break
          }
        }

        let aggregation = schemeData['aggregation']
        let notExistsInAgg = false
        for (const statistic of aggregation.statistics) {
          // eslint-disable-next-line
          let sectionExists = sections.find(section => {
            for (const s of section.sections) {
              if (statistic.section === s.value || statistic.section <= -1) {
                return section;
              }
            }
          })

          if (!sectionExists) {
            statisticName = statistic.label
            notExistsInAgg = true
            break
          }
        }

        if (notExists) {
          errors.push(statisticName + ' statistic has section value that not exists in dataset')
        }

        if (notExistsInAgg) {
          errors.push(statisticName + ' statistic has section value that not exists in dataset in aggregation')
        }

        for (const pol of schemeData.policies) {
          if (pol.affected_variables.length > 0) {
            let formulas = []
            for (const affectedVariable of pol.affected_variables) {
              for (const effect of affectedVariable.effects) {
                if (effect.type === 'formula') {
                  formulas.push(effect.value)
                }
              }
            }

            if (formulas.length) {
              const result = await formulaValidator(formulas, scheme.dataset)

              if (result.data && result.data.invalids && result.data.invalids.length > 0) {
                for (const invalid of result.data.invalids) {
                  errors.push(`Affected Variable - ${invalid.msg}(${invalid.expression})`)
                }
              }
            }
          }

          if (pol.segmentation_variables && pol.segmentation_variables.dataset_custom) {
            let segmentationFormulas = []

            for (const segCustom of Object.values(pol.segmentation_variables.dataset_custom)) {
              segmentationFormulas.push(segCustom.value)
            }

            if (segmentationFormulas.length > 0) {
              const result = await formulaValidator(segmentationFormulas, scheme.dataset)

              if (result.data && result.data.invalids && result.data.invalids.length > 0) {
                for (const invalid of result.data.invalids) {
                  errors.push(`Segmentation Variable - ${invalid.msg}(${invalid.expression})`)
                }
              }
            }
          }
        }

        if (errors.length > 0) {
          for (const error of errors) {
            errorMsg(error)
          }
        } else {
          setScheme(prev => ({...prev, ...schemeData}))
        }

        ev.target.value = null;
        setIsImporting(false)
      } catch (e) {
        setIsImporting(false)
        errorMsg('Invalid json file')
      }
    }

    if (ev.target.files.length > 0 && ev.target.files[0]) {
      fr.readAsText(ev.target.files[0]);
    }
  }

  React.useEffect(() => {
    if (collection) {
      dispatch(setTargetCollection(collection))
    }
  }, [collection])

  return (
    <Grid container spacing={2} noValidate>
      {(isImporting || isLoading) && (
        <div className='loader-container'>
          <div className='loader'>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--text'></div>
          </div>
        </div>
      )}
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Name the target scheme"
            name="name"
            value={scheme.name}
            onChange={(e) => handleChange(e)}
            inputProps={{ maxLength: 80 }}
            onKeyDown={handleKeyDown}/>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Description of the target scheme"
            name="description"
            value={scheme.description}
            onChange={(e) => handleChange(e)}
            inputProps={{ maxLength: 80 }}
            onKeyDown={handleKeyDown}/>
        </FormControl>
      </Grid>


      <Grid item xs={12} sm={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="demo-simple-select-outlined"
            label="Dataset"
            name="dataset"
            select
            onChange={(e) => handleChange(e)}
            value={scheme.dataset}>
            {collections?.map(d =>
              <MenuItem key={d.collection}
                value={d.collection}>{d.name}</MenuItem>
            )}
          </TextField>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormControl fullWidth required variant="outlined" margin="normal">
          <TextField
            id="scenario-weight"
            label="Weight"
            name="weight"
            select
            value={scheme.weight}
            onChange={(e) => handleChange(e)}>
            {weightFields.map((weight) => (
              <MenuItem key={weight.propName} value={weight.propName}>
                {weight.label ? weight.label : weight.propName}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>

      <Grid item xs={12} container sm={12} justifyContent="flex-end">
        <input
          accept=".json"
          style={{display: 'none'}}
          id="contained-button-file"
          multiple
          type="file"
          readOnly={!scheme.dataset && scheme.dataset === ''}
          onChange={(e) => handleImportScenario(e)}
          data-cy='btn-import-file'/>
        <label htmlFor="contained-button-file">
          <Button variant="contained"
            color="secondary" disabled={!scheme.dataset && scheme.dataset === ''}
            component="span">
            {t('btn_import_scheme')}
          </Button>
        </label>

        <Button
          style={{ marginLeft: 6 }}
          data-cy='btn-goto-chart'
          disabled={isCreating}
          variant="contained"
          color="secondary"
          onClick={() => handleExport()}>
          {t('btn_export_scheme')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FirstStep;
