import React from 'react';
import { Navigate } from 'react-router-dom';

import Main from 'components/views/Main';
import NotFound from 'components/views/NotFound';
import Error from 'components/views/Error';
import Login from 'components/views/login/Login';
import Organizations from 'components/views/organizations/Organizations';
import Users from 'components/views/organizations/users/Users';
import Scenarios from 'components/views/organizations/scenarios/Scenarios';
import Datasets from 'components/views/organizations/datasets/Datasets';
import Organization from 'components/views/organizations/organization/Organization';
import ForgotPassword from 'components/views/login/ForgotPassword';
import ResetPassword from 'components/views/login/ResetPassword';
import LoginLayout from 'components/views/login/LoginLayout';
import Workspace from 'components/views/organizations/workspace/Workspace';
import Translations from 'components/views/trasnlations/Translations';
import Specification from 'components/views/organizations/scenarios/specification/Specification';
import TreeView from './components/views/organizations/scenarios/tree/TreeView';

export const secureRoutes = (type, orgId, orgsUserLevel) => {
  switch (type) {
    case 'level_1':
      return levelUser(orgId, orgsUserLevel);
    case 'level_2':
      return levelUser(orgId, orgsUserLevel);
    case 'level_3':
      return levelUser(orgId, orgsUserLevel);
    case 'admin':
      return admin();
    case 'super_admin':
      return superAdmin;
    default:
      break;
  }
};

const levelUser = (orgId, orgsUserLevel) => {
  if(orgsUserLevel.length === 0){
    return [
      {
        path: `/orgs/${orgId}`,
        element: <Organization />,
        children: [
          ...scenariosDatasets()
        ],
      },
      {
        path: '/orgs/:idOrg/scenarios/:id/tree',
        element: <TreeView />,
      },
      { path: '*', element: <Navigate to={`/orgs/${orgId}/scenarios`} replace /> },
    ]
  }
  return [
    ...commonRouters,
    {
      path: '/orgs/:id',
      element: <Organization />,
      children: [
        ...scenariosDatasets(),
      ],
    },
    {
      path: '/orgs/:idOrg/scenarios/:id/tree',
      element: <TreeView />,
    },
  ];
};

const admin = () => {

  return [
    ...commonRouters,
    {
      path: '/orgs/:id',
      element: <Organization />,
      children: [
        ...scenariosDatasets(),
        { path: 'users', element: <Users /> },
      ],
    },
    {
      path: '/orgs/:idOrg/scenarios/:id/tree',
      element: <TreeView />,
    },
    {
      path: '/orgs/workspace',
      element: <Workspace />,
      children: [...scenariosDatasets()],
    },
  ];
};

const commonRouters = [
  { path: '/error', element: <Error /> },
  { path: '/orgs', element: <Organizations /> },
  { path: '404', element: <NotFound /> },
  { path: '*', element: <Navigate to='/404' replace /> },
  {
    path: '/orgs/workspace/scenarios/:scenarioId/tree',
    element: <TreeView />,
  },
];
const scenariosDatasets = (orgId = '') => [

  { path: `${orgId ? orgId + '/' : ''}datasets`, element: <Datasets /> },
  {
    path: `${orgId ? orgId + '/' : ''}scenarios`,
    element: <Scenarios />,
  },
  {
    path: `${orgId ? orgId + '/' : ''}scenarios/create/specification`,
    element: <Specification />,
  },
  { path: `${orgId ? orgId + '/' : ''}scenarios/:idScenarios/specification`, element: <Specification /> },
];

const superAdmin = [
  { path: '/', element: <Main /> },
  {
    path: '/orgs/:id',
    element: <Organization />,
    children: [
      ...scenariosDatasets(),
      { path: 'users', element: <Users /> },
    ],
  },
  {
    path: '/orgs/workspace',
    element: <Workspace />,
    children: [...scenariosDatasets()],
  },
  {
    path: '/orgs/:idOrg/scenarios/:id/tree',
    element: <TreeView />,
  },
  { path: '/translations', element: <Translations /> },
  ...commonRouters,
];

export const routes = [
  {
    path: '/',
    element: <LoginLayout />,
    children: [
      { index: true, element: <Login /> },
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset', element: <ResetPassword /> },
    ],
  },
];
