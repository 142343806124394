// React and External Libraries
import React from 'react';
import {useLocation} from 'react-router-dom';
import tippy from 'tippy.js';

// MUI Components
import {
  Box,
  ClickAwayListener,
  CssBaseline,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  RadioGroup,
  Select,
  Switch
} from '@mui/material';

// MUI Icons
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import TuneIcon from '@mui/icons-material/Tune';
import CachedIcon from '@mui/icons-material/Cached';
import ClearIcon from '@mui/icons-material/Clear';

// Hooks and Contexts
import useUpdateScheme from 'api/hooks/useUpdateScheme';
import {useAuth} from '../providers/AuthProvider';
import {useLoading} from '../providers/LoadingProvider';
import {useTranslation} from '../providers/TranslationProvider';
import {useDispatch} from 'react-redux';

// Redux Actions and Custom Hooks
import {setGlobalLoading} from '../../store/appSlice';

// Style Imports
import 'tippy.js/dist/tippy.css';
import * as DrawerStyle from './styles/drawer';
import {
  listItemTextCustomStyle as ListItemTextCustom,
  MuiDivRoot,
  MuiDivToolbar,
  MuiDrawer
} from './styles/drawer';

export default function MiniDrawer({
  tabs,
  activeTab,
  scheme,
  onPoliciesSelected,
  updateAggrTree,
  setOpenToast,
  setSeverity,
  setToastMsg,
  allTabRoots,
}) {
  const [currentValue, setCurrentValue] = React.useState('Cuba');
  const [state, setState] = React.useState({left: false});
  const [radioMethod, setRadioMethod] = React.useState('Tree');
  const [policies, setPolices] = React.useState([]);
  const [fixedMenu, setFixedMenu] = React.useState(false);
  const methodologyEl = React.useRef(null);

  const location = useLocation();
  const { user } = useAuth();
  const userId =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { setIsLoading } = useLoading();
  const { mutateAsync: updateScheme, isLoading: updateIsLoading } = useUpdateScheme();

  const radioChangeHandler = (e) => setRadioMethod(e.target.value);

  const toggleChecked = (index, checked) => {
    let newPolicies = [...policies];
    newPolicies[index].selected = checked;
    onPoliciesSelected(newPolicies)
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (!fixedMenu) {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState({ ...state, [anchor]: open });
    }
  };

  const saveTrees = () => {
    const newScheme = JSON.parse(JSON.stringify(scheme));
    newScheme.active_tab = activeTab;
    for (const policy of newScheme.policies) {
      const policyTab = tabs.find((tab) => policy.id === tab.policy_id);
      policy.chart_view = policyTab.config.chart_view.type;
      policy.map_state = null;
      policy.tree_state = allTabRoots[policy.id]
    }

    setIsLoading(true)

    updateScheme({
      scheme: newScheme,
      user_id: userId,
    }).then(() => {
      setSeverity('success')
      setToastMsg('All trees saved!')
      setOpenToast(true)
      setIsLoading(false)
    });
  };

  const handleCloseDrawer = () => {
    setFixedMenu(false);
    setState({ left: false })
  }

  const methodologies = React.useMemo(() => {
    const descrs = tabs.filter((d) => d.config.methodology).map((d) => d.config.methodology);
    if (descrs.length > 0) {
      return `<div style="height: 895px; overflow-x: scroll">${descrs.join('<br>')}</div>`;
    }

    return null
  }, [tabs]);

  React.useEffect(() => {
    if (scheme.policies) {
      setPolices(
        scheme.policies.map((d) => {
          return {
            id: d.id,
            name: d.name,
            selected: true,
          };
        })
      );
    }
  }, [scheme]);

  React.useEffect(() => {
    if (methodologies) {
      tippy(methodologyEl.current, {
        content: methodologies,
        allowHTML: true,
        placement: 'right',
        trigger: 'click',
        theme: 'light',
        maxWidth: 550,
        interactive: true,
        appendTo: () => document.body,
      });
    }
  }, [methodologies]);

  React.useEffect(() => {
    dispatch(setGlobalLoading(updateIsLoading));
  }, [updateIsLoading]);

  React.useEffect(() => {
    const tooltips = document.querySelectorAll('[data-tippy-content]')
    for (const tooltip of tooltips) {
      if (tooltip._tippy) {
        tooltip._tippy.destroy()
      }
    }
    tippy('[data-tippy-content]', {
      placement: 'right',
      theme: 'light',
    })

    if(tabs[activeTab].type !== 'aggregation'){
      setFixedMenu(false);
      setState({ left: false })
    }
  }, [activeTab])

  React.useEffect(()=>{
    if(state.left){
      setFixedMenu(true);
    }
  },[state])

  const iemsList = (anchor) => (
    <Box
      style={DrawerStyle.boxStyle}
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, true)}>
      <List style={DrawerStyle.listStyle}>
        <ListItemText style={DrawerStyle.listItemTextStyle}>
          <Grid container justifyContent='flex-end' style={DrawerStyle.gridHeaderStyle}>
            <Grid item xs={10}>
              <ListItemText primary={'Policies to Aggregate'} />
            </Grid>
            <Grid item xs={2}>
                <ClearIcon
                  style={DrawerStyle.fixMenuIconsStyle}
                  onClick={() => handleCloseDrawer()}/>
            </Grid>
          </Grid>
          <br />
          {policies.map((d, index) => (
            <Grid
              component='label'
              container
              alignItems='center'
              key={d.id}
              style={DrawerStyle.gridContainerStyle}>
              <ListItemTextCustom primary={d.name} />
              <Grid style={{ textAlign: 'end' }}>
                <Switch
                  checked={d.selected}
                  onChange={(event, checked) => toggleChecked(index, checked)}
                  value='checked'
                  color='secondary'
                  size="small"
                />
              </Grid>
            </Grid>
          ))}
        </ListItemText>
        <br />
        <ListItemText style={DrawerStyle.listItemStyle}>
          <ListItemText primary={'Chart Type'} />
          <br />
          <div style={{ alignItems: 'center' }}>
            <RadioGroup
              onChange={radioChangeHandler}
              aria-label='platform'
              defaultValue='Tree'
              overlay
              name='platform'
              sx={DrawerStyle.radioGroupStyle}>
            </RadioGroup>
          </div>
          <br />
          {radioMethod === 'Map' && (
            <Select
              value={currentValue}
              style={DrawerStyle.selectDrawerStyle}
              onChange={(e) => {
                setCurrentValue(e.target.value);
              }}
            >
              <MenuItem value={'Cuba'}>Cuba</MenuItem>
              <MenuItem value={'Venezuela'}>Venezuela</MenuItem>
              <MenuItem value={'Chile'}>Chile</MenuItem>
              <MenuItem value={'México'}>México</MenuItem>
            </Select>
          )}
        </ListItemText>
      </List>
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={toggleDrawer('left', false)}>
      <MuiDivRoot id='test-sidebar'>
        <CssBaseline />
        <MuiDrawer
          variant='permanent'
          style={DrawerStyle.drawerStyle}>
          <MuiDivToolbar style={{ minHeight: 100 }}></MuiDivToolbar>
          <List>
            {tabs[activeTab].type === 'aggregation' && (
              <>
                <ListItem
                  data-tippy-content={t('update_aggr_tree')}
                  sx={DrawerStyle.paddingCommonStyle}
                  button>
                  <ListItemIcon
                    onClick={() => updateAggrTree()}
                    style={DrawerStyle.listItemIconStyle}>
                    <CachedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem
                  data-tippy-content={'Settings'}
                  sx={DrawerStyle.paddingCommonStyle}
                  button
                  key={'left'}>
                  <ListItemIcon
                    onClick={toggleDrawer('left', !state.left)}
                    style={DrawerStyle.listItemIconStyle}>
                    <TuneIcon />
                  </ListItemIcon>
                </ListItem>
              </>
            )}

            <ListItem
              data-tippy-content={t('save_icon_tooltip')}
              button sx={DrawerStyle.paddingCommonStyle}>
              <ListItemIcon onClick={saveTrees}
                            style={DrawerStyle.listItemIconStyle}>
                <SaveIcon />
              </ListItemIcon>
            </ListItem>

            {methodologies && (
              <ListItem
                sx={DrawerStyle.paddingCommonStyle}
                data-tippy-content={t('info_icon_tooltip')}
                button>
                <ListItemIcon ref={methodologyEl}
                              style={DrawerStyle.listItemIconStyle}>
                  <InfoIcon />
                </ListItemIcon>
              </ListItem>
            )}
          </List>
        </MuiDrawer>
        <Drawer
          variant='persistent'
          anchor={'left'}
          style={{ zIndex: 0 }}
          open={state['left']}
          onClose={toggleDrawer('left', false)}>
          {iemsList('left')}
        </Drawer>
      </MuiDivRoot>
    </ClickAwayListener>
  );
}
