// External library imports
import * as React from 'react';
import {styled, Tabs} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';

// Internal component and function imports
import useOrganization from 'api/hooks/useOrganization';
import {useTranslation} from 'components/providers/TranslationProvider';
import {usePermissions} from 'components/hooks/usePermissions';
import {useTheme} from 'components/providers/CustomThemeProvider';

// Style and asset imports
import logo from '../../../../assets/img/logo.0c7a85ba.png';

// Style and asset imports
import {
  MuiContainerNavBar,
  MuiGridContainer,
  MuiGridImgContainer,
  MuiImage,
  textOrg,
} from './styles/organization';
import {MuiTypographyTitle} from "../../../common/styles/modal";
import {headline7} from "../../../common/styles/global";

const Organization = () => {
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const { data, isLoading } = useOrganization(orgId);
  const { setColors } = useTheme();
  const isSpecificationView = location.pathname.split('/').includes('specification')

  React.useEffect(() => {
    if (data) setColors(data.attrs.primary_color, data.attrs.secondary_color);
  }, [data]);

  return (
    <MuiContainerNavBar>
      <MuiGridContainer container>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <MuiTypographyTitle sx={{...headline7, ...textOrg}}>{data?.name}</MuiTypographyTitle>
            {isLoading ? (
              <>
                <MuiImage src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImage src={data?.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <MuiGridContainer item xs={isSpecificationView ? 12 : 9}>
          <Outlet />
        </MuiGridContainer>
      </MuiGridContainer>
    </MuiContainerNavBar>
  );
};

export default Organization;
