import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {
  Autocomplete,
  Button as MuiButton,
  Grid,
  MenuItem,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  buttonActionStyle, buttonCancelStyle,
  commonStyle,
  MuiContainer,
  MuiSelectField,
  MuiSwitch,
  MuiTextFieldMultiple,
} from './styles/formModal';

import themeCriteria from 'theme/criteria';

import {useTranslation} from 'components/providers/TranslationProvider';
import {useDispatch} from 'react-redux';
import {setPrivateLoading} from '../../../../store/appSlice';
import {Controller, FormProvider, useForm} from 'react-hook-form';

import {useAuth} from '../../../providers/AuthProvider';
import giveMeId from '../../../../utils/supportComponents';
import useSchemes from '../../../../api/hooks/useSchemes';
import useCopyScheme from '../../../../api/hooks/useCopyScheme';

import Button from 'components/common/Button';
import Toast from 'components/common/Toast';
import Modal from 'components/common/Modal';

import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import useUsers from 'api/hooks/useUsers';
import useOrganizations from "../../../../api/hooks/useOrganizations";
import {useTheme} from '../../../providers/CustomThemeProvider';
import {optionLabelStyle} from "../users/styles/formModal";

const schema = yup.object().shape({
  userToId: yup.string().required(),
  selectedSchemeId: yup.string().required(),
});

const CopySchemesModal = ({scenario, open, onClose}) => {
  const [error, setError] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);
  const [copyTarget,setCopyTarget] = React.useState('users');
  const [inputValue, setInputValue] = React.useState('');

  const location = useLocation();
  const dispatch = useDispatch();

  const {t} = useTranslation();
  const {theme} = useTheme()
  const {user} = useAuth();
  const id = giveMeId(location.pathname, user);
  const {data: schemes, isLoading: isLoadingSchemes} = useSchemes(id);
  const {data: users, isLoading: isLoadingUsers} = useUsers(null);
  const {data:organizations,isLoading:isLoadingOrgs} = useOrganizations();
  const {
    mutateAsync: copyScheme,
    isError: copyIsError,
    isSuccess: copyIsSuccess,
    error: copyError,
    isLoading: copyIsLoading,
  } = useCopyScheme();

  const [allOptions] = React.useState(schemes?.schemes||[]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [targetCantidates, setTargetCantidates] = React.useState(users);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    control,
    setValue,
    formState: {errors, isDirty},
    getValues,
  } = methods;

  const copyScenario = (data = null) => {
    const values = getValues()
    if (values.userToId !== '' && values.schemes.length > 0) {
      const scenarioToCopy = {
        userFromId: id,
        userToId: values.userToId,
        selectedSchemeIds: values.schemes.map(s => s.id),
      };
      copyScheme(scenarioToCopy);
    }
  };

  React.useEffect(() => {
    if (copyIsError) {
      dispatch(setPrivateLoading(false));
      setError(copyError.data?.error ? t(copyError.data.error) : JSON.stringify(copyError));
      setOpenToast(true);
    }
  }, [copyIsError, copyError, t]);

  React.useEffect(() => {
    if (copyIsSuccess) {
      onClose(copyIsSuccess);
    }
  }, [copyIsSuccess]);

  React.useEffect(() => {
    if(copyTarget==='organizations'){
      setTargetCantidates(organizations);
    }else {
      setTargetCantidates(users);
    }
  },[copyTarget,isLoadingOrgs,isLoadingUsers]);

  const copyForm = (
    <ThemeProvider theme={themeCriteria}>
      <MuiContainer display='flex'>
        <Grid  item xs={12}>
          <Grid container>
            <Grid container my={1} alignContent='center' item xs={12}>
              <Typography variant='caption'>{t('copy_target')}</Typography>
            </Grid>
            <Grid container alignContent='left' item xs={3}>
              <MuiSwitch colorSecondary={theme.palette.secondary.main}
                         checked={copyTarget === 'users'}
                         onChange={() => setCopyTarget(prevState => prevState==='users'?'organizations':'users')}
              />
            </Grid>
            <Grid container alignContent={'left'} item xs={9} paddingTop={1} sx={{marginTop:'-5px'}}>
              <Typography sx={{
                ...commonStyle,
                color: copyTarget === 'organizations' ? theme.palette.secondary.main : 'black'}}>
                {t('organizations')}
              </Typography>
              <span style={{...commonStyle, marginLeft:'3px'}}>/</span>
              <Typography sx={{
                ...commonStyle,
                marginLeft:'3px',
                color: copyTarget === 'users' ? theme.palette.secondary.main : 'black'}}>
                {t('users')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{marginTop:'26px'}} >
            <Grid container alignContent='center' item xs={12}>
              <MuiSelectField id='userToId' name='userToId' variant='outlined' fullWidth
                              error={t(errors.userToId?.message)} label={t('copy_to')}
                              sx={{'& .MuiInputBase-root': {
                                  width: '400px',
                                },}}>

                {targetCantidates?.map((u) => {
                  return (
                    <MenuItem key={u._id} value={u._id}>
                      <Typography  variant='body1' fontFamily='Montserrat' fontSize={16}  letterSpacing={0.5}>
                        {copyTarget==='users'?u.email:u.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </MuiSelectField>
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{paddingTop: 20}} item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                control={control}
                name='schemes'
                defaultValue={[]}
                render={() => (
                  <Autocomplete
                    id='selectedSchemeId'
                    multiple
                    options={allOptions}
                    getOptionLabel={(option) => option.name}
                    ListboxProps={{sx: optionLabelStyle }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    disabled={isLoadingSchemes}
                    value={selectedOptions}
                    onChange={(e, value) => {
                      setValue('schemes', value);
                      setSelectedOptions(value)
                    }}
                    filterSelectedOptions
                    inputValue={inputValue}
                    onInputChange={(e, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <MuiTextFieldMultiple
                        {...params}
                        colorSecondary={theme.palette.secondary.main}
                        label={t('scheme_to_copy')}
                        name='schemes'
                        variant='outlined'
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <div>
        </div>
        <div>

        </div>
      </MuiContainer>
    </ThemeProvider>
  );

  const handleCloseToast = () => setOpenToast(false);

  const actions = (
    <>
      <MuiButton onClick={onClose} variant='text' sx={buttonCancelStyle}>
        <Typography  fontFamily='Montserrat' fontSize={14} letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </MuiButton>
      <Button
        style={{...buttonActionStyle, width:'105px'}}
        variant='outlined'
        type='submit'
        loading={copyIsLoading}
        disabled={!isDirty}
        onClick={() => copyScenario()}>
        <Typography  fontFamily='Montserrat' fontSize={14} letterSpacing={0.25}>
          {!isLoadingSchemes || !isLoadingUsers||!isLoadingOrgs ? t('copy') : 'processing...'}
        </Typography>
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <Toast
        open={openToast}
        handleClose={handleCloseToast}
        severity='error'
        vertical='top'
        horizontal='center'
        message={error}
      />
      <Modal open={open} onClose={onClose} title={t('copy_schemes')} actions={actions}>
        {copyForm}
      </Modal>
    </FormProvider>
  );
};

export default CopySchemesModal;
