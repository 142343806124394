// External library imports
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// Internal component and function imports
import useDeleteScheme from 'api/hooks/useDeleteScheme';
import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import ModalDelete from '../common/ModalDelete';

const DeleteScenario = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuth();
  const deleteMutation = useDeleteScheme();
  const Id = location.pathname.split('/')[2] === 'workspace' ?
    user.id : location.pathname.split('/')[2];

  const handleSubmit = () => {
    const scheme_id = location.state.id;
    const data = {
      user_id: Id,
      scheme_id: scheme_id,
    };
    deleteMutation.mutateAsync(data);
  };

  return (
    <ModalDelete
      deleteMutation={deleteMutation}
      onSubmit={handleSubmit}
      content={t('delete_scenario')}
      title={t('delete_scenario_title')}
      name={location.state?.name}
    />
  );
};

export default DeleteScenario;
