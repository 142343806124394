// External library imports
import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

// Internal component and function imports
import {useTranslation} from 'components/providers/TranslationProvider';
import {usePermissions} from 'components/hooks/usePermissions';
import FormModal from './FormModal';
import {MuiButton} from "../styles/dataset";

// Style and asset imports


const NewUser = () => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();
  const {search, pathname} = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);

  const handleClose = () => {
    query.delete('create');
    navigate(`${pathname}${query && ''}${query}`, { replace: true });
  };

  React.useEffect(() => {
    const createQuery = query.get('create');
    setOpen(createQuery === 'user');
  }, [query]);

  return (
    <>
      {usePermissions('create_users') && (
        <MuiButton
          variant='outlined'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() =>
            navigate(`${pathname}?create=user`, {
              replace: true,
            })
          }
        >
          {t('new_user')}
        </MuiButton>
      )}
      {open && <FormModal action='create' open={open} onClose={handleClose} />}
    </>
  );
};
export default NewUser;
