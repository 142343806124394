// External library imports
import { extent, sum } from 'd3-array';
import tippy from 'tippy.js';

// Internal component and function imports
import renderHistogram from './histogram';
import renderButton from './button';
import renderSlider from './slider';
import renderTextButton from './text-button';
import renderPie2Level from './pie-2level';
import Statistic from './statistic';
import patternify from '../../../utils/patternify.js';
import GetStat, { getStatsData } from '../shared/stats-calc';
import { getTooltipHtml } from '../../../utils/tooltip';
import { allocateBinsToBounds } from '../../../utils/array';
import { refreshIcon, impactFactorIcon, splitIcon, collapseIcon } from '../../../utils/constants';

// Style and asset imports
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const btnStyle = `
    padding: .25rem .5rem;
    font-size: .875rem; line-height: 1.5;
    border-radius: .2rem;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-weight: 400;
    border: 1px solid transparent;
    background-color: #fff;
`;

const LeafNode = (params) => {
  const {
    container,
    node,
    criteria,
    dimensions,
    parent,
    translations,
    hideSplitBtn,
    criteriaPriority,
    sections,
    statistics,
    regimes,
    histogramConfig,
    binSize,
    variables,
    setEnabledTransition
  } = params;

  const noop = function () {};

  const singleStatHeight = 32;
  let data = node.bins.slice();

  let pie_section = statistics.length > 0 ? statistics[0].section : 0; // section based on which the pie chart is calculated
  // if (histogramConfig && histogramConfig.sections && histogramConfig.sections.length > 0) {
  //   pie_section = histogramConfig.sections[0].value;
  // }
  const pie_label = statistics.length > 0 ? statistics[0].label : 0; // label on top of pie chart
  const showSplitBtn = node.splitBy.length > 0 && !hideSplitBtn;
  const showThresholdSlider = params.treeType === 'normal';
  const minMax = params.visualizationRange || extent(data, (d) => d.criteria);
  const validStats = ['percentage_count', 'percentage_sum'];

  let pie_statistic = statistics.length > 0 ? statistics[0].statistic : null;

  if (validStats.indexOf(pie_statistic) === -1) {
    pie_statistic = 'percentage_count';
  }

  // first statistic is for pie chart, others is visible under the pie
  const otherStats = statistics.slice(1);

  const showRegime = params.treeType === 'normal';
  const regimeSliderDy = showRegime ? 35 : 0;
  const histogramY = 30 + (sections.length ? 20 : 0);

  // need to pull out the fake percent and sums.
  // `fake` means not calculated from the data but from children.
  const {
    pie_percent_pre = null,
    pie_percent_post = null,
    siblings_sum_pre = null,
  } = node.fakePercent || {};

  // instance variables
  let width,
    height,
    pieValuePre,
    pieValuePost,
    sumOfWeightsPre,
    editView = params.editView || false,
    currentValue = node.value,
    oldValue = node.value,
    total = sum(data, (d) => d.sum),
    splitFunc = noop,
    collapseFunc = noop,
    onUpdate = noop,
    onEditViewOpen = noop,
    onEditViewClose = noop,
    onRegimeSliderUpdate = noop,
    onRefreshClick = () => {
      if (!node.loading) {
        setEnabledTransition(true);
        container.selectAll('.refresh-icon').classed('loading', true);
        node.loading = true;

        params.onHistogramUpdateClick(currentValue, () => {
          container.selectAll('.refresh-icon').classed('loading', false);
          node.loading = false;
        });
      }
    };

  let slider,
    pie,
    splitBtn,
    histogram,
    thresholdSlider,
    backgroundRect,
    collapseBtn,
    cancelBtn,
    okBtn,
    regimeSlider,
    refreshBtn,
    costFactorBtn,
    regimeSelectBtn,
    statsComponents,
    breadcrumb,
    defineThreshholds,
    regimeLabel;

  function main() {
    setDimensions();

    // adjust node.value to cover all the bins
    adjustNodeValue();

    // pre calculate pie.value
    adjustPieValues();

    /////// REGIMES AND UPDATE BTN ///////

    if (showRegime) {
      refreshBtn = renderTextButton({
        container,
        translation: [width / 2 - 15, dimensions.nodePadding - 7],
        visible: editView,
        html: `<button class="btn btn-sm btn-outline-light p-1"
          style="border: none; line-height: 12px; background-color: transparent; cursor: pointer">
          <img src="${refreshIcon}" style="width: 15px; height: 15px" class="refresh-icon" />
        </button>`,
      }).click(onRefreshClick);

      regimeSelectBtn = renderTextButton({
        container,
        translation: [width / 2 - 38, dimensions.nodePadding - 8],
        visible: editView,
        html: `
          <button class="btn btn-sm btn-outline-light p-1" style="border: none; line-height: 12px; background-color: transparent; cursor: pointer;">
            <img src="${impactFactorIcon}" style="width: 16px; height: 14px;" />
          </button>
        `,
      });

      const t = tippy(regimeSelectBtn.getNode(), {
        theme: 'light',
        trigger: 'click',
        interactive: true,
        arrow: true,
        appendTo: document.body,
        allowHTML: true,
        content: getTooltipHtml(
          {
            options: regimes.map((d) => d.regime_label),
            nodeId: 'regime-' + node.id,
            headerText: 'Select regime',
            btnText: 'Select',
            btnClass: 'btn-outline-success',
            selectedIndex: regimes.findIndex((d) => d.regime_label === node.regime.regime_label),
            variables
          },
          (selected) => {
            const regime = regimes.find((d) => d.regime_label === selected);

            if (regime) {
              node.regime = regime;
              node.amount = regime.slider_definition.default;

              regimeLabel.html(regime.regime_label);
              regimeSlider
                .updateTooltip(regime.slider_definition.slider_label)
                .updateIcon(regime.slider_definition.symbol)
                .updateMinMax(regime.slider_definition.min, regime.slider_definition.max)
                .update(regime.slider_definition.default);
            }

            t.hide();
          }
        ),
        placement: 'left',
      });

      regimeLabel = container
        .append('text')
        .attr('class', 'regime-label')
        .attr('text-anchor', 'end')
        .attr('pointer-events', 'none')
        .attr('font-size', '12px')
        .attr('fill', '#ccc')
        .attr('opacity', 0)
        .attr('x', width / 2 - 59)
        .attr('y', dimensions.nodePadding - 4)
        .text(node.regime.regime_label);

      regimeSlider = renderSlider({
        icon: node.regime.slider_definition.symbol,
        container,
        tooltip: node.regime.slider_definition.slider_label,
        color: node.color,
        x: -dimensions.pieOuterRadius,
        y: regimeSliderDy + 5,
        width: dimensions.pieOuterRadius * 2,
        min: node.regime.slider_definition.min,
        max: node.regime.slider_definition.max,
        value: node.amount || 0,
        name: criteria,
        showProgress: false,
        formatThousands: true,
        visible: true,
        hasEditIcon: true,
        nodeId: node.id,
        type: 'regime',
      }).onChange(function (value) {
        onRegimeSliderUpdate(Math.ceil(value));
      });
    }
    /////////// END OF REGIME SELECT //////

    ///////////// PIE CHART //////////////
    pie = renderPie2Level({
      id: node.id,
      container,
      title: pie_label,
      translations,
      node: node,
      value: [pieValuePre, pieValuePost],
      pieInnerRadius: dimensions.pieInnerRadius,
      pieOuterRadius: params.pieOuterRadius,
      translation: [
        editView ? width / 2 - dimensions.nodePadding - dimensions.pieOuterRadius : 0,
        dimensions.pieOuterRadius + (showThresholdSlider ? 25 : 0) + regimeSliderDy,
      ],
    })
      .onPieClick(function () {
        if (!node.expanded && !editView) {
          showEditView();
        }
      })
      .onPieMouseover(function () {
        if (node.expanded) {
          h(node);

          // eslint-disable-next-line
          function h(n) {
            if (n.children) {
              n.children.forEach((d) => {
                d.leafNode.components.pie.highlight(false);

                h(d);
              });
            }
          }
        }
      })
      .onPieMouseOut(function () {
        if (node.expanded) {
          c(node);

          // eslint-disable-next-line
          function c(n) {
            if (n.children) {
              n.children.forEach((d) => {
                d.leafNode.components.pie.clearHighlight();

                c(d);
              });
            }
          }
        }
      });
    /////// END OF PIE CHART ////////////

    ///////////// STATISTICS ////////////
    if (otherStats.length) {
      statsComponents = [];
      const statsData = getStatisticData();

      // show only 3 stats
      const foreignHeight = Math.min(3, otherStats.length) * singleStatHeight;

      const statsForeign = patternify(container, 'foreignObject', 'stats-foreign')
        .attr('width', dimensions.pieOuterRadius * 2 + 15)
        .attr('height', foreignHeight + 12)
        .style('width', dimensions.pieOuterRadius * 2 + 15 + 'px')
        .style('height', foreignHeight + 10 + 'px')
        .attr('x', width / 2 - dimensions.nodePadding - dimensions.pieOuterRadius * 2)
        .attr('y', dimensions.nodePadding + dimensions.pieOuterRadius * 2 + regimeSliderDy + 40);

      const scollParent = patternify(statsForeign, 'xhtml:div', 'scroll-parent').style(
        'height',
        foreignHeight + 10 + 'px'
      );

      const statsParent = patternify(scollParent, 'xhtml:div', 'stats-parent');

      otherStats.forEach((s, i) => {
        const comp = Statistic({
          id: 'stat_' + i,
          container: statsParent,
          width: dimensions.pieOuterRadius * 2,
          data: statsData[i],
          title: s.label,
          visible: false,
        });

        statsComponents.push(comp);
      });
    }

    ///////// END OF STATISTICS /////////

    ///////////// HISTOGRAM //////////////
    histogram = renderHistogram({
      data: allocateBinsToBounds(data, minMax),
      criteriaRange: minMax,
      maxDomain: params.maxDomain,
      sections: sections,
      container: container,
      currentValue: currentValue,
      fieldName: criteria,
      width: dimensions.histogramWidth,
      height: dimensions.histogramHeight,
      visible: editView,
      criteriaPriority: criteriaPriority,
      translation: [-width / 2 + dimensions.nodePadding, dimensions.nodePadding + histogramY],
      color: node.color,
      colorPercent: node.colorPercent,
      config: histogramConfig,
      binSize,
      variables,
    }).onUpdate(function () {
      pieValuePre = getPieValue('pre');
      pieValuePost = getPieValue('post');

      if (otherStats.length && statsComponents) {
        const statsData = getStatisticData();
        otherStats.forEach((s, i) => statsComponents[i].update(statsData[i]));
      }

      pie.update([pieValuePre, pieValuePost]);
    });

    if (showThresholdSlider) {
      // add slider
      slider = histogram
        .addSlider({
          value: node.value,
          visible: editView,
        })
        .onChange(function (value) {
          currentValue = value;

          if (thresholdSlider) {
            thresholdSlider.update(value);
          }

          histogram.update(value);

          if (parent) {
            updateParents(parent);
          }

          onUpdate();
        });
    }
    ///////////// END OF HISTOGRAM //////////

    //////////// COLLAPSE BUTTON ///////////
    collapseBtn = renderButton({
      container,
      translation: [
        editView ? dimensions.pieOuterRadius + dimensions.nodePadding - width / 2 : 0,
        dimensions.pieOuterRadius * 2 + 8 + (showThresholdSlider ? 25 : 0) + regimeSliderDy,
      ],
      visible: false,
      btnUrl: collapseIcon,
    }).click(function () {
      collapseFunc();
    });
    // end of collapse button

    //////////// SPLIT BUTTON /////////////
    splitBtn = renderButton({
      container,
      translation: [
        editView ? dimensions.pieOuterRadius + dimensions.nodePadding - width / 2 : 0,
        dimensions.pieOuterRadius * 2 + 8 + (showThresholdSlider ? 25 : 0) + regimeSliderDy,
      ],
      visible: !editView && showSplitBtn,
      btnUrl: splitIcon,
    });

    let tippyTooltip;

    if (showSplitBtn) {
      tippyTooltip = tippy(splitBtn.getNode(), {
        theme: 'light',
        trigger: 'click',
        interactive: true,
        arrow: true,
        appendTo: document.body,
        allowHTML: true,
        content: getTooltipHtml(
          {
            options: node.splitBy,
            nodeId: node.id,
            headerText: translations.split_by_properties,
            btnText: translations.split_btn,
            btnClass: 'btn-outline-success',
            selectedIndex: 0,
            variables,
          },
          (selected) => {
            splitFunc(selected, currentValue);
            tippyTooltip.hide();
          }
        ),
        placement: 'right',
      });

      window.tooltips.push(tippyTooltip);
    }
    ////////// END OF SPLIT BUTTON ///////////

    ////////// THRESHOLD SLIDER /////////////
    if (showThresholdSlider) {
      thresholdSlider = renderSlider({
        icon: 'filter',
        container,
        tooltip: criteria,
        color: node.color,
        x: -dimensions.pieOuterRadius,
        y: 5,
        width: dimensions.pieOuterRadius * 2,
        min: criteriaPriority === 'low' ? minMax[0] - 1 : minMax[0],
        max: criteriaPriority === 'high' ? minMax[1] + 1 : minMax[1],
        value: node.value || 0,
        sections: sections,
        priority: criteriaPriority,
        name: criteria,
        showProgress: true,
        visible: !editView,
        nodeId: node.id,
        type: 'threshold',
      }).onChange(function (value) {
        currentValue = Math.ceil(value);
        node.value = currentValue;

        if (slider) {
          slider.update(value);
        }

        if (thresholdSlider) {
          thresholdSlider.update(value);
        }

        histogram.update(value);

        if (parent) {
          updateParents(parent);
        }

        onUpdate();
      });
    }

    ////////// CANCEL BUTTON ////////////
    cancelBtn = renderTextButton({
      container,
      translation: [width / 2 - dimensions.nodePadding - 18, height - dimensions.nodePadding],
      visible: editView,
      html: `<button class="btn btn-sm btn-outline-secondary" style="${btnStyle} color: #6c757d; border-color: #6c757d;">${translations.cancel_btn}</button>`,
    }).click(() => {
      if (currentValue !== oldValue) {
        currentValue = oldValue;

        histogram.update(currentValue);

        if (thresholdSlider) {
          thresholdSlider.update(currentValue);
        }

        if (slider) {
          slider.update(currentValue);
        }

        if (parent) {
          updateParents(parent);
        }

        onUpdate();

        setTimeout(() => {
          hideEditView();
        }, 750);
      } else {
        hideEditView();
      }
    });

    ///////// OK BUTTON ///////////
    okBtn = renderTextButton({
      container,
      translation: [width / 2 - 110, height - dimensions.nodePadding],
      visible: editView,
      html: `<button
              class="btn btn-sm btn-outline-success"
              style="${btnStyle} color: #28a745; border-color: #28a745; padding-left: 1rem !important; padding-right: 1rem !important;"
            >
              ${translations.ok_btn}
            </button>`,
    }).click(() => {
      oldValue = currentValue;
      node.value = currentValue;

      if (showRegime) {
        onRefreshClick();
      }

      onUpdate();
      hideEditView();
    });

    if (node.isRoot) {
      container
        .append('text')
        .attr('class', 'root-title')
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('y', -18)
        .text(node.name);
    }

    // define thresholds instruction text
    defineThreshholds = container
      .append('text')
      .attr('font-weight', '600')
      .attr('x', -width / 2 + dimensions.nodePadding)
      .attr('y', dimensions.nodePadding + 4)
      .attr('font-size', '15px')
      .attr('opacity', 0)
      .attr('pointer-events', 'none')
      .text(translations.define_thresholds_txt);

    // background rectangle
    backgroundRect = container
      .append('rect')
      .attr('transform', `translate(${-width / 2})`)
      .attr('fill', '#fff')
      .attr('stroke', '#ddd')
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('width', width)
      .attr('height', height)
      .attr('opacity', editView ? 1 : 0)
      .attr('pointer-events', 'none')
      .lower();

    // breadcrumb
    breadcrumb = container
      .append('text')
      .attr('class', 'pie-breadcrumb')
      .attr('x', -width / 2 + dimensions.nodePadding)
      .attr('y', dimensions.nodePadding + 22)
      .attr('opacity', 0)
      .attr('font-size', '11px')
      .attr('fill', '#000')
      .html(getBreadCrumb());

    if (node.children.length > 0) {
      splitBtn.hide();

      if (thresholdSlider) {
        thresholdSlider.hide();
      }

      collapseBtn.show();

      if (regimeSlider) {
        regimeSlider.hide();
      }
    }

    main.components = {
      pie,
      splitBtn,
      thresholdSlider,
      slider,
      histogram,
      collapseBtn,
      tippyTooltip,
      regimeSlider,
    };

    return main;
  }

  function setDimensions() {
    const { pieOuterRadius, pieHistPadding, histogramWidth, histogramHeight, nodePadding } =
      dimensions;

    width = nodePadding * 2 + pieOuterRadius * 2 + pieHistPadding + histogramWidth;

    // show only 3 stats
    const foreignHeight = Math.min(3, otherStats.length) * singleStatHeight;

    height = nodePadding * 2 + histogramHeight + histogramY + foreignHeight;
  }

  function adjustNodeValue() {
    if (node.value >= minMax[1]) {
      if (criteriaPriority === 'high') {
        node.value = minMax[1] + 1;
      } else {
        node.value = minMax[1];
      }
    }

    if (node.value <= minMax[0]) {
      if (criteriaPriority === 'low') {
        node.value = minMax[0] - 1;
      } else {
        node.value = minMax[0];
      }
    }

    node.total = total;
  }

  function getPieSection() {
    let s = pie_section;

    if (pie_section === -1) {
      // dynamic selection
      s = currentValue;
    } else if (pie_section === -2) {
      // full range
      s = criteriaPriority === 'low' ? minMax[1] + 1 : minMax[0] - 1;
    }

    return s;
  }

  function adjustPieValues() {
    if (node.fakePercent && node.children.length > 0 && pie_percent_pre !== null) {
      pieValuePre = pie_percent_pre;
    } else {
      pieValuePre = getPieValue('pre');
    }

    if (node.fakePercent && node.children.length > 0 && pie_percent_post !== null) {
      pieValuePost = pie_percent_post;
    } else {
      pieValuePost = getPieValue('post');
    }

    if (node.fakePercent && node.children.length > 0 && siblings_sum_pre !== null) {
      sumOfWeightsPre = siblings_sum_pre;
    } else {
      sumOfWeightsPre = getTotalSum();
    }
  }

  function getTotalSum() {
    const useStatVarInsteadOfSum = statistics[0].statistic === 'percentage_sum';
    return sum(data, (d) => (useStatVarInsteadOfSum ? d[`post_${statistics[0].stat_var}`] : d.sum));
  }

  function getAcceptedPoints(value, prop = 'sum') {
    const _currentValue = value || currentValue;
    return sum(
      data.filter((d) => {
        return criteriaPriority === 'high'
          ? d.criteria >= _currentValue
          : d.criteria <= _currentValue;
      }),
      (d) => d[prop]
    );
  }

  function getPieValue(preOrPost = 'pre') {
    const first_stat = statistics[0];

    const stat_value = GetStat(
      data,
      getPieSection(),
      first_stat.stat_var,
      pie_statistic,
      criteriaPriority,
      preOrPost
    );
    return stat_value;
  }

  function getBreadCrumb() {
    let breadcrumb = [node.name];
    let _parent = parent;

    while (_parent) {
      breadcrumb.push(_parent.data.name);
      _parent = _parent.parent;
    }

    return breadcrumb.reverse().join(' &#x2b62 ');
  }

  function updateParents(_parent) {
    let parentData = _parent.data;

    // PRE POLICY WEIGHTS: ( child1.sum + child2.sum + …)
    let sum_of_weights_pre = sum(parentData.children.map((d) => d.leafNode.getTotalSum()));

    // (child1.stat1.pre * child1.total) + (child2.stat1.pre * child2.total ) + … )
    let stats_weighted_pre = sum(
      parentData.children.map((d) => {
        const total = d.leafNode.getTotalSum();
        const datum = d.leafNode.components.pie.getData();
        return total * (datum[0] / 100);
      })
    );

    // (child1.stat1.post * child1.total) + (child2.stat1.post * child2.total ) + … )
    let stats_weighted_post = sum(
      parentData.children.map((d) => {
        const total = d.leafNode.getTotalSum();
        const datum = d.leafNode.components.pie.getData();
        return total * (datum[1] / 100);
      })
    );

    let parent_pie_percent_pre = (stats_weighted_pre / sum_of_weights_pre) * 100;
    let parent_pie_percent_post = (stats_weighted_post / sum_of_weights_pre) * 100;

    parentData.fakePercent = {
      pie_percent_pre: parent_pie_percent_pre,
      pie_percent_post: parent_pie_percent_post,
      siblings_sum_pre: sum_of_weights_pre,
    };

    parentData.leafNode.updatePie(
      parent_pie_percent_pre,
      parent_pie_percent_post,
      sum_of_weights_pre
    );

    // recursion
    if (_parent.parent) {
      updateParents(_parent.parent);
    }
  }

  function getStatisticData() {
    return getStatsData({
      statistics: otherStats,
      currentValue,
      criteriaPriority,
      minMax: params.populationRange,
      data,
      color: node.color,
    });
  }

  function showEditView(othersOpacity = 0.3) {
    const spaceForRegime = showRegime ? 20 : 0;

    editView = !editView;

    if (showRegime) {
      onRefreshClick();
    }

    pie.translate([
      width / 2 - dimensions.nodePadding - dimensions.pieOuterRadius,
      dimensions.nodePadding + dimensions.pieOuterRadius + 8 + spaceForRegime + regimeSliderDy,
    ]);

    if (regimeSlider) {
      regimeSlider.translate([
        width / 2 - dimensions.nodePadding - dimensions.pieOuterRadius * 2 + 8,
        dimensions.nodePadding + spaceForRegime + 10,
      ]);
    }

    splitBtn.hide();
    cancelBtn.show();
    okBtn.show();

    if (thresholdSlider) {
      thresholdSlider.hide();
    }

    setTimeout(() => {
      if (refreshBtn) {
        refreshBtn.show();
      }

      if (costFactorBtn) {
        costFactorBtn.show();
      }

      if (regimeSelectBtn) {
        regimeSelectBtn.show();
      }

      if (regimeLabel) {
        regimeLabel.attr('opacity', 1);
      }

      if (statsComponents) {
        statsComponents.forEach((d) => d.show());
      }

      if (slider) {
        slider.show();
      }

      pie.showTitle();

      histogram.show();
      defineThreshholds.attr('opacity', 1);
      breadcrumb.attr('opacity', 1);
    }, 750);

    backgroundRect.attr('opacity', editView ? 1 : 0);

    container.raise();

    onEditViewOpen({
      container,
      nodeWidth: width,
      nodeHeight: height,
      currentValue,
      othersOpacity,
    });
  }

  function hideEditView() {
    editView = !editView;

    pie.translate([0, dimensions.pieOuterRadius + (showThresholdSlider ? 25 : 0) + regimeSliderDy]);

    pie.hideTitle();

    if (regimeSlider) {
      regimeSlider.translate([-dimensions.pieOuterRadius, regimeSliderDy + 5]);
    }

    if (slider) {
      slider.hide();
    }

    histogram.hide();
    cancelBtn.hide();
    okBtn.hide();
    defineThreshholds.attr('opacity', 0);
    breadcrumb.attr('opacity', 0);

    if (statsComponents) {
      statsComponents.forEach((d) => d.hide());
    }

    if (refreshBtn) {
      refreshBtn.hide();
    }

    if (regimeSelectBtn) {
      regimeSelectBtn.hide();
    }

    if (regimeLabel) {
      regimeLabel.attr('opacity', 0);
    }

    if (costFactorBtn) {
      costFactorBtn.hide();
    }

    setTimeout(() => {
      if (showSplitBtn) {
        splitBtn.show();
      }

      if (thresholdSlider) {
        thresholdSlider.show();
      }
    }, 750);

    backgroundRect.attr('opacity', editView ? 1 : 0);
    container.lower();

    onEditViewClose({
      container,
      nodeWidth: width,
      nodeHeight: height,
    });
  }

  main.updateHistogram = (newData) => {
    data = newData;

    pieValuePre = getPieValue('pre');
    pieValuePost = getPieValue('post');

    pie.update([pieValuePre, pieValuePost]);

    histogram.updateData(allocateBinsToBounds(newData, minMax));

    if (parent) {
      updateParents(parent);
    }

    if (otherStats.length && statsComponents) {
      const statsData = getStatisticData();
      otherStats.forEach((s, i) => {
        statsComponents[i].update(statsData[i]);
      });
    }
  };

  main.onUpdate = (f) => {
    onUpdate = f;
    return main;
  };

  main.onSplit = (f) => {
    splitFunc = f;
    return main;
  };

  main.onCollapse = (f) => {
    collapseFunc = f;
    return main;
  };

  main.updatePie = (pre_percent, post_percent, sum_pre) => {
    sumOfWeightsPre = sum_pre;
    pie.update([pre_percent, post_percent]);
    return main;
  };

  main.onEditViewOpen = (f) => {
    onEditViewOpen = f;
    return main;
  };

  main.onEditViewClose = (f) => {
    onEditViewClose = f;
    return main;
  };

  main.onRegimeSliderUpdate = (f) => {
    onRegimeSliderUpdate = f;
    return main;
  };

  main.getTotalSum = () => {
    if (node.expanded && sumOfWeightsPre) {
      return sumOfWeightsPre;
    }
    return getTotalSum();
  };

  main.getUnitsAccepted = () => getAcceptedPoints(null, 'sum');

  main.getAcceptedCost = () => sum(data, (d) => d['cost']);

  main.updateColor = (color) => {
    pie.updatePieColor();
    histogram.updateColor(color);

    if (thresholdSlider) {
      thresholdSlider.updateColor(color);
    }

    if (slider) {
      slider.updateColor(color);
    }

    if (statsComponents) {
      statsComponents.forEach((d) => d.updateColor(color));
    }

    if (regimeSlider) {
      regimeSlider.updateColor(color);
    }

    return main;
  };

  main.showEditView = (opacity) => showEditView(opacity);
  main.hideEditView = hideEditView;
  main.getContainer = () => container;
  main.getSize = () => ({ width, height });

  return main();
}

export default LeafNode;
