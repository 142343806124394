// External library imports
import React from "react";
import {Box, CardActions, CardContent, Grid, Typography,} from '@mui/material';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useSnackbar} from 'notistack';

// Redux Actions and Store
import {setTargetCollection, setTargetScheme} from '../../../../store/appSlice';

// Custom Hooks and Contexts
import {useTranslation} from '../../../providers/TranslationProvider';
import useCreateScheme from '../../../../api/hooks/useCreateScheme';
import useUpdateScheme from '../../../../api/hooks/useUpdateScheme';

// Utility imports
import {getRandomId} from '../../../../utils/formatter';
import {schemeChanged} from '../../../../utils/utils';
import formulaValidator from '../../../../api/formulaValidator';

// Internal component and function imports
import SecondStep from "./Step/SecondStep";
import ThirdStep from "./Step/ThirdStep";
import FirstStep from "./Step/FirstStep";
import FourStep from "./Step/FourStep";
import Policies from "./Step/Policies";

// Style and asset imports
import {boxStyle, divStyle, MuiButton, MuiCard} from './styles/StepForm';
import {Item} from './Step/form/styles/common';

const initialValues = {
  dataset: '',
  name: '',
  description: '',
  weight: '',
};

const defaultWeight = {
  propName: 'weight',
  label: 'weight',
  category: 'weight',
}

const StepForm = ({ scenario, schemes, orgId, isWorkspace }) => {
  const [isSavingScenario, setIsSavingScenario] = React.useState(false)
  const [scheme, setScheme] = React.useState(initialValues);
  const [selectedCollection, setSelectedCollection] = React.useState(null)
  const [filterable, setFilterable] = React.useState([])
  const [filters, setFilters] = React.useState([])
  const [aggregation, setAggregation] = React.useState({})
  const [variables, setVariables] = React.useState([])
  const [weightFields, setWeightFields] = React.useState([defaultWeight])
  const [policies, setPolicies] = React.useState([])
  const [weight, setWeight] = React.useState('weight')
  const [schemeCollectionConfig, setSchemeCollectionConfig] = React.useState([])

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const targetScheme = useSelector(state => state.app.targetScheme)
  const targetCollection = useSelector(state => state.app.targetCollection)
  const schemeNameChange = false
  const { mutateAsync: createScheme } = useCreateScheme();
  const { mutateAsync: updateScheme } = useUpdateScheme();
  const location = useLocation();
  const isCreating = location.pathname.split('/')[4] === 'create'
  let schemeId = null

  const errorToast = (msg) => enqueueSnackbar(msg, { variant: 'error' })

  const successToast = (msg) => enqueueSnackbar(msg, { variant: 'success' })

  const buildFilters = () => {
    let localScheme = _.cloneDeep(targetScheme)
    let localCollection = _.cloneDeep(targetCollection)

    setVariables(localCollection.variables);

    let localWeightFields = localCollection.variables
      .filter((d) => d.category === 'weight')

    setWeightFields(
      localWeightFields.length > 0 ? localWeightFields : [defaultWeight]
    )

    // all available filters
    setFilterable(
      localCollection.filters.filter((d) => {
        return d.type === "categorical" || d.type === "numerical";
      })
    );

    // saved filters
    setFilters((localScheme && localScheme.match && localScheme.match.in_dataset) || []);

    let schemeCollectionConfigLocal = scenario.scheme.schemeCollectionConfig ? _.cloneDeep(scenario.scheme.schemeCollectionConfig) : []

    if (schemeCollectionConfigLocal.length === 0) {
      for (const binSize of localCollection.binSizes) {
        schemeCollectionConfigLocal.push(binSize)
      }
    }

    if (localScheme
      && (!localScheme.schemeCollectionConfig || localScheme.schemeCollectionConfig.length === 0)
    ) {
      for (let i = 0; i < schemeCollectionConfigLocal.length; i++) {
        let histogramConfig = localCollection.histogramConfigs.find(hc => hc.variable === schemeCollectionConfigLocal[i].variable)
        let extent = localCollection.extents.find(e => e.variable === schemeCollectionConfigLocal[i].variable)
        let sections = localCollection.sections.find(e => e.variable === schemeCollectionConfigLocal[i].variable)

        if (histogramConfig) {
          schemeCollectionConfigLocal[i].sliderLabel = histogramConfig.sliderLabel
          schemeCollectionConfigLocal[i].xLabel = histogramConfig.xLabel
        }

        if (extent) {
          schemeCollectionConfigLocal[i].xExtent = extent.xExtent
          schemeCollectionConfigLocal[i].yExtent = extent.yExtent
        }

        if (sections) {
          schemeCollectionConfigLocal[i].sections = sections.sections
        }
      }
    } else {
      for (let j = 0; j < schemeCollectionConfigLocal.length; j++) {
        let extent = localCollection.extents.find(e => e.variable === schemeCollectionConfigLocal[j].variable)
        let sections = localCollection.sections.find(e => e.variable === schemeCollectionConfigLocal[j].variable)

        if (extent) {
          schemeCollectionConfigLocal[j].xExtent =
            schemeCollectionConfigLocal[j].xExtent && schemeCollectionConfigLocal[j].xExtent.length
              ? schemeCollectionConfigLocal[j].xExtent
              : extent.xExtent
          schemeCollectionConfigLocal[j].yExtent =
            schemeCollectionConfigLocal[j].yExtent && schemeCollectionConfigLocal[j].yExtent.length
              ? schemeCollectionConfigLocal[j].yExtent : extent.yExtent
        }

        if (sections) {
          schemeCollectionConfigLocal[j].sections =
            schemeCollectionConfigLocal[j].sections && schemeCollectionConfigLocal[j].sections.length
              ? schemeCollectionConfigLocal[j].sections
              : extent.sections
        }
      }
    }

    setSchemeCollectionConfig(schemeCollectionConfigLocal)
  }

  const setLocalTargetScheme = () => {
    setSelectedCollection(scheme.dataset);
    setPolicies(_.cloneDeep(scheme.policies) || [])
    setAggregation(_.cloneDeep(scheme.aggregation) || {})
    setWeight(scheme.weight || 'weight')
    setSchemeCollectionConfig(scheme.schemeCollectionConfig)
  };

  const validatePrioritization = (prioritization) =>
    prioritization !== undefined && prioritization.name !== undefined && prioritization.name.length > 0;

  const hasErrorRegime = (regime) => {
    return regime.some(x => {
      return (x.regime_label === undefined || x.regime_label.length === 0 ||
        x.slider_definition === undefined || !(parseInt(x.slider_definition.default) >= 0) ||
        !(parseInt(x.slider_definition.min) >= 0) || !(parseInt(x.slider_definition.max) >= 0) ||
        x.slider_definition.symbol === undefined || x.slider_definition.symbol.length === 0 ||
        x.slider_definition.slider_label === undefined || x.slider_definition.slider_label.length === 0 ||
        x.effects.length === 0 || x.effects.some(y => {
          return (y.effect === undefined || y.effect.length === 0 ||
            y.variable === undefined || y.variable.length === 0)
        }))
    });
  }

  const hasErrorStatistic = (statistic) => {
    return statistic.some(x => x.label === undefined || x.label.length === 0
      || x.section === undefined || x.stat_var === undefined
      || x.stat_var.length === 0 || x.statistic === undefined
      || x.statistic.length === 0
    )
  }

  const hasErrorAffectedVariable = (affected) => {
    return affected.some(x => {
      return (x.name === undefined || x.name.length === 0 || x.effects.length === 0 ||
        x.effects.some(y => {
          return (y.label === undefined || y.label.length === 0 || y.type === undefined ||
            y.type.length === 0 || y.value === undefined || y.value.length === 0)
        }))
    })
  }

  const saveTargetScheme = () => {
    let localTargetScheme = {
      id: scheme && scheme.id ? scheme.id : getRandomId(),
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      description: scheme.description,
      dataset: selectedCollection,
      match: {
        in_dataset: filters,
        custom: [],
      },
      prioritizationCustomVariables: scheme.prioritizationCustomVariables?.length > 0
        ? scheme.prioritizationCustomVariables
        : [],
      weight: weight,
      policies: policies,
      aggregation: aggregation,
      nameChange: schemeNameChange,
      schemeCollectionConfig: schemeCollectionConfig,
    };
    schemeId = localTargetScheme.id

    if (schemes.schemes.some((d) => d.id === localTargetScheme.id)) {
      if (!_.isEqual(targetScheme.match, localTargetScheme.match)) {
        localTargetScheme.policies.forEach((d) => {
          d.tree_state = null;
        });
      }

      let _schemeChanged = schemeChanged(localTargetScheme, scenario.scheme);

      if (Array.isArray(_schemeChanged)) {
        localTargetScheme.policies.forEach((d) => {
          if (_schemeChanged.indexOf(d.id) > -1) {
            d.tree_state = null;
          }
        });
      }

      localTargetScheme.policies.forEach((d) => {
        if (targetScheme.schemeCollectionConfig && targetScheme.schemeCollectionConfig.length > 0) {
          let oldSchemeCollectionConfig =
            targetScheme.schemeCollectionConfig.find(s => s.variable === d.prioritization[0].name)
          let newSchemeCollectionConfig =
            localTargetScheme.schemeCollectionConfig.find(s => s.variable === d.prioritization[0].name)

          if (oldSchemeCollectionConfig
            && newSchemeCollectionConfig
            && (oldSchemeCollectionConfig.binSize !== newSchemeCollectionConfig.binSize)
          ) {
            d.tree_state = null;
          }
        }
      });

      if (_schemeChanged) {
        return updateScheme({
          user_id: orgId,
          scheme: localTargetScheme,
        });
      } else {
        return new Promise((resolve, reject) => {
          resolve(true);
        })
      }
    } else {
      return createScheme({
        user_id: orgId,
        scheme: localTargetScheme,
      });
    }
  }

  const handleGoToChart = async () => {
    setIsSavingScenario(true)
    if (!scheme.weight) {
      errorToast('Choose weight variable.')
      setIsSavingScenario(false)
      return false;
    }
    //validate name
    const nameExists = schemes.schemes.some(scenario => scenario.name === scheme.name);
    if (nameExists && isCreating) {
      errorToast(t('scheme_collection_exist'));
      setIsSavingScenario(false)
      return false;
    }

    // validate policies
    const missingSomething = policies.some((x) => {
      return (
        x.prioritization.length === 0 || validatePrioritization(x.prioritization[0]) === false
        || (x.regimes && x.regimes.length === 0) || hasErrorRegime(x.regimes)
        || (x.statistics && x.statistics.length === 0) || hasErrorStatistic(x.statistics)
        || (x.affected_variables && x.affected_variables.length === 0)
        || hasErrorAffectedVariable(x.affected_variables)
      );
    });

    let errors = []
    for (const pol of policies) {
      if (pol.affected_variables.length > 0) {
        let formulas = []
        for (const affectedVariable of pol.affected_variables) {
          for (const effect of affectedVariable.effects) {
            if (effect.type === 'formula') {
              formulas.push(effect.value)
            }
          }
        }

        if (formulas.length) {
          const result = await formulaValidator(formulas, scheme.dataset)

          if (result.data && result.data.invalids && result.data.invalids.length > 0) {
            for (const invalid of result.data.invalids) {
              errors.push(`Affected Variable - ${invalid.msg}(${invalid.expression})`)
            }
          }
        }
      }

      if (pol.segmentation_variables && pol.segmentation_variables.dataset_custom) {
        let segmentationFormulas = []

        for (const segCustom of Object.values(pol.segmentation_variables.dataset_custom)) {
          segmentationFormulas.push(segCustom.value)
        }

        if (segmentationFormulas.length > 0) {
          const result = await formulaValidator(segmentationFormulas, scheme.dataset)

          if (result.data && result.data.invalids && result.data.invalids.length > 0) {
            for (const invalid of result.data.invalids) {
              errors.push(`Segmentation Variable - ${invalid.msg}(${invalid.expression})`)
            }
          }
        }
      }
    }

    if (policies.length === 0 || missingSomething) {
      let sms = 'Fill all fields. You need at least one regime, one statistic, prioritization and affect variables.'
      errorToast(sms)
      setIsSavingScenario(false)
      return false
    }

    if (errors.length > 0) {
      for (const error of errors) {
        errorToast(error)
      }
      setIsSavingScenario(false)
    }

    try {
      const response = await saveTargetScheme();
      setIsSavingScenario(false)
      if (response) {
        successToast('Scheme Saved')
        const protocol = window.location.protocol
        const domain = window.location.host
        window.location.href = `${protocol}//${domain}/orgs/${isWorkspace ? 'workspace' : orgId}/scenarios/${schemeId}/tree`;
      }
    } catch (e) {
      let errorMessage = t(e.data.error)
      errorToast(errorMessage)
      setIsSavingScenario(false)
    }
  }
  const handleExportScheme = () => {
    let schemeDataToExport = {
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      policies: policies,
      aggregation: aggregation,
    }

    const schemeDataToExportJson = JSON.stringify(schemeDataToExport);

    const anchorLinkDonwload = document.createElement('a');
    anchorLinkDonwload.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(schemeDataToExportJson);
    anchorLinkDonwload.download = `${scheme.name}_export.json`;

    anchorLinkDonwload.click();
  }

  React.useEffect(() => {
    setScheme(scenario.scheme)
    dispatch(setTargetCollection(scenario.collection))
    dispatch(setTargetScheme(scenario.scheme))
  }, [scenario.scheme])

  React.useEffect(() => {
    if (isCreating && targetCollection && Object.keys(targetCollection).length > 0) {
      buildFilters()
    }
  }, [isCreating, targetCollection, scheme])

  React.useEffect(() => {
    if (targetScheme
      && Object.keys(targetScheme).length > 0
      && targetCollection
      && Object.keys(targetCollection).length > 0
    ) {
      setLocalTargetScheme()
      buildFilters()
    }
  }, [targetScheme, targetCollection, scheme])

  return (
    <Grid container justifyContent="center" spacing={1}>
      {isSavingScenario && (
        <div className='loader-container'>
          <div className='loader'>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--text'></div>
          </div>
        </div>
      )}
      <Grid item md={12}>
        <MuiCard>
          <CardContent style={{padding: 0}}>
            <Grid item container spacing={1} justifyContent="center">
              <Box sx={boxStyle}>
                {/*Step 1*/}
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography>Select the dataset to use</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <FirstStep
                        collections={schemes.collections}
                        scheme={scheme}
                        weightFields={weightFields}
                        isCreating={isCreating}
                        setScheme={setScheme}
                        orgId={orgId}
                        handleExport={handleExportScheme}/>
                    </Item>
                  </Grid>
                </Grid>

                {scheme && scheme.dataset !== '' && (
                  <>
                    <div style={divStyle} />

                    {/*Step 2*/}
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>{t('define_tar_pop')}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <SecondStep values={filters}
                            filters={filterable}
                            setFilters={setFilters}/>
                        </Item>
                      </Grid>
                    </Grid>

                    <div style={divStyle} />
                    {/*Step 3*/}
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>{t('custom_prioritization_variable_header')}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <ThirdStep
                            scheme={scheme}
                            variables={variables}
                            setScheme={setScheme}/>
                        </Item>
                      </Grid>
                    </Grid>

                    <div style={divStyle} />
                    {/*Step 4*/}
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>{t('scheme_histogram_configuration')}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>
                          <FourStep
                            collectionName={scheme.dataset ? scheme.dataset : ''}
                            weightField={scheme.weight ? scheme.weight : 'weight'}
                            schemeCollectionConfig={schemeCollectionConfig}
                            setSchemeCollectionConfig={setSchemeCollectionConfig}/>
                        </Item>
                      </Grid>
                    </Grid>

                    <div style={divStyle} />
                    {/*Step 5*/}
                    <Grid container spacing={2} direction='column' sx={{
                      mt: '34px',
                      maxWidth: '100%'
                    }}>
                      <Grid item>
                        <Typography>{t('policies')}</Typography>
                      </Grid>
                      <Grid container item sx={{width: '101%', maxWidth: '100%'}}>
                        <Policies
                          policies={policies}
                          setPolicies={setPolicies}
                          aggregation={aggregation}
                          variables={variables}
                          setAggregation={setAggregation}/>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container spacing={1}>
              <Grid item md={10}></Grid>
              <Grid item md={2}>
                <MuiButton
                  data-cy='btn-goto-chart'
                  variant="contained"
                  color="secondary"
                  type="Submit"
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    handleGoToChart()
                  }}>
                  {t('go_to_chart')}
                </MuiButton>
              </Grid>
            </Grid>
          </CardActions>
        </MuiCard>
      </Grid>
    </Grid>
  )
}

export default StepForm
