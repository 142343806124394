// External library imports
import * as React from 'react';
import {Grid,} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';

// Internal component and function imports
import {useTranslation} from 'components/providers/TranslationProvider';
import {useAuth} from 'components/providers/AuthProvider';

// Style and asset imports
import logo from '../../../../assets/img/logo.0c7a85ba.png';
import {
  MuiContainerNavBar,
  MuiGridContainer,
  MuiGridImgContainer,
  MuiImg,
} from './styles/workspace';
import {MuiTypography} from "../../../common/styles/global";

const Workspace = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { user, isLoading } = useAuth();
  const activeTab = location.pathname.split('/')[3];
  const prefixPath = location.pathname.split('/').slice(0, 3).join('/');
  const isSpecificationView = location.pathname.split('/').includes('specification')


  const createTab = (label, value, to) => ({ label, value, to });
  const initializeTabs = () => {
    const tabs = [
      createTab(t('scenarios'), 'scenarios', '/scenarios'),
      createTab(t('datasets'), 'datasets', '/datasets'),
    ];
    return tabs;
  };

  const tabs = initializeTabs();

  const getTabValue = () => activeTab || 'scenarios';

  return (
    <MuiContainerNavBar>
      <Grid container>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <MuiTypography variant='h6' sx={{textAlign: 'center', fontWeight: 'bold'}}> {user?.org.name}/{user?.name}</MuiTypography>
              {isLoading ? (
                <>
                  <MuiImg src={logo} alt={'logo'}/>
                </>
              ) : (
                <>
                  <MuiImg src={user?.org.attrs.profile_img_url} alt={'logo'}/>
                </>
              )}
          </MuiGridImgContainer>
        )}
        <MuiGridContainer item xs={isSpecificationView ? 12 : 9}>
          <Outlet />
        </MuiGridContainer>
      </Grid>
    </MuiContainerNavBar>
  );
};
export default Workspace;
