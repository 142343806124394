// External library imports
import React from 'react';
import {
  Grid, TextField, Button, FormControlLabel, Checkbox, MenuItem, Slider,
  FormControl, FormGroup, IconButton
} from '@mui/material';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';


const SecondStep = ({ values, filters, setFilters }) => {
  const [filtersConfig, setFiltersConfig] = React.useState([])
  const filterTypes = React.useMemo(() => {
    return new Map(
      filters.map(f => [
        f.value,
        f.type,
      ])
    )
  }, [filters])

  let filtersCounter = 0

  const addFilter = () => {
    let configs = [ ...filtersConfig ];
    let filtersCounter = configs.filter((c) => c.active).length;

    if (configs[filtersCounter]) {
      configs[filtersCounter].active = true;

      filtersCounter++;
      setFiltersConfig([ ...configs ])
    }
  }

  const removeFilter = (index) => {
    let configs = [ ...filtersConfig ];
    configs[index].active = false;
    configs[index].selectedFilter = null;
    configs[index].values = []
    configs[index].options = []

    filtersCounter = Math.max(filtersCounter - 1, 0);
    setFiltersConfig(
      configs.sort(
        (a, b) => b.active - a.active
      )
    );
  }

  const handleSliderChange = (event, value, index) => {
    let configs = [ ...filtersConfig ]
    configs[index].values = value;

    updateFilters(configs);
  }

  const handleSelectChange = (event, value, index) => {
    let configs = [ ...filtersConfig ]
    configs[index].selectedFilter = value
    const filter = filters.find(d => d.value === value);

    if (filter.type === 'categorical') {
      configs[index].options = filter.values;
      configs[index].values = filter.values;
    } else if (filter.type === 'numerical') {
      configs[index].options = [filter.min, Math.ceil(filter.max)];
      configs[index].values = [filter.min, Math.ceil(filter.max)];
    }

    updateFilters(configs);
  }

  const handleCheckboxChange = (value, index) => {
    let configs = [ ...filtersConfig ]
    let existsValue = configs[index].values.find((d) => d === value)

    if (existsValue) {
      configs[index].values = configs[index].values.filter((d) => d !== value)
    } else {
      configs[index].values = [ ...configs[index].values, value ]
    }

    updateFilters(configs)
  }

  const updateFilters = (configs) => {
    const newFiltersConfig = configs
      .filter((d) => d.active && d.selectedFilter)
      .map((d) => {
        const type = filterTypes.get(d.selectedFilter);
        return {
          type: type,
          [d.selectedFilter]: d.values
        }
      });

    setFilters([ ...newFiltersConfig ])
  }

  React.useEffect(() => {
    let configuration = filters.map(() => ({
      active: false,
      selectedFilter: null,
      options: [],
      values: [],
    }))

    if (values && values) {
      values.forEach((d, i) => {
        if (configuration[i]) {
          configuration[i].active = true;
          const key = Object.keys(d).filter(k => k !== 'type')[0];

          if (key) {
            const f = filters.find(d => d.value === key);

            if (f) {
              configuration[i].selectedFilter = key;
              configuration[i].values = d[key];
              configuration[i].options = f.type === 'categorical' ? f.values : [f.min, Math.ceil(f.max)];
            }
          }
        }
      });

      filtersCounter = values.length;
      setFiltersConfig([ ...configuration ])
    }
  }, [filters, values])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {filtersConfig.filter((d) => d.active).map((config, i) => (
            <Grid container key={i}>
              <Grid item xs={11}>
                <FormControl fullWidth variant='outlined'>
                  <TextField
                    value={config.selectedFilter ? config.selectedFilter : ''}
                    name='selectedFilter'
                    label='Select Filter'
                    onChange={(e) => { handleSelectChange(e, e.target.value, i) }}
                    select
                    data-cy={`select_eligibity_${i}`}>
                    {filters.map(filter => (
                      <MenuItem key={filter.value} value={filter.value}>{filter.text}</MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  variant="contained"
                  onClick={() => removeFilter(i)}
                  type="button"
                  data-cy={`remove_eligibity_${i}`}>
                  <DeleteForeverTwoToneIcon style={{ color: '#fd7676' }}/>
                </IconButton>
              </Grid>
              <Grid item xs={12} style={{margin: '10px 20px 0 10px'}}>
                {filterTypes.get(config.selectedFilter) === 'numerical' ? (
                  <Slider
                    value={config.values}
                    min={config.options[0]}
                    max={config.options[1]}
                    step={0.01}
                    onChange={(e, newValue) => handleSliderChange(e, newValue, i)}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                  />
                ) : (
                  <FormControl component="fieldset">
                    <FormGroup>
                      {config.options.map((op) => (
                        <FormControlLabel
                          key={op}
                          control={
                            <Checkbox
                              checked={config.values.some(v => v === op)}
                              onChange={() => handleCheckboxChange(op, i)}
                              name="values" />
                          }
                          label={op}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} justifyContent='flex-end'>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => addFilter()}
            type="button"
            data-cy='btn_eligibity'>
            + Add eligibity criteria
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SecondStep;
