// External library imports
import { useMutation, useQueryClient } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const updateScheme = ({ scheme, user_id }) =>
  axios.post(apiPaths.update_scheme, { scheme: scheme, user_id: user_id }, status200)
       .then((resp) => resp.data);

const useUpdateScheme = () => {
  const queryClient = useQueryClient();

  return useMutation((values) => updateScheme(values), {
    onSuccess: () => queryClient.resetQueries('schemes'),
    onError: (error) => error.message,
    onSettled: () => queryClient.invalidateQueries('schemes')
  });
};

export default useUpdateScheme;
