// External library imports
import * as React from 'react';
import {
  Grid, FormControl, Button, TextField, MenuItem, IconButton,
  Accordion, AccordionDetails, Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SortableContainer, SortableElement, SortableHandle } from 'react-18-compat-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

// Custom Hooks and Contexts
import { useTranslation } from '../../../../../providers/TranslationProvider';

// Utility imports
import { getVariableLabel } from '../../../../../../utils/utils';

// Style and asset imports
import {Item} from './styles/common';
import {
  AccordionSummary,
  deleteForeverTwoToneIconStyle,
  textFieldStyle
} from './styles/statistics';

const DragHandle = SortableHandle(() =>
  <span><DragHandleIcon style={{cursor: 'move'}}/></span>
);

const SortableList = SortableContainer(({
  statistics,
  handleChange,
  handleRemoveStatisticsFields,
  onChangeStatistic,
  allSections,
  expanded,
  columns,
  variables,
  getStatTypes,
  handleKeyDown,
  translations,
}) => (
  <Grid container>
    {statistics.map((stat, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        stat={stat}
        indexStat={index}
        handleChange={handleChange}
        handleRemoveStatisticsFields={handleRemoveStatisticsFields}
        onChangeStatistic={onChangeStatistic}
        allSections={allSections}
        expanded={expanded}
        columns={columns}
        variables={variables}
        getStatTypes={getStatTypes}
        handleKeyDown={handleKeyDown}
        t={translations}/>
    ))}
  </Grid>
))

const SortableItem = SortableElement(({
  stat,
  indexStat,
  handleChange,
  handleRemoveStatisticsFields,
  onChangeStatistic,
  allSections,
  expanded,
  columns,
  variables,
  getStatTypes,
  handleKeyDown,
  t,
}) => (
  <Accordion
    data-cy={stat.stat_var}
    expanded={expanded === indexStat}
    onChange={handleChange(indexStat)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header">
      <DragHandle/>
      <Typography>{getVariableLabel(variables, stat.stat_var)}</Typography>
      <IconButton size='small'
        onClick={(e) => handleRemoveStatisticsFields(e, indexStat)}>
        <DeleteForeverTwoToneIcon style={deleteForeverTwoToneIconStyle} />
      </IconButton>
    </AccordionSummary>
    <AccordionDetails>
      <Grid item xs={12}>
        <Item>
          <FormControl variant="outlined">
            <TextField
              select
              data-cy='select-stat-var'
              style={textFieldStyle}
              id="demo-simple-select-outlined"
              label="Select statistic column"
              value={stat.stat_var}
              onChange={(e) => onChangeStatistic(e, indexStat)}
              name="stat_var">
              {columns.map(statCol => (
                <MenuItem value={statCol} key={statCol} data-cy={'option-' + statCol}>{getVariableLabel(variables, statCol)}</MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              select
              style={textFieldStyle}
              id="demo-simple-select-outlined"
              label="Select section of histogram"
              value={stat.section}
              onChange={(e) => onChangeStatistic(e, indexStat)}
              name="section">
              {allSections.map(section => (
                <MenuItem value={section.value} key={section.value}>{section.text}</MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              select
              style={textFieldStyle}
              id="statistic"
              label={t('select_stat_type')}
              value={stat.statistic}
              onChange={(e) => onChangeStatistic(e, indexStat)}
              name="statistic">
              {getStatTypes(indexStat).map(type => (
                <MenuItem value={type} key={type}>{type}</MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              select
              style={textFieldStyle}
              id="demo-simple-select-outlined"
              label={t('show_pre_post_stat') || 'Show'}
              value={stat.show_pre_or_post}
              onChange={(e) => onChangeStatistic(e, indexStat)}
              name="show_pre_or_post">
              <MenuItem value={'both'}>both pre and post
                policies</MenuItem>
              <MenuItem value={'pre'}>only pre-policy</MenuItem>
              <MenuItem value={'post'}>only post-policy</MenuItem>
            </TextField>
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              style={textFieldStyle}
              label={t('stat_name')}
              name="label"
              value={stat.label}
              variant="outlined"
              onChange={(e) => onChangeStatistic(e, indexStat)}
              inputProps={{ maxLength: 80 }}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Item>
      </Grid>
    </AccordionDetails>
  </Accordion>
));

export default function Statistics({
  statistics,
  sections,
  columns,
  variables,
  onStatisticsChange
}) {
  const [expanded, setExpanded] = React.useState(false);

  const { t } = useTranslation();

  const getStatTypes = (index) => {
    if (index === 0) {
      return ['percentage_count', 'percentage_sum'];
    }
    return [
      'mean',
      'sum',
      'count',
      'percentage_sum',
      'percentage_count',
      'poverty_gap',
      'gini',
      'FGT1'
    ];
  }
  const onSortEnd = ({oldIndex, newIndex}) =>
    onStatisticsChange(arrayMoveImmutable(statistics, oldIndex, newIndex));

  const handleAddStatistics = () => {
    statistics.push({
      stat_var: null,
      section: null,
      statistic: null,
      label: null,
      show_pre_or_post: 'both',
    })

    onStatisticsChange(statistics)
  }
  const handleRemoveStatisticsFields = (e, statIndex) => {
    e.stopPropagation()
    statistics.splice(statIndex, 1)

    onStatisticsChange(statistics)
  }
  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);

  const onChangeStatistic = (e, index) => {
    statistics[index][e.target.name] = e.target.value
    onStatisticsChange(statistics)
  }

  const handleKeyDown = (event) => {
    const { value } = event.target;
    if (value.length >= 80 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const allSections = React.useMemo(() => {
    return sections
      .map((d) => {
        return {
          text: d.name,
          value: d.value
        };
      })
      .concat([
        { value: -1, text: t('current_selection'), isCustom: true }, // -1 is dynamic
        { value: -2, text: t('full_population'), isFull: true } // -2 is full
      ]);
  }, [sections])

  return (
    <>
      <SortableList onSortEnd={onSortEnd}
        statistics={statistics}
        handleChange={handleChange}
        handleRemoveStatisticsFields={handleRemoveStatisticsFields}
        onChangeStatistic={onChangeStatistic}
        allSections={allSections}
        expanded={expanded}
        columns={columns}
        variables={variables}
        getStatTypes={getStatTypes}
        handleKeyDown={handleKeyDown}
        translations={t}
        useDragHandle/>

      <Grid container justifyContent="flex-end" style={{ marginTop: 10 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddStatistics}
          type="button">
          + Add additional statistics
        </Button>
      </Grid>
    </>
  )
}
