// External library imports
import * as React from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
} from '@mui/material';
import { FormProvider } from 'react-hook-form';
import rewind from 'geojson-rewind';

// Internal component and function imports
import { useTranslation } from '../../../providers/TranslationProvider';
import { getRandomId } from '../../../../utils/formatter';
import uploadCollection, { getFilters } from '../../../../api/uploadCollection';
import Toast from '../../../common/Toast';

const Mapviz = ({ field, collectionName, shapeFileName }) => {
  const [uploadedGeoJsonFiles, setUploadedGeoJsonFiles] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const { t } = useTranslation();

  const uniqueCollection = () => {
    let fileName = uploadedGeoJsonFiles.length > 0 ? uploadedGeoJsonFiles[0].name : '';
    fileName = fileName.slice(0, fileName.lastIndexOf('.'));
    return `shapefile_${fileName.split(' ').join('_')}_${getRandomId()}`;
  };

  const upload = (data) => {
    let collection = uniqueCollection();
    return uploadCollection(data, collection)
      .then(() => {
        setOpenToast(true);
        shapeFileName(collection);
        setMessage({
          message: t('upload_successful'),
          severity: 'success',
        });
      })
      .catch((e) => {
        setOpenToast(true);
        setMessage({
          message: e.message,
          severity: 'error',
        });
      });
  };

  const handleFileUpload = () => {
    if (uploadedGeoJsonFiles[0] && uniqueCollection) {
      uploadedGeoJsonFiles[0]
        .text()
        .then((text) => {
          const geojson = JSON.parse(text);

          if (geojson.features) {
            const { features = [] } = rewind(geojson, true);

            if (features.length) {
              setUploading(true);
              getFilters({
                filter: field,
                collection: collectionName,
              })
                .then((resp) => {
                  const arr = resp.data.map((d) => d._id);
                  const names = features.map((d) => d.properties.name);

                  if (!arr.some((d) => names.indexOf(d) === -1)) {
                    upload(features).then(() => {
                      setUploading(false);
                    });
                  } else {
                    setUploading(false);
                    setOpenToast(true);
                    setMessage({
                      message:
                        'Shapefile does not have required properties. Name column of the shapefile should coincide with the categories of [segmentation variable name]',
                      severity: 'error',
                    });
                  }
                })
                .catch((e) => {
                  setUploading(false);
                  setOpenToast(true);
                  setMessage({
                    message: e.message,
                    severity: 'error',
                  });
                });
            }
          }
        })
        .catch((e) => {
          setOpenToast(true);
          setMessage({
            message: e.message,
            severity: 'error',
          });
        });
    }
  };
  const handleGeoJsonFileChange = (event) => setUploadedGeoJsonFiles([...event.target.files]);

  const handleCloseToast = () => {
    setOpenToast(false);
    setMessage(null);
  };

  return (
    <div>
      {message && (
        <Toast
          message={message.message}
          handleClose={handleCloseToast}
          severity={message.severity}
          open={openToast}
        />
      )}
      <FormProvider>
        <form>
          <Grid spacing={2} container>
            <Grid item xs={10}>
              <MuiTextField
                name='file'
                margin='dense'
                type='file'
                variant='outlined'
                inputProps={{ style: { height: 35 }, accept: '.geojson' }}
                onChange={handleGeoJsonFileChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={handleFileUpload}
                disabled={uploadedGeoJsonFiles.length === 0 || uploading}
                variant='contained'
                color='primary'
                startIcon={uploading && <CircularProgress size={20} color='secondary' />}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default Mapviz;
