// React and External Libraries
import * as React from 'react';
import {NavLink} from 'react-router-dom';

// Custom Hooks and Contexts
import {useTranslation} from '../providers/TranslationProvider';
import {useAuth} from 'components/providers/AuthProvider';

// Style and asset imports
import {
  breadBox,
  MuiAppsIcon,
  MuiBreadcrumbs,
  MuiGrid,
  MuiHomeIcon,
  MuiIconButton,
  MuiPersonIcon,
  MuiScenarioIcon,
  MuiSettingsIcon,
} from './styles/navigationMenu';
import {MuiTypography} from "./styles/global";
import {Box} from "@mui/material";

const NavigationMenu = ({ location, column: vertical }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const getLastElement = () => {
    if (
      location.pathname.split('/')[1] &&
      !location.pathname.split('/')[2] &&
      !location.pathname.split('/').includes('specification') &&
      !location.pathname.split('/').includes('tree')
    )
      return 0;
    else if (
      location.pathname.split('/')[2] &&
      !location.pathname.split('/').includes('specification') &&
      !location.pathname.split('/').includes('tree')
    )
      return 1;
    else if (location.pathname.split('/').includes('specification')) return 2;
    else if (location.pathname.split('/').includes('tree')) return 3;
  };

  return (
    <MuiGrid
      container
      direction={vertical ? 'column' : 'row'}
    >
      <MuiBreadcrumbs aria-label='breadcrumb'>
        {user && (user.type === 'super_admin' || user.type === 'admin' || user.orgs_user_level.length !== 0) && (
          <Box sx={breadBox}>
          <MuiIconButton
                component={NavLink}
                to={`${location.pathname.split('/')[0]}/orgs`}
                disableRipple
          >
                <MuiAppsIcon/>
            <MuiTypography variant="subtitle1" color='inherit' component="span" sx={{marginLeft:'8px'}}> {t('organizations')}</MuiTypography>
          </MuiIconButton>
          </Box>)}
        {getLastElement() >= 1 && location.pathname.split('/')[2] !== 'workspace' ? (
            getLastElement() === 1 ?
              (<Box sx={breadBox}>
                <MuiIconButton
                  component={NavLink}
                  to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                  disableRipple
                >
                  <MuiHomeIcon/>
                  <MuiTypography variant="subtitle1" color='inherit' component="span"> {t('organization')}</MuiTypography>
                </MuiIconButton>
              </Box>) :
              (<Box sx={breadBox}>
                <MuiIconButton
                  component={NavLink}
                  to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                  disableRipple
                >
                  <MuiHomeIcon/>
                  <MuiTypography variant="subtitle1" color='inherit' component="span"> {t('organization')}</MuiTypography>
                </MuiIconButton>
              </Box>)
        ) : (
          location.pathname.split('/')[2] === 'workspace' && (
              getLastElement() === 1 ?
                (<Box sx={breadBox}>
                  <MuiIconButton
                    component={NavLink}
                    to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                    disableRipple
                  >
                    <MuiPersonIcon/>
                    <MuiTypography variant="subtitle1" color='inherit' component="span"> {t('organization')}</MuiTypography>
                  </MuiIconButton>
                </Box> ) :
                (<Box sx={breadBox}>
                  <MuiIconButton
                    component={NavLink}
                    to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                    disableRipple
                  >
                    <MuiPersonIcon/>
                    <MuiTypography variant="subtitle1" color='inherit' component="span"> {t('organization')}</MuiTypography>
                  </MuiIconButton>
                </Box>)
          )
        )}
        {getLastElement() >= 2 && (user.type === 'super_admin' || user.type === 'admin') && (
            getLastElement() === 2  &&
              (<Box sx={breadBox}>
                  <MuiIconButton
                    component={NavLink}
                    to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios/${
                      location.pathname.split('/')[4]
                    }/specification`}
                    disableRipple
                  >
                    <MuiSettingsIcon/>
                    <MuiTypography variant="subtitle1" color='inherit' component="span"> {t('specification')}</MuiTypography>
                  </MuiIconButton>
              </Box>
              ))}
        {getLastElement() === 3 &&
            ( <Box sx={breadBox}>
              <MuiIconButton
              component={NavLink}
              to={`${location.pathname}`} disableRipple>
              <MuiScenarioIcon/>
              <MuiTypography variant="subtitle1" color='inherit' component="span"> {t('scenario')}</MuiTypography>
            </MuiIconButton>
            </Box>)}
      </MuiBreadcrumbs>
    </MuiGrid>
  );
};
export default NavigationMenu;
