// External library imports
import { max } from 'd3-array';
import { select } from 'd3-selection';
import { axisLeft, axisBottom } from 'd3-axis';
import { scaleLinear, scaleBand } from 'd3-scale';

// Internal component and function imports
import patternify from '../../utils/patternify.js';
import renderSlider from './section-slider';
import addSections from './add-sections-band';
import { colors } from '../../utils/colors';

export default function SimpleHistogram(params) {
  let width = params.width || 520,
    height = params.height || 250,
    margin = {
      top: 30,
      right: 15,
      bottom: 65,
      left: 65,
    };

  let data = params.data;

  let sections = params.sections || [];
  let title = params.fieldName;
  let container = params.container || document.body;
  let containerSelector = params.containerSelector || 'body';
  let xExtent = params.xExtent;
  let yExtent = params.yExtent;
  let histogramConfig = params.histogramConfig;
  // eslint-disable-next-line
  let currentValue = params.currentValue;
  let update;
  let svg;
  let chart;
  let sectionsGroup;
  let slider;
  // eslint-disable-next-line
  let sectionsDom;
  let x, y, chartHeight, chartWidth;
  let xAxis;
  let tickValues;
  let binSize = params.binSize;
  // eslint-disable-next-line
  let onUpdate = () => {};

  const main = () => {
    container = select(document.querySelector(containerSelector));

    // calculations
    chartWidth = width - margin.left - margin.right;
    chartHeight = height - margin.top - margin.bottom;

    let bands = data.map((d) => d.criteria).sort((a, b) => a - b);

    let each = 5;

    if (bands.length < 100) {
      each = 5;
    } else if (bands.length < 200) {
      each = 10;
    } else {
      each = 25;
    }

    tickValues = bands.filter((d) => d % each === 0);

    // scales
    x = scaleBand().domain(bands).range([0, chartWidth]);

    y = scaleLinear()
      .domain(params.yDomain || [0, max(data, (d) => d.sum)])
      .range([chartHeight, 0])
      .nice()
      .clamp(true);

    svg = patternify(container, 'svg', 'simple-histogram')
      .attr('width', width)
      .attr('height', height);

    // drawing
    chart = patternify(svg, 'g', 'histogram')
      .attr('pointer-events', 'none')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('opacity', params.visible ? 1 : 0);

    addCropArea();

    sectionsGroup = patternify(chart, 'g', 'sections-group');

    patternify(chart, 'text', 'x-axis-title')
      .attr(
        'transform',
        `translate(
                ${chartWidth / 2}, ${chartHeight + margin.bottom / 2 + 16}
            )`
      )
      .style('text-anchor', 'middle')
      .text(title);

    xAxis = patternify(chart, 'g', 'x-axis')
      .attr('transform', `translate(0, ${chartHeight})`)
      .call(
        axisBottom(x)
          .tickPadding(12)
          .tickValues(tickValues)
          .tickFormat((domain) => {
            if (!histogramConfig.xLabel) {
              return '';
            } else {
              return domain;
            }
          })
      );

    let yAxis = patternify(chart, 'g', 'y-axis').call(axisLeft(y).ticks(5));

    yAxis.selectAll('path').remove();
    xAxis.selectAll('path').remove();
    xAxis.selectAll('line').remove();

    chart
      .selectAll('rect.bar')
      .data(data)
      .join('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x(d.criteria) + 0.5)
      .attr('y', (d) => y(d.sum))
      .attr('width', () => {
        const w = x.bandwidth();
        if (w >= 2) {
          return w + ((binSize - 1) * 5) + (binSize - 2)
        }
        return w;
      })
      .attr('height', (d) => y(0) - y(d.sum))
      .attr('fill', colors.notAccepted);

    update = () => {};

    slider = renderSlider({
      container: chart,
      value: 0,
      visible: true,
      x: 0,
      y: chartHeight + 8,
      width: chartWidth,
      sections: sections,
      scale: x,
      showSliderLabel: histogramConfig.sliderLabel,
    }).onChange(() => {
      const [min, max] = xExtent;
      sections.forEach((d) => {
        d.value = Math.max(min, Math.min(max, d.value));
      });

      params.fnSections(sections);

      sectionsDom = addSections({
        chart: sectionsGroup,
        sections,
        xScale: x,
        height: height - 20,
        margin,
        translateY: -20,
      });
    });

    if (sections.length) {
      sectionsDom = addSections({
        chart: sectionsGroup,
        sections,
        xScale: x,
        height: height - 20,
        margin,
        translateY: -20,
      });
    }

    return main;
  }

  function addCropArea() {
    let [xMin, xMax] = xExtent;

    let cropData = {
      x1: x(xMin),
      x2: x(xMax) + x.bandwidth() ? x(xMax) + x.bandwidth() : chartWidth,
      y: yExtent ? y(yExtent[1]) : 0,
    };

    chart
      .selectAll('rect.boundary')
      .data([cropData])
      .join('rect')
      .attr('class', 'boundary')
      .attr('x', (d) => d.x1)
      .attr('y', (d) => (d.y ? d.y : 0))
      .attr('width', (d) => d.x2 - d.x1)
      .attr('height', (d) => chartHeight - d.y + 1)
      .attr('fill', '#f8f8f8');
  }

  main.updateSections = (_sections) => {
    sections = _sections;

    sectionsDom = addSections({
      chart: sectionsGroup,
      sections,
      xScale: x,
      height: height - 20,
      margin,
      translateY: -20,
    });

    slider.update(sections);
  };

  main.updateXAxisExtent = (min, max) => {
    if (min) {
      xExtent[0] = min;
    }

    if (max) {
      xExtent[1] = max;
    }

    addCropArea();
  };

  main.updateYAxisExtent = (min, max) => {
    if (min) {
      yExtent[0] = min;
    }

    if (max) {
      yExtent[1] = max;
    }

    addCropArea();
  };

  main.update = (value) => {
    currentValue = value;
    update();
    return main;
  };

  main.onUpdate = (f) => {
    onUpdate = f;
    return main;
  };

  main.show = () => {
    params.visible = true;
    chart
      .attr('opacity', params.visible ? 1 : 0)
      .attr('pointer-events', params.visible ? 'all' : 'none');
  };

  main.hide = () => {
    params.visible = false;
    chart
      .attr('opacity', params.visible ? 1 : 0)
      .attr('pointer-events', params.visible ? 'all' : 'none');
  };

  main.getSlider = () => slider;

  main.getXaxis = () => xAxis;

  main.showHideXAxis = (value) => {
    histogramConfig.xLabel = value;
    xAxis.call(
      axisBottom(x)
        .tickPadding(12)
        .tickValues(tickValues)
        .tickFormat((domain) => {
          if (!histogramConfig.xLabel) {
            return '';
          } else {
            return domain;
          }
        })
    );
    xAxis.selectAll('path').remove();
    xAxis.selectAll('line').remove();
  };

  main.resizeChart = (width) => {};

  return main();
}
