// External library imports
import * as React from 'react';
import Button from '@mui/material/Button';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { Button as MuiButton } from '@mui/material';

// Internal component and function imports
import { Card } from '../dragdrop/Card';
import { useTranslation } from '../../../../../providers/TranslationProvider';
import { getVariableLabel } from '../../../../../../utils/utils';
import Modal from 'components/common/Modal';

export default function ModalDialogDragDrop({ variables, segmentationVariables, onDragAndDrop }) {
  const [open, setOpen] = React.useState(false);
  const [ segmentationVariablesLocal, setSegmentationVariablesLocal ] = React.useState([])

  const { t } = useTranslation()

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = () => {
    onDragAndDrop(segmentationVariablesLocal)
    handleClose()
  }

  const moveCard = React.useCallback((dragIndex, hoverIndex) => {
    setSegmentationVariablesLocal((prevSegmentation) =>
      update(prevSegmentation, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSegmentation[dragIndex]]
        ]
      })
    )
  }, [])

  const renderCard = React.useCallback((variable, index) => {
    return (
      <Card
        key={variable + index}
        index={index}
        id={variable}
        text={getVariableLabel(variables, variable)}
        moveCard={moveCard}
      />
    )
  }, [])

  React.useEffect(() => {
    setSegmentationVariablesLocal(segmentationVariables)
  }, [])

  const actions = (
    <>
      <MuiButton onClick={handleClose} color="primary" variant="text">
        {t('cancel_btn')}
      </MuiButton>
      <Button onClick={handleSubmit}>
        Save
      </Button>
    </>
  );

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" style={{ marginLeft: 6 }}>{t('segmentation_reoder_button')}</Button>
      <Modal
        open={open}
        title={t('segmentation_reorder_modal_title')}
        actions={actions}
        onClose={handleClose}>
        <DndProvider backend={HTML5Backend}>
          <div>
            {segmentationVariablesLocal.map((variable, i) => renderCard(variable, i))}
          </div>
        </DndProvider>
      </Modal>
    </div>
  );
}
