import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    forceOAuthLogin: false, // enable for an initial Login screen
    userTranslations: {},
    requestPolicies: [],
    globalLoading: false,
    policiesToAggregate: [],
    targetCollection: null,
    targetScheme: {},
    activePoliciesTab: null,
  },
  reducers: {
    setPrivateLoading: (state, action) => {
      state.privateLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setUserTranslations: (state, action) => {
      const { lng, allTranslations } = action.payload;
      let translations = {};

      if (allTranslations) {
        for (const translation of allTranslations) {
          translations[translation.key] = translation[lng];
        }
      }

      state.userTranslations = translations;
    },
    setRequestPolicies: (state, action) => {
      state.requestPolicies = action.payload;
    },
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
    setPoliciesToAggregate: (state, action) => {
      state.policiesToAggregate = action.payload;
    },
    setTargetCollection: (state, action) => {
      state.targetCollection = action.payload;
    },
    setTargetScheme: (state, action) => {
      state.targetScheme = action.payload;
    },
    setTargetSchemeProperty: (state, action) => {
      state.targetScheme = { ...state.targetScheme, [action.payload.key]: action.payload.value }
    },
    setActivePoliciesTab: (state, action) => {
      state.activePoliciesTab = action.payload
    }
  },
});

export default slice.reducer;

export const setError = (payload) => ({ type: 'app/setError', payload });

export const setPrivateLoading = (payload) => ({ type: 'app/setPrivateLoading', payload });

export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});

export const setUserTranslations = (payload) => ({
  type: 'app/setUserTranslations',
  payload,
});

export const setRequestPolicies = (payload) => ({
  type: 'app/setRequestPolicies',
  payload,
});

export const setGlobalLoading = (payload) => ({
  type: 'app/setGlobalLoading',
  payload,
});

export const setPoliciesToAggregate = (payload) => ({
  type: 'app/setPoliciesToAggregate',
  payload,
});

export const setTargetCollection = (payload) => ({
  type: 'app/setTargetCollection',
  payload,
})

export const setTargetScheme = (payload) => ({
  type: 'app/setTargetScheme',
  payload,
})

export const setTargetSchemeProperty = (payload) => ({
  type: 'app/setTargetSchemeProperty',
  payload,
})

export const setActivePoliciesTab = (payload) => ({
  type: 'app/setActivePoliciesTab',
  payload,
})
