// External library imports
import * as React from 'react';
import {useLocation} from 'react-router-dom';

// Internal component and function imports
import useDeleteUser from 'api/hooks/useDeleteUser';
import {useTranslation} from 'components/providers/TranslationProvider';
import ModalDelete from '../common/ModalDelete';

const DeleteUser = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const deleteMutation = useDeleteUser();

  const handleSubmit = () => deleteMutation.mutateAsync(state);

  return (
    <ModalDelete
      deleteMutation={deleteMutation}
      onSubmit={handleSubmit}
      content={t('delete_user_content')}
      title={t('delete_user')}
      name={state?.email}
    />
  );
};

export default DeleteUser;
