// External library imports
import * as React from 'react';
import {IconButton} from '@mui/material';
import FileDownload from "@mui/icons-material/FileDownload";

const DownloadIcon = ({ onClick, className }) => (
  <IconButton aria-controls="edit-button" color='primary' className={className} onClick={onClick}>
    <FileDownload />
  </IconButton>
);

export default DownloadIcon;
