// External library imports
import axios from 'axios';

// Internal component and function imports
import apiPaths from './apiPaths';

const changePassword = ({ user, password, accessToken }, onError, onSuccess) => {
  const { REACT_APP_API_URL } = process.env;
  const url = `${REACT_APP_API_URL}${apiPaths.changePassword}`;

  axios.post(url,{ password: password, user: user },
      {
        headers: {
          Authorization: `Basic ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((resp) => onSuccess(resp.data))
    .catch((e) => onError(e.error));
};

export default changePassword;
