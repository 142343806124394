// External library imports
import * as React from 'react';
import { Grid } from '@mui/material';
import { extent } from 'd3-array';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Data and Chart imports
import {
  loadPostPolicyData,
  loadTotalPopulationBins
} from '../../../../charts/shared/data';
import Tree from './Tree';
import StackedBar from './StackedBar';

// Utility imports
import { getMatchArray } from '../../../../../utils/chart';
import { mergeBins } from '../../../../../utils/array';
import { useLoading } from '../../../../providers/LoadingProvider';

// Style and asset imports
import {firstGridItemStyle, secondGridItemStyle} from "../styles/treePage";


const TreePage = React.forwardRef(
  ({ activeTab, index, tabData, chartView, onTreeUpdate, aggrTreeUpdateEnabled, setAggrTreeUpdateEnabled, setAllTabRoots },
   ref
  ) => {
  const [binsData, setBinsData] = React.useState([]);
  const [nodes, setNodes] = React.useState([]);
  const [enabledTransition, setEnabledTransition] = React.useState(false);

  const { setIsLoading } = useLoading();
  const requestPolicies = useSelector(state => state.app.requestPolicies)
  const { enqueueSnackbar } = useSnackbar()

  React.useImperativeHandle(ref, () => ({
    updateAggrTree() {
      setAggrTreeUpdateEnabled(true)
    },
  }))

  const isAggrTree = React.useMemo(() => {
    return tabData.type === 'aggregation';
  }, [tabData]);

  const onNodesUpdate = (currentNodes) => setNodes(currentNodes);

  React.useEffect(() => {
    if (!tabData.config.tree_state||Object.keys(tabData.config.tree_state).length===0) {
      setIsLoading(true)
      loadTotalPopulationBins(tabData.config).then((res) => {
        setIsLoading(false);
        setBinsData(res);

        const _ext = extent(res, (d) => d.criteria);
        const priority = tabData.config.criteriaPriority;

        if (isAggrTree) {
          tabData.config.defaultThresholdValue = priority === 'low' ? _ext[1] : _ext[0];
        } else {
          tabData.config.defaultThresholdValue = priority === 'low' ? _ext[0] - 1 : _ext[1] + 1;
        }
      }).catch(() => {
        enqueueSnackbar('Some error in bins request', {
          variant: 'error',
          preventDuplicate: true,
        })
        setIsLoading(false)
      });
    }
  }, []);

  React.useEffect(() => {
    if (activeTab === index) {
      if (nodes.length > 0) {
        setIsLoading(true)
        let promises = [];

        for (const node of nodes) {
          promises.push(loadPostPolicyData(
            node,
            node.data.value,
            tabData.config,
            requestPolicies
          ));
        }

        Promise.all(promises).then((dataArray) => {
          for (const [key, node] of Object.entries(nodes)) {
            node.data.bins = mergeBins(
              node.data.__original_data,
              dataArray[key],
              tabData.config.numericalFilters[tabData.config.criteria[0]]
            );
            node.data.leafNode.updateHistogram(node.data.bins);
          }
        }).finally(() => {
          setIsLoading(false)
        })
      }
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (!isAggrTree) {
      onTreeUpdate(nodes.map((node) => {
        const data = node.data;
        const match = getMatchArray(node) || [];
        const regime = node.data.regime;
        const affected_variables = regime.effects.map(d => {
          const effect = d.avEffect;
          return {
            name: d.variable,
            formula: effect.type === 'factor' ? `"${effect.value}" * $amount$` : effect.value
          }
        }).filter(d => d);

        return {
          subset: match,
          params: {
            amount: data.amount,
            threshold: data.value,
          },
          affected_variables,
        }
      }));
    }
    setEnabledTransition(false)
  }, [nodes])
  return (
    <div
      role='tabpanel'
      hidden={activeTab !== index}
      id={`simple-tabpanel-${tabData.id}`}
      aria-labelledby={`simple-tab-${index}`}>
      {((tabData.config.tree_state&&Object.keys(tabData.config.tree_state).length>0) || binsData.length > 0) && (
        <Grid container spacing={0}>
          <Grid item xs={11} style={firstGridItemStyle}>
            <Tree
              treeConfiguration={JSON.parse(JSON.stringify(tabData.config))}
              initialData={JSON.parse(JSON.stringify(binsData))}
              onNodesUpdate={(updatedNode) => onNodesUpdate(updatedNode)}
              aggrTreeUpdateEnabled={aggrTreeUpdateEnabled}
              setAggrTreeUpdateEnabled={setAggrTreeUpdateEnabled}
              tabId={tabData.id}
              setAllTabRoots={setAllTabRoots}
              setEnabledTransition={setEnabledTransition}
            ></Tree>
          </Grid>
          <Grid item xs={1} style={secondGridItemStyle}>
            {chartView.type === 'tree' && (
              <StackedBar
                tabId={tabData.id}
                nodes={nodes}
                budget={tabData.config.budget}
                treeType={tabData.config.type}
                costBarTitle={tabData.config.cost_bar_title}
                enabledTransition={enabledTransition}
              ></StackedBar>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
});

export default TreePage;
