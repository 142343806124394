// React and External Libraries
import * as React from 'react';

const LoadingContext = React.createContext();

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const value = {isLoading, setIsLoading}

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => React.useContext(LoadingContext);
export { LoadingContext, useLoading };
export default LoadingProvider
