// External library imports
import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  ThemeProvider,
  Typography
} from '@mui/material';

// Internal component and function imports
import {useTheme} from 'components/providers/CustomThemeProvider';

// Style and asset imports
import themeCriteria from "../../theme/criteria";
import {
  DialogStyle,
  MuiDialogActions,
  TypographyStyle
} from './styles/modal';

const DialogTitle = (
  ({ children, classes, onClose, isDelete, ...props }) => (
    <MuiDialogTitle {...props} >
      <Typography sx={TypographyStyle} className={isDelete}>
        {children}
      </Typography>
    </MuiDialogTitle>
  )
);

const Modal = ({ open, onClose, title, children, actions, isDelete, maxWidth }) => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={themeCriteria}>
      <Dialog
        sx={DialogStyle}
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : 'xs'}
        fullWidth
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        { title && (
          <DialogTitle onClose={onClose} isDelete={isDelete} id='scroll-dialog-title'>
            {title}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        <ThemeProvider theme={theme}>
          <MuiDialogActions>{actions}</MuiDialogActions>
        </ThemeProvider>
      </Dialog>
    </ThemeProvider>
  );
};

export default Modal;
