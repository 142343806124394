import {styled} from "@mui/material";
import MuiAccordionSummary from '@mui/material/AccordionSummary';

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  justifyContent: 'space-between',
}));

export const textFieldStyle = {
  margin: '5px'
}

export const deleteForeverTwoToneIconStyle = {
  color: '#fd7676'
}
