// External library imports
import * as React from 'react';
import { IconButton } from '@mui/material';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import {DeleteOutlined} from "@mui/icons-material";

const DeleteIcon = ({ onClick, className }) => (
  <IconButton
    className={className}
    onClick={onClick}
    data-cy='table_common_delete_action'
  >
    <DeleteOutlined style={{color:'#F63B2B'}}/>
  </IconButton>
);

export default DeleteIcon;
