// External library imports
import * as React from 'react';
import { Button as MuiButton } from '@mui/material';
import { FormProvider } from 'react-hook-form';

// Internal component and function imports
import Modal from 'components/common/Modal';
import { useTranslation } from '../../../providers/TranslationProvider';
import Sections from './Sections';
import Mapviz from './Mapviz';

const MoreModal = ({open, onClose, onSave, field, collectionName, weightField }) => {
  const [shapeFileName, setShapeFileName] = React.useState(null);
  const [sectionData, setSectionData] = React.useState({
    sections: [],
    xExtent: [],
    yExtent: [],
    histogramConfiguration: {},
  });

  const { t } = useTranslation();

  const handleSave = () => {
    onSave({
      sections: sectionData.sections,
      shapeFile: shapeFileName,
      xExtent: sectionData.xExtent,
      yExtent: sectionData.yExtent,
      histogramConfig: sectionData.histogramConfiguration,
    });
  };

  const actions = (
    <>
      <MuiButton
        onClick={() => handleSave()}
        color='primary'
        variant='contained'
        data-cy='save_more_settings'
      >
        {t('save_generic_btn')}
      </MuiButton>
      <MuiButton
        onClick={() => onClose()}
        color='primary'
        variant='outlined'
        data-cy='cancel_more_settings'
      >
        {t('cancel_btn')}
      </MuiButton>
    </>
  );

  return (
    <FormProvider>
      <Modal
        open={open}
        onClose={() => onClose()}
        title={t('title_configuration')}
        maxWidth={'lg'}
        actions={actions}
      >
        {field.category === 'numeric' ? (
          <Sections
            propName={field.propName}
            weightField={weightField}
            collectionName={collectionName}
            sectionData={setSectionData}
          ></Sections>
        ) : (
          <Mapviz field={field} collectionName={collectionName} shapeFileName={setShapeFileName} />
        )}
      </Modal>
    </FormProvider>
  );
};

export default MoreModal;
