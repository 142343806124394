// External library imports
import { useQueryClient, useMutation } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from 'api/apiPaths';

const deleteDataset = async ({ name, user_id } ) => {
  await axios.post(apiPaths.delete_scenario, { collections: [name], user_id: user_id }, status200)
             .then((resp) => resp.data);
};

const useDeleteDataset = () => {
  const queryClient = useQueryClient();

  return useMutation(async (values) => await deleteDataset(values), {
    onSuccess: () => queryClient.resetQueries(['schemes']),
    onError: (error) => error,
    onSettled: () => queryClient.invalidateQueries(['schemes'])
  });
};

export default useDeleteDataset;
