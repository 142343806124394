// External library imports
import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

// Internal component and function imports
import {useTranslation} from 'components/providers/TranslationProvider';
import {usePermissions} from 'components/hooks/usePermissions';

// Style and asset imports
import {MuiButtonSpacing} from './styles/scenario';

const NewScenario = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {usePermissions('create_schemes') && (
        <MuiButtonSpacing
          variant='outlined'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => navigate(`${location.pathname}/create/specification`)}
          data-cy='btn_new_scenario'
        >
          {t('new_scenario')}
        </MuiButtonSpacing>
      )}
    </>
  );
};
export default NewScenario;
