import {DialogActions, styled, Typography} from '@mui/material';

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  textAlign:'left',
  marginBottom:'5px',
  fontWeight:500,
  fontStyle:'normal',
  fontFamily:'Raleway',
  color:'#212121'
}));

export const MuiDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '26px 16px',
}));

export const TypographyStyle = {
  flexGrow: 1,
  textAlign: 'left',
  fontFamily: 'Raleway',
  fontSize:'25px'
}

export const DialogStyle = {
  width: 'auto',
  margin: 'auto',
  borderTop:'none',
  '& .MuiPaper-root': {
    borderRadius: '28px !important',
  },
}
