// External library imports
import React, { Fragment } from 'react';
import {
  Grid, Accordion, AccordionDetails, AccordionSummary, Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';

// Internal component and function imports
import Sections from '../../datasets/Sections';


const FourStep = ({ schemeCollectionConfig, collectionName, weightField, setSchemeCollectionConfig }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [schemeConfigs, setSchemeConfigs] = React.useState([])
  const [sectionData, setSectionData] = React.useState({
    sections: [],
    xExtent: [],
    yExtent: [],
    histogramConfiguration: {
      binSize: 1,
      xLabel: false,
      sliderLabel: true,
    },
  });

  const targetCollection = useSelector(state => state.app.targetCollection)

  const getSectionsByVariable = (varName) => {
    let schemeConfiguration = null

    if (schemeCollectionConfig.length > 0) {
      schemeConfiguration = schemeCollectionConfig.find((config) => config.variable === varName)
    }

    let section = schemeConfiguration && schemeConfiguration.sections && schemeConfiguration.sections.length > 0
      ? { sections: schemeConfiguration.sections }
      : targetCollection.sections.find(s => s.variable === varName)

    return section && section.sections && section.sections.length > 0 ? section.sections : []
  }
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    let configs = [ ...schemeCollectionConfig ]
    let configIndex = configs.findIndex(config => config.variable === sectionData.variable)
    configs[configIndex] = {
      ...configs[configIndex],
      binSize: sectionData.histogramConfiguration.binSize,
      sections: sectionData.sections,
      variable: sectionData.variable,
      xExtent: sectionData.xExtent,
      yExtent: sectionData.yExtent,
      sliderLabel: sectionData.histogramConfiguration.sliderLabel,
      xLabel: sectionData.histogramConfiguration.xLabel,
    }
    setSchemeCollectionConfig(configs)
  }, [sectionData])

  React.useEffect(() => {
    setSchemeConfigs(schemeCollectionConfig)
  }, [schemeCollectionConfig]);

  return (
    <Fragment>
      <Grid container spacing={2} noValidate sx={{marginTop:'10px', marginBottom:'10px'}}>
        {schemeConfigs.map((config, index) => (
          <Accordion key={config.variable + '-' + index}
            style={{width: '100%', marginLeft:'15px'}}
            expanded={expanded === config.variable}
            onChange={handleChange(config.variable)}
            data-cy={`accordion_${index}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography>{config.variable}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === config.variable && (
                <Sections
                  propName={config.variable}
                  weightField={weightField}
                  collectionName={collectionName}
                  sectionData={setSectionData}
                  showAddSectionBlock={false}
                  schemeConfiguration={config}
                  currentSections={getSectionsByVariable(config.variable)}
                  gridSizes={{othersLabel: 3, sliderLabel: 5}}>
                </Sections>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Fragment>
  );
};

export default FourStep;
