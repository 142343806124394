// External library imports
import { arc, pie } from 'd3-shape';
import { select, selectAll } from 'd3-selection';
import tippy from 'tippy.js';

// Internal component and function imports
import patternify from '../../../utils/patternify.js';
import roundNumber from '../../../utils/round-number';
import { colors } from '../../../utils/colors';
import { hexToRgb, parseRgb } from '../../../utils/color-codes';

const renderPie2Level = (params) => {
  let container = params.container;
  let pieGroup, pieTitle, slices, outlineCircle, path, texts;
  let translations = params.translations;
  let datum = container.datum();

  const attrs = {
    innerRadius: params.pieInnerRadius || 20,
    outerRadius: params.pieOuterRadius || 70,
  };
  let pieValue = params.value;
  let posPietValue = params.value;
  let pieData = generatePieData(pieValue);

  const arcGenerator = (rInner, rOuter) => {
    return arc().innerRadius(rInner).outerRadius(rOuter);
  };

  const pieGenerator = pie()
    .sort(null)
    .startAngle(0 - Math.PI / 2)
    .endAngle(Math.PI * 1.5)
    .value((d) => d.value);

  let interval = null;

  let onPieClick = () => {};
  let onPieMouseover = () => {};
  let onPieMouseOut = () => {};

  function main() {
    pieGroup = patternify(container, 'g', 'pie-chart-group').attr(
      'transform',
      `translate(${params.translation})`
    );

    pieTitle = patternify(pieGroup, 'text', 'pie-label')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('font-size', '10px')
      .attr('fill', '#000')
      .attr('opacity', 0)
      .attr('y', -attrs.outerRadius - 6)
      .text(params.title);

    slices = patternify(pieGroup, 'g', 'slices')
      .attr('cursor', 'pointer')
      .style("filter", "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4))")
      .on('click', () => {
        onPieClick();
      })
      .on('mouseover', () => {
        main.highlight(false);
        onPieMouseover();
      })
      .on('mouseout', () => {
        main.clearHighlight();
        onPieMouseOut();
      });

    outlineCircle = patternify(slices, 'circle', 'stroke-circle')
      .attr('r', attrs.outerRadius + 1)
      .attr('fill', 'transparent')
      .attr('stroke', colors.highlight)
      .attr('stroke-width', 2)
      .attr('opacity', 0);

    drawArcs();

    return main;
  }

  function drawArcs() {
    let layers = patternify(slices, 'g', 'layer', pieData);

    let arcs = patternify(layers, 'g', 'arc', (d) =>
      pieGenerator(d.data).map((x) => {
        const _arc = arcGenerator(d.rInner, d.rOuter);

        return {
          ...x,
          _arc,
          d: _arc(x),
        };
      })
    );

    path = patternify(arcs, 'path', 'arc-path', (d) => [d])
      .attr('d', (d) => d.d)
      .attr('fill', (d) => d.data.color);

    texts = patternify(arcs, 'text', 'arc-text', (d) => [d])
      .attr('transform', function (d) {
        return 'translate(' + d._arc.centroid(d) + ')';
      })
      .attr('dy', '0.35em')
      .attr('opacity', (d) => (d.data.hasTextLabel ? 1 : 0))
      .attr('font-size', '9px')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('fill', (d) => d.data.textColor)
      .text((d) => roundNumber(d.data.value, 1));

    arcs.each(function (d) {
      if (this._tippy) {
        this._tippy.destroy(true);
      }

      tippy(this, {
        theme: 'light-border',
        arrow: true,
        delay: 0,
        content: d.data.suffixText + ' ' + roundNumber(d.data.value, 1) + '%',
        placement: 'right',
      });
    });

    patternify(slices, 'text', 'center-text')
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('pointer-events', 'none')
      .attr('font-weight', 600)
      .text(roundNumber(posPietValue, 1) + '%');
  }

  function generatePieData(value) {
    const preValue = value[0];
    const postValue = value[1];
    const greyvalue = 100 - preValue;
    const postValue1 = preValue - postValue;
    posPietValue = postValue;

    const rgb = hexToRgb(params.node.color);
    const textColor = params.node.colorPercent < 0.7 ? '#fff' : '#000';
    const prePostNotSame = roundNumber(preValue, 1) !== roundNumber(postValue, 1);

    const layer1 = [
      {
        suffixText: translations['pre_policy'],
        value: preValue,
        color: params.node.color,
        changesColor: true,
        hasTextLabel: preValue > 5 && prePostNotSame,
        textColor,
      },
      {
        suffixText: '',
        value: greyvalue,
        color: colors.notAccepted,
      },
    ];

    const layer2 = [
      {
        suffixText: translations['post_policy'],
        value: postValue,
        color: params.node.color,
        changesColor: true,
        hasTextLabel: postValue > 3.5 && prePostNotSame,
        textColor: textColor,
      },
      {
        suffixText: translations['pie_chart_difference'],
        value: postValue1,
        changesColor: true,
        opacity: 0.6,
        color: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`,
        hasTextLabel: postValue1 > 5,
        textColor: '#000',
      },
      {
        suffixText: '',
        value: greyvalue,
        color: colors.notAccepted,
      },
    ];

    const outerSlice = attrs.outerRadius - attrs.innerRadius;

    return [
      {
        rInner: attrs.innerRadius,
        rOuter: attrs.innerRadius + outerSlice / 2 - 0.5,
        data: layer1.filter((d) => d.value),
      },
      {
        rInner: attrs.innerRadius + outerSlice / 2,
        rOuter: attrs.outerRadius,
        data: layer2.filter((d) => d.value),
      },
    ];
  }

  main.updatePieColor = function (color, textColor) {
    if (color) {
      params.node.color = color;
    }

    let c = params.node.color;
    let rgb = c.slice(0, 3) === 'rgb' ? parseRgb(c) : hexToRgb(c);

    path
      .each((d) => {
        if (d.data.changesColor) {
          if (d.data.opacity) {
            d.data.color = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${d.data.opacity})`;
          } else {
            d.data.color = params.node.color;
          }
        }
      })
      .attr('fill', (d) => d.data.color);

    if (textColor) {
      texts.attr('fill', textColor);
    }
  };

  main.getData = () => pieValue;

  main.update = (value) => {
    pieValue = value;
    pieData = generatePieData(pieValue);
    drawArcs();
    return main;
  };

  main.translate = (translation) => {
    params.translation = translation;
    pieGroup.transition().duration(750).attr('transform', `translate(${params.translation})`);
  };

  main.translateYBy = (dy) => {
    pieGroup
      .transition()
      .duration(750)
      .attr('transform', `translate(${params.translation[0]}, ${params.translation[1] + dy})`);
  };

  main.highlight = (animate = true) => {
    outlineCircle.attr('opacity', 1);
    slices.style("filter", "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))");

    let i = 0;

    if (animate) {
      animateFunc();

      interval = setTimeout(() => {
        animateFunc();
      }, 500);
    }

    function animateFunc() {
      slices
        .transition()
        .duration(500)
        .attr('transform', `translate(0, 0) scale(${i % 2 === 0 ? 1.08 : 1})`)
        .on('end', function () {
          if (!interval) {
            slices.attr('transform', `translate(0, 0) scale(1)`);
          }
        });
      i++;
    }

    let d = datum;

    while (d.parent) {
      select(`#link-${d.data.id}-${d.parent.data.id}`)
        .raise()
        .attr('stroke', colors.highlight)
        .attr('stroke-width', 2);

      d = d.parent;
    }

    const stack = select('#stack-' + params.id);

    if (!stack.empty()) {
      const d = stack.datum();

      const tooltip = stack.node()._tippy;

      if (tooltip) tooltip.show();

      stack
        .raise()
        .select('rect')
        .attr('stroke', d.scaledVal < 0.5 ? colors.highlightLight : colors.highlight)
        .attr('stroke-width', 2);
    }

    return main;
  };

  main.clearHighlight = () => {
    outlineCircle.attr('opacity', 0);
    slices.style("filter", "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4))");

    if (interval) {
      clearInterval(interval);
      interval = null;
    }

    selectAll('.link').attr('stroke', colors.linkColor).attr('stroke-width', null);

    const stack = select('#stack-' + params.id);

    if (!stack.empty()) {
      const tooltip = stack.node()._tippy;

      if (tooltip) tooltip.hide();

      stack.select('rect').attr('stroke', null).attr('stroke-width', null);
    }

    return main;
  };

  main.onPieClick = (f) => {
    onPieClick = f;
    return main;
  };

  main.onPieMouseover = (f) => {
    onPieMouseover = f;
    return main;
  };

  main.onPieMouseOut = (f) => {
    onPieMouseOut = f;
    return main;
  };

  main.showTitle = () => pieTitle.attr('opacity', 1);

  main.hideTitle = () => pieTitle.attr('opacity', 0);

  return main();
}

export default renderPie2Level;
