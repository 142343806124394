import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

import { usePermissions } from 'components/hooks/usePermissions';
import {
  divActionStyle,
  gridNameStyle,
  MuiCardContent,
  MuiCardMedia,
  MuiCardRoot, MuiGridActions,
  MuiIconButtonDelete,
  MuiIconButtonEdit,
  MuiLink, MuiTypography
} from './styles/card';
import {CardMedia, Grid} from '@mui/material';
import {DeleteOutlined} from "@mui/icons-material";


const CardOrganization = ({ organization }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  return (
    <>
      <MuiCardRoot elevation={0}>
        <CardMedia>
          <MuiLink
            to={`/orgs/${organization._id}/scenarios`}
            data-cy={organization.name}
          >
            <MuiCardMedia
              image={organization.attrs.profile_img_url}
              title={organization.name}
            />
          </MuiLink>
        </CardMedia>
        <MuiCardContent>
          <Grid container item direction={'row'}>
            <Grid item xs={8} style={gridNameStyle}>
              <MuiTypography variant='subtitle1' component='h2'>
                {organization.name}
              </MuiTypography>
            </Grid>
            <MuiGridActions item xs={2} hasDeletePermission={hasPermissionFor('delete_org')}>
              <div style={divActionStyle}>
                {hasPermissionFor('update_org') && (
                  <MuiIconButtonEdit
                    onClick={() =>
                      navigate(`${location.pathname}?edit=${organization._id}`, {
                        replace: true,
                        state: organization
                      })
                    }
                    color='primary'
                    data-cy={`edit_button_org_${organization.name}`}
                  >
                    <EditIcon fontSize='small' />
                  </MuiIconButtonEdit>
                )}
                {hasPermissionFor('delete_org') && (
                  <MuiIconButtonDelete
                    onClick={() =>
                      navigate(`${location.pathname}?delete=${organization._id}`, {
                        replace: true,
                        state: organization
                      })
                    }
                    data-cy={`delete_card_${organization._id}`}
                  >
                    <DeleteOutlined style={{color:'#F63B2B'}}/>
                  </MuiIconButtonDelete>
                )}
              </div>
            </MuiGridActions>
          </Grid>
        </MuiCardContent>
      </MuiCardRoot>
    </>
  );
};

export default CardOrganization;
