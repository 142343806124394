import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {InputBase, Select, styled} from "@mui/material";

export const MuiSelect = styled(Select)(({ theme }) => ({
  // color: 'black!important',
}));

export const MuiExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  // color: 'black !important',
  marginLeft: theme.spacing(-3.5),
  fontsize: '14px',
}));

export const MuiInputBase = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  borderRadius: 4,
  position: 'relative',
  fontSize: 14,
  padding: '5px 10px 5px 5px',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  color: 'black',
  // Use the system font instead of the default Roboto font.
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:focus': {
    borderRadius: 4,
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  },
}));
