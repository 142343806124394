// External library imports
import {Avatar, styled, Typography} from '@mui/material';

export const MuiTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  fontSize:'16px',
  fontWeight:400,
  letterSpacing:'0.5px',
  textAlign: 'start',
  width: 'auto',
  marginLeft: '8px'
}));

export const MuiTypographyLevelUser = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  fontSize:'12px',
  fontWeight:400,
  letterSpacing:'0.4px',
  textAlign: 'start',
  width: 'auto',
  marginLeft: '8px'
}));

export const MuiAvatar = styled(Avatar)(({ theme }) => ({
  cursor: 'pointer',
  width: theme.spacing(4.5),
  height: theme.spacing(4.5),
  marginLeft: theme.spacing(1),
  backgroundColor:'white'
}));
