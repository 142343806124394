import {Fab, styled} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const drawerWidth = 350;

export const MuiNavDrawer = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

export const MuiDrawer = styled("nav")(({ theme }) => ({
  width: drawerWidth,
}));

export const MuiBottomSheetContent = styled("div")(({ theme }) => ({
  minHeight: theme.spacing(18),
  '& > *': {
    paddingBottom: theme.spacing(6),
  },
}));

export const MuiFab = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(5),
  right: theme.spacing(2),
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  transform: `translateY(${theme.spacing(3)}px)`,
  transition: `transform ${theme.transitions.easing.sharp} ${theme.transitions.duration.shortest}ms`,

  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiFab-label': {
    width: theme.spacing(9),
    justifyContent: 'flex-start',
  },

  '&$buttonShow': {
    transform: 'translateY(0)',

    '& $bottomSheetIcon': {
      transform: 'rotate(0)',
    },
  },
}));

export const MuiExpandLessIcon = styled(ExpandLessIcon)(({ theme }) => ({
  color: theme.palette.text.hint,
  height: theme.spacing(4),
  transform: 'rotate(180deg)',
}));
