import {styled} from "@mui/material";

export const MuiDivContainer  = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  maxWidth: '500px',
  maxHeight: '500px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '8px'
}));

export const MuiImage  = styled("img")(({ theme }) => ({
  height: 32,
  width: 'auto',
  objectFit: 'scale-down',
}));

export const orgName ={
  width: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}
