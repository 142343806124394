// External library imports
import { useMutation, useQueryClient } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const newUser = async ({ user }) => {
  await axios.post(apiPaths.create_user, user, status200)
             .then((resp) => resp.data);
};

const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(async (values) => await newUser(values), {
    onSuccess: () => queryClient.resetQueries('users'),
    onError: (error) => error,
    onSettled: () => queryClient.invalidateQueries('users')
  });
};
export default useCreateUser;
