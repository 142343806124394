// External library imports
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'

// Style and asset imports
import {Item} from './styles/common';
import {
  gridItemStyle,
  typographyStyle
} from "./styles/regimes";

export default function Regimes({ regimes, affectedVars, onRegimesChange }) {

  const addRegime = () => {
    let localRegimes = [...regimes];
    localRegimes.push({
      regime_label: '',
      slider_definition: {
        slider_label: '',
        symbol: '',
        min: 0,
        max: 0,
        'default': 0
      },
      effects: []
    });

    onRegimesChange(localRegimes);
  }
  const removeRegime = (index) => {
    regimes.splice(index, 1);
    onRegimesChange(regimes)
  }

  const addRegimeEffect = (regime, index) => {
    regime.effects.push({
      variable: '',
      effect: ''
    })

    regimes[index] = regime
    onRegimesChange(regimes)
  }

  const removeRegimeEffect = (regimeIndex, effectIndex) => {
    regimes[regimeIndex].effects.splice(effectIndex, 1)
    onRegimesChange(regimes)
  }

  const getAffectedVarOpts = (regime) => {
    const selectedVars = regime.effects.map(d => d.variable).filter(d => d);
    return affectedVars.map(d => {
      return {
        value: d.name,
        text: d.name,
        disabled: selectedVars.indexOf(d.name) > -1
      }
    });
  }

  const getEffectOptions = (variable) => {
    const av = affectedVars.find(d => d.name === variable);
    const effects = av ? av.effects : [];
    return effects.map(d => d.label)
  }

  const onChangeRegime = (event, regime, regimeIndex, objKey) => {
    if (objKey) {
      regime[objKey][event.target.name] = event.target.value
    } else {
      regime[event.target.name] = event.target.value
    }

    regimes[regimeIndex] = regime
    onRegimesChange(regimes)
  }

  const onChangeRegimeEffects = (e, regime, regimeIndex, effect, effectIndex) => {
    effect[e.target.name] = e.target.value
    regime.effects[effectIndex] = effect

    regimes[regimeIndex] = regime
    onRegimesChange(regimes)
  }

  const handleKeyDown = (event) => {
    const { value } = event.target;
    if (value.length >= 80 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  return (
    <Grid container spacing={2}>
      {regimes.map((regime, regimeIndex) => (
        <Grid item xs={12} key={regimeIndex}>
          <Item>
            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={11}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                      id="regime-label"
                      label="Label"
                      value={regime.regime_label}
                      onChange={(e) => onChangeRegime(e, regime, regimeIndex)}
                      name="regime_label"
                      inputProps={{ maxLength: 80 }}
                      onKeyDown={handleKeyDown}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => removeRegime(regimeIndex)}>
                  <DeleteForeverTwoToneIcon style={{ color: '#fd7676' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3}>
                <Typography style={typographyStyle}>Slider
                  definition</Typography>
              </Grid>
              <Grid item xs={8} style={gridItemStyle}>
                <Item>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          label="Slider label"
                          value={regime.slider_definition.slider_label}
                          onChange={(e) => onChangeRegime(e, regime, regimeIndex, 'slider_definition')}
                          name="slider_label"
                          inputProps={{ maxLength: 80 }}
                          onKeyDown={handleKeyDown}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          select
                          label="Slider symbol"
                          value={regime.slider_definition.symbol}
                          onChange={(e) => onChangeRegime(e, regime, regimeIndex, 'slider_definition')}
                          variant="outlined"
                          name="symbol">
                          <MenuItem key={'$'} value={'$'}>$</MenuItem>
                          <MenuItem key={'%'} value={'%'}>%</MenuItem>
                          <MenuItem key={'✓/x'} value={'✓/x'}>✓/x</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="effect-label"
                          label="Min"
                          name="min"
                          type='number'
                          value={regime.slider_definition.min}
                          onChange={(e) => onChangeRegime(e, regime, regimeIndex, 'slider_definition')}
                          InputProps={{ inputProps: { min: 0 } }}
                          onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="effect-label"
                          label="Max"
                          name="max"
                          type='number'
                          onChange={(e) => onChangeRegime(e, regime, regimeIndex, 'slider_definition')}
                          value={regime.slider_definition.max}
                          InputProps={{ inputProps: { min: 0 } }}
                          onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="effect-label"
                          label="Default"
                          name="default"
                          type='number'
                          onChange={(e) => onChangeRegime(e, regime, regimeIndex, 'slider_definition')}
                          value={regime.slider_definition.default}
                          InputProps={{ inputProps: { min: 0 } }}
                          onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3}>
                <Typography style={typographyStyle}>Effects</Typography>
              </Grid>
              <Grid item xs={9} style={gridItemStyle}>
                {regime.effects.map((effect, effectIndex) => (
                  <Item key={effectIndex}>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item xs={11}>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            select
                            label="Affected variable"
                            value={effect.variable}
                            onChange={(e) => onChangeRegimeEffects(e, regime, regimeIndex, effect, effectIndex)}
                            name="variable">
                            {getAffectedVarOpts(regime).map((v) => (
                              <MenuItem key={v.value}
                                value={v.value} disabled={v.disabled}>{v.text}</MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        {effect.variable !== 'cost' && (
                          <IconButton
                            onClick={() => removeRegimeEffect(regimeIndex, effectIndex)}>
                            <DeleteForeverTwoToneIcon style={{ color: '#fd7676' }} />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      {effect.variable && (
                        <Grid item xs={11}>
                          <FormControl fullWidth>
                            <TextField
                              select
                              style={{ marginTop: 8 }}
                              label="Effect"
                              value={effect.effect}
                              onChange={(e) => onChangeRegimeEffects(e, regime, regimeIndex, effect, effectIndex)}
                              variant="outlined"
                              name="effect">
                              {getEffectOptions(effect.variable).map((v) => (
                                <MenuItem key={v} value={v}>{v}</MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Item>
                ))}
                <Item>
                  <Grid container spacing={1}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={3}>
                      <Button size="small"
                        onClick={() => addRegimeEffect(regime, regimeIndex)}>
                        <AddIcon /> Add Effect
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      ))}
      <Grid container item xs={12} justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => addRegime()}
          type="button">
          + Add regime
        </Button>
      </Grid>
    </Grid>
  )
}
