// External library imports
import React from 'react';
import Box from '@mui/material/Box';

const padding = { padding: 0 }

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={padding}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
