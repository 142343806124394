// External library imports
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { select } from 'd3-selection';
import tippy from 'tippy.js';

// Internal component and function imports
import patternify from '../../../utils/patternify.js';

const getDomain = (data) => {
  const maxVal = max(data, (d) => d.value);
  if (maxVal === 0) {
    return [0, 1];
  }
  return [0, maxVal];
};

const Statistic = ({ container, data, title, width, visible, id }) => {
  let group,
    rects,
    labelWidth = 15;

  let scale = scaleLinear()
    .domain(getDomain(data))
    .range([0, width - labelWidth])
    .clamp(true);

  const main = () => {
    group = patternify(container, 'xhtml:div', 'stat-group' + id)
      .style('display', visible ? null : 'none')
      .classed('statistic-group', true);

    patternify(group, 'xhtml:div', 'stat-title').text(title);

    addBars();

    return main;
  }

  function addBars() {
    rects = patternify(group, 'xhtml:div', 'stat-rect-group', data);

    const statRect = patternify(rects, 'xhtml:div', 'stat-rect', (d) => [d])
      .style('width', (d) => Math.max(1, scale(d.value)) + 'px')
      .style('background', (d) => d.color);

    patternify(rects, 'xhtml:div', 'stat-text', (d) => [d]).text((d) => d.valueText);

    statRect.each(function (d) {
      if (this._tippy) {
        this._tippy.destroy(true);
      }

      tippy(this, {
        theme: 'light-border',
        appendTo: document.body,
        arrow: true,
        delay: 0,
        content: d.name + d.valueText,
        placement: 'left',
      });
    });
  }

  main.updateColor = function (color) {
    rects.each(function (d, i) {
      if (i === 0) {
        d.color = color;

        select(this)
          .select('.stat-rect')
          .style('background', (d) => d.color);
      }
    });

    return main;
  };

  main.update = (newData) => {
    data = newData;
    scale.domain(getDomain(data));
    addBars();
    return main;
  };

  main.show = () => {
    visible = true;
    group.style('display', null);
    return main;
  };

  main.hide = () => {
    visible = false;
    group.style('display', 'none');
    return main;
  };

  return main();
}

export default Statistic;
