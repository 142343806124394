// External library imports
import { useMutation, useQueryClient } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const newScheme = (data) => axios.post(apiPaths.create_scenario, data, status200)
                                 .then((resp) => resp.data);;

const useCreateScheme = () => {
  const queryClient = useQueryClient();

  return useMutation( (values) => newScheme(values), {
    onSuccess: () => queryClient.resetQueries('schemes'),
    onError: (error) =>  error,
    onSettled: () => queryClient.invalidateQueries('schemes')
  });
};
export default useCreateScheme;
