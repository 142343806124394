// Internal component and function imports
import { status200 } from './status.utils';
import axios from './axios/axiosInstance';
import apiPaths from './apiPaths';

const resetPassword = (data, onError, onSuccess) => {
  const url = `${apiPaths.reset_password}${data.token}`;

  axios.post( url,
    {password: data.password, token: data.token},
    status200
  )
    .then((resp) => onSuccess(resp.data))
    .catch((e) => onError(e.error));
}

export default resetPassword;
