export const divInitStyle = {
  height: '100%'
}

export const svgStyle = {
  fontFamily: 'sans-serif',
  display: 'inline',
  height: '100%',
  marginLeft:'35%'
}
