// External library imports
import { drag } from 'd3-drag';
import { pointer, select } from 'd3-selection';

// Internal component and function imports
import patternify from '../../utils/patternify.js';
import getCustomLabel from '../../utils/custom-labels';

const renderSlider = (node) => {
  let width = node.width || 400;
  let height = node.height || 8;
  let container = node.container;
  let sliderGroup, circleGroup;
  let sections = node.sections || [];
  let scale = node.scale;
  let bands = scale.domain();
  let bandWidth = scale.bandwidth();
  let showSliderLabel = node.showSliderLabel;

  let slider, backRect;

  let onChange = function () {};

  const main = () => {
    // calculation parameters
    let translation = [0, -height / 2],
      backRectWidth = width,
      backRectHeight = 30,
      trackWidth = width,
      trackHeight = 8;

    sliderGroup = container
      .append('g')
      .attr('class', 'slider')
      .attr('transform', `translate(${node.x || 0}, ${node.y || 0})`)
      .attr('opacity', node.visible ? 1 : 0)
      .attr('pointer-events', node.visible ? 'all' : 'none');

    slider = sliderGroup.append('g').attr('transform', `translate(${translation})`);

    backRect = slider
      .append('rect')
      .attr('width', backRectWidth)
      .attr('height', backRectHeight)
      .attr('y', -backRectHeight / 2)
      .attr('x', 0)
      .attr('fill', 'transparent');

    slider
      .append('rect')
      .attr('width', trackWidth)
      .attr('height', trackHeight)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('x', 0)
      .attr('y', -4)
      .attr('fill', '#E1E5EC')
      .attr('cursor', 'pointer')
      .on('click', slide);

    addSections();

    return main;
  }

  const addSections = () => {
    circleGroup = patternify(slider, 'g', 'slider-thumb', sections)
      .attr('transform', (d) => `translate(${scale(d.value) + bandWidth}, 0)`)
      .call(drag().on('drag', slide));

    patternify(circleGroup, 'circle', 'slider-thumb-circle', (d) => [d])
      .attr('r', 11)
      .attr('fill', '#fff')
      .attr('stroke', '#ddd')
      .attr('cursor', 'pointer');

    patternify(circleGroup, 'text', 'slider-thumb-text', (d) => [d])
      .attr('text-anchor', 'middle')
      .text((d) => (showSliderLabel ? getCustomLabel(d.value, sections) : ''))
      .attr('y', 4)
      .attr('font-size', '10px')
      .attr('pointer-events', 'none');
  }

  function slide(d) {
    if (typeof d !== 'object') return;

    let cx = pointer(backRect.node())[0];
    let tempIndex = 0;

    if (cx < 0) {
      cx = 0;
      tempIndex = 0;
    } else if (cx > width) {
      cx = width;
      tempIndex = bands[bands.length - 1];
    }

    let index = Math.floor(cx / scale.bandwidth());

    d.value = bands[index] || bands[tempIndex];

    let x = scale(d.value) + bandWidth;
    let y = 0;

    select(this).attr('transform', `translate(${x},${y})`);
    circleGroup
      .selectAll('.slider-thumb-text')
      .text((x) => (showSliderLabel ? getCustomLabel(x.value, sections) : ''));

    onChange();
  }

  main.update = (_sections) => {
    sections = _sections;

    addSections();
  };

  main.onChange = (f) => {
    onChange = f;
    return main;
  };

  main.show = () => {
    node.visible = true;
    sliderGroup
      .attr('opacity', node.visible ? 1 : 0)
      .attr('pointer-events', node.visible ? 'all' : 'none');
  };

  main.hide = () => {
    node.visible = false;
    sliderGroup
      .attr('opacity', node.visible ? 1 : 0)
      .attr('pointer-events', node.visible ? 'all' : 'none');
  };

  main.updateSliderLabel = (show) => {
    showSliderLabel = show;
    patternify(circleGroup, 'text', 'slider-thumb-text', (d) => [d]).text((d) =>
      showSliderLabel ? getCustomLabel(d.value, sections) : ''
    );
  };

  return main();
}

export default renderSlider;
