// External library imports
import {sum} from 'd3-array';

// Internal component and function imports
import roundNumber from '../../../utils/round-number';
import {formatStatistic} from '../../../utils/formatters';
import {colors} from '../../../utils/colors';

// additional statistics calculations
/* eslint-disable */
/**
 * Filters data accordint to section value
 * @param {Array} data
 * @param {Number} section
 * @param {String} priority
 */
const sectionFilter = (data, section, priority) => {
  return data.filter((d) => {
    return priority === 'low' ? d.criteria <= section : d.criteria > section;
  });
};

export default function GetStat(
  data,
  section,
  statColumn,
  statType,
  priority = 'low',
  preOrPost = 'pre'
) {
  const statField = `${preOrPost}_${statColumn}`;
  const sectionData = sectionFilter(data, section, priority);
  const criteria = preOrPost === 'pre' ? 'sum' : 'current';

  const _count = sum(sectionData, (d) => d[criteria]);
  const _sum = sum(sectionData, (d) => d[statField]);
  const _count_total = sum(data, (d) => d[criteria]);
  const _sum_total = sum(data, (d) => d[statField]);

  if (statType === 'count') return _count;

  if (statType === 'sum') return _sum;

  if (statType === 'percentage_count') return (100 * _count) / (_count_total || 1);

  if (statType === 'percentage_sum') return (100 * _sum) / (_sum_total || 1);

  if (statType === 'mean') return _count ? _sum / _count : 0;

  if (statType === 'poverty_gap') {
    const mean = _count ? _sum / _count : 0;

    if (section > 0) {
      let poverty_gap;

      if (priority === 'low') {
        // calculate poverty gap
        poverty_gap = (100 * (section - mean)) / section;
      }

      if (priority === 'high') {
        // calculate poverty gap
        poverty_gap = (100 * (mean - section)) / section;
      }

      return poverty_gap;
    }

    return 1;
  }

  if (statType === 'FGT1') {
    if (_count_total === 0) {
      return 1;
    }

    const mean= _count ? _sum / _count : 0;
    if (section > 0) {
      let poverty_gap;

      if (priority === 'low') {
        poverty_gap =  (section - mean) / section;
      }

      if (priority === 'high') {
        poverty_gap = (mean - section) / section;
      }

      return 100*poverty_gap*(_count/_count_total);
    }

    return 1;
  }

  if (statType === 'gini') return calculateGini(sectionData,statField,criteria);

  return 0;
}

function calculateGini(sectionData, incomeField, weightField) {
  /**
   * Calculates the Gini coefficient of a given section of data.
   *
   * @param {Array} sectionData - The data to calculate the Gini coefficient for.
   * @param {string} incomeField - The field in the data representing income.
   * @param {string} weightField - The field in the data representing weight.
   * @return {number} The calculated Gini coefficient.
   *
   * References:
   * - Capitulo IV Estimation of the Gini Index: Gastwirth, J. L. (1972). The estimation of the Lorenz curve and Gini index. The Review of Economics and Statistics, 306-316.
   * - Seccion 2.3. The Gini Coeficient: Yitzhaki, S., & Schechtman, E. (2013). The Gini Methodology: A Primer on a Statistical Methodology. Springer.
   */
  // Validate and set undefined or NaN income to 0
  sectionData.forEach(d => {
    if (typeof d[incomeField] !== 'number' || isNaN(d[incomeField])) {
      d[incomeField] = 0;
    }
  });
  // Step 1: Summation of weighted income and weight sum within each bin
  const totalIncome = sectionData.map(d=> d[incomeField]).reduce((acc, bin) => acc + bin, 0);
  const totalWeight = sectionData.reduce((acc, bin) => acc + bin[weightField], 0);

  // Step 2: Initialization of cumulative sum arrays
  let cumulativeIncomeSum = 0;
  let cumulativeWeightSum = 0;
  const cumulativeProportionIncome = [];
  const cumulativeProportionWeight = [];

  // Step 3: Compute cumulative proportions for income and weight values
  // For each bin, we update the running cumulative sums of income and weight,
  // then calculate and store the cumulative proportions of income and weight.
  // Cumulative proportion is the ratio of the cumulative sum up to the current
  // bin to the total sum.
  for (let i = 0; i < sectionData.length; i++) {
    cumulativeIncomeSum += sectionData[i][incomeField];
    cumulativeWeightSum += sectionData[i][weightField];
    cumulativeProportionIncome.push(cumulativeIncomeSum / totalIncome);
    cumulativeProportionWeight.push(cumulativeWeightSum / totalWeight);
  }

  // Step 4: Calculate the Gini coefficient using the provided formula
  //Now we calculate the area (A) between the Lorenz Curve and the perfect inequality curve (45 degree line)
  //as the difference between the area under de curve of perfect inequality and the area under the Lorenz curve (B)
  //The Area under the perfect inequality curve is Always 0.5 (since b=1 and h=1), so we initialize A with 0.5
  // And we substract the area of each trapezoid that forms the area of B.
  let A = 0.5;
  let total_area_b=0;
  for (let i = 1; i < sectionData.length; i++) {
    //Compute the area of the trapezoid associated with each section of the data
    let area=Math.abs(cumulativeProportionWeight[i]-cumulativeProportionWeight[i-1])*Math.abs(cumulativeProportionIncome[i]+cumulativeProportionIncome[i-1])/2;
    // The area of B is the sum of the areas of the trapezoids
    // eslint-disable-next-line
    total_area_b+=area;
    // Substract the area of the trapezoid from the area under the perfect inequality
    A-=area;
  }
  //The Gini coefficient is the difference between the area under the equality curve and B divided by 0.5, in summary
  let giniCoefficient = A / 0.5;
  return 100*giniCoefficient;
}

export const getStatsData = ({ statistics, currentValue, criteriaPriority, data, color }) => {
  const statsData = [];

  if (statistics && statistics.length) {
    statistics.forEach((s) => {
      let sectionValue;

      if (s.section === -1) {
        // dynamic selection
        sectionValue = currentValue;
      } else if (s.section === -2) {
        // full range
        let values = data.map(d=> {
         return d.criteria;
        });
        sectionValue = criteriaPriority === 'low' ? Math.max.apply(null,values): Math.min.apply(null,values);
      } else {
        sectionValue = s.section;
      }

      const pre = GetStat(data, sectionValue, s.stat_var, s.statistic, criteriaPriority, 'pre');

      const post = GetStat(data, sectionValue, s.stat_var, s.statistic, criteriaPriority, 'post');

      const preStat = {
        stat_name: s.label,
        color: colors.notAccepted,
        value: pre,
        textColor: '#000',
        valueText: pre < 1 && pre > 0 ? roundNumber(pre, 2) : formatStatistic(roundNumber(pre, 2)),
        name: 'Pre policy: ',
      };

      const postStat = {
        stat_name: s.label,
        color: color,
        value: post,
        textColor: '#fff',
        valueText:
          post > 0 && post < 1 ? roundNumber(post, 2) : formatStatistic(roundNumber(post, 2)),
        name: 'Post policy: ',
      };

      const showPrePost = s.show_pre_or_post || 'both';

      const stats_array = [];

      if (showPrePost === 'both') {
        stats_array.push(postStat, preStat);
      } else if (showPrePost === 'pre') {
        stats_array.push(preStat);
      } else if (showPrePost === 'post') {
        stats_array.push(postStat);
      }

      statsData.push(stats_array);
    });
  }

  return statsData;
}
