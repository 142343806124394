import {Tabs, Container, Toolbar, Paper, styled} from "@mui/material";

export const MuiContainer = styled(Container)(({ theme }) => ({
  maxWidth: '100%',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  marginRight: 'unset',
  '&.MuiContainer-root': {
    maxWidth: '100%',
  },
}));

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: 'white',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
}));

export const MuiPaperTabsContainer = styled(Paper)(({ theme }) => ({
  borderTop: 0,
  borderRight: 0,
  borderLeft: 0,
  width: '100%',
  paddingLeft: '48px',
  zIndex: 1,
}));

export const MuiTabs = styled(Tabs)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  '& .Mui-selected': {
    fontSize: 15,
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'font-size 10ms, font-weight 10ms',
  },
}));
