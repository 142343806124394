// External library imports
import { select } from 'd3-selection';
import _ from 'lodash';

// Internal component and function imports
import patternify from '../../utils/patternify.js';

export default function addSections({ chart, sections, xScale, height, margin, translateY }) {
  let dx = 5;
  let dy = translateY !== undefined ? translateY : -30;
  let initialY = 10;
  let cloneSections = _.cloneDeep(sections)

  let textParams = cloneSections.map((d) => {
    return {
      ...d,
      y: initialY,
      anchor: 'end',
    };
  });

  chart.selectAll('.section').remove();

  let sectionsDom = patternify(chart, 'g', 'section', cloneSections)
    .lower()
    .sort((a, b) => a.value - b.value)
    .attr('transform', (d) => `translate(${xScale(d.value) + xScale.bandwidth()}, ${dy})`);

  patternify(sectionsDom, 'line', 'section-line', (d) => [d])
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 5)
    .attr('y2', height - margin.bottom + 15)
    .attr('stroke', '#ccc')
    .attr('stroke-width', '1px');

  patternify(sectionsDom, 'text', 'section-text', (d) => [d])
    .attr('x', -dx)
    .attr('y', initialY)
    .attr('text-anchor', 'end')
    .text((d) => d.name)
    .attr('font-size', '10px')
    .attr('pointer-events', 'none');

  sectionsDom.each(function (d, i) {
    let txt = select(this).select('.section-text').node();
    let width = txt.getBoundingClientRect().width;
    let anchor = textParams[i].anchor;

    if (width > 0) {
      textParams[i].width = width;
      d.width = width;
    } else if (!textParams[i].width) {
      // ბოლო იმედი :დ
      const ctx = document.createElement('canvas').getContext('2d');
      ctx.font = '10px sans-serif';
      textParams[i].width = ctx.measureText(d.name).width;
    }

    textParams[i].x = xScale(d.value) + xScale.bandwidth() + (anchor === 'start' ? dx : -dx);
  });

  textParams.forEach(function (d, i) {
    if (i > 0) {
      let current = d;
      let prev = textParams[i - 1];
      let range0 = [];
      let range1 = [];

      range0 = prev.anchor === 'start' ? [prev.x, prev.x + prev.width]
        : [prev.x - prev.width, prev.x];

      range1 = current.anchor === 'start' ? [current.x, current.x + current.width]
        : [current.x - current.width, current.x];

      // if current one overlaps previus one and previous one is not adjusted yet
      if (
        (range1[0] <= range0[1] && range1[0] >= range0[0]) ||
        (range1[1] <= range0[1] && range1[0] >= range0[0])
      ) {
        if (!prev.adjusted) {
          current.y -= 12;
          current.adjusted = true;
        }
      }
    }
  });

  sectionsDom.each(function (_, i) {
    let y = textParams[i].y;
    let anchor = textParams[i].anchor;

    let text = select(this).select('.section-text');
    let line = select(this).select('.section-line');

    text.attr('y', y);
    line.attr('y1', y - 4);

    patternify(select(this), 'line', 'tick-line', (d) => [d])
      .attr('x1', 0)
      .attr('x2', anchor === 'start' ? dx - 1 : -dx + 1)
      .attr('y1', y - 4)
      .attr('y2', y - 4)
      .attr('stroke', '#ccc')
      .attr('stroke-width', '1px');
  });

  return sectionsDom;
}
