// External library imports
import { useQuery } from 'react-query';

// API and Utility imports
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchTranslations = () =>
  axios.get(apiPaths.translations, status200)
       .then((resp) => resp.data);

const useTranslations = () =>
  useQuery('translations', fetchTranslations, {
    retry: 3,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 'Infinity',
    refetchInterval: false,
  });

export default useTranslations;
