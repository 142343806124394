// React and External Libraries
import * as React from 'react';

// MUI Styles and Theming
import {
  colors,
  createTheme,
  responsiveFontSizes,
  ThemeProvider
} from '@mui/material';

const criteriaThemeOptions = {
  palette: {
    primary: {
      main: colors.blue[800],
    },
    secondary: {
      main: colors.blue[500],
    },
  },
  typography: {
    fontFamily: '"Montserrat"',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
  },
};

const ThemeContext = React.createContext();

const CustomThemeProvider = ({ children }) => {
  const [isCustomColor, setIsCustomColor] = React.useState(true);
  const [primary, setPrimary] = React.useState(colors.blue[900]);
  const [secondary, setSecondary] = React.useState(colors.blue[500]);

  const customTheme = createTheme(({
    ...criteriaThemeOptions,
    palette: {
      ...criteriaThemeOptions.palette,
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
  }));

  const theme = responsiveFontSizes(customTheme);

  const setSwitchColor = React.useCallback(() => {
    setThemeActive(!isCustomColor);
    setIsCustomColor(!isCustomColor);
  }, [isCustomColor]);
  const setThemeActive = (active) => localStorage.setItem('themeActive', active);
  const getThemeActive = () => localStorage.getItem('themeActive');

  const setColorsInLocalStorage = React.useCallback((primary, secondary) => {
    localStorage.setItem('colorPrimary', primary);
    localStorage.setItem('colorSecondary', secondary);
  }, []);

  const setCustomColors = React.useCallback(
    (primaryColor, secondaryColor) => {
      setSecondary(secondaryColor);
      setPrimary(primaryColor);
      setColorsInLocalStorage(primaryColor, secondaryColor);
    },
    [setColorsInLocalStorage, isCustomColor]
  );

  const getColorsInLocalStorage = React.useCallback(
    () => [localStorage.getItem('colorPrimary'), localStorage.getItem('colorSecondary')],
    []
  );

  const removeColor = React.useCallback(() => {
    localStorage.removeItem('colorPrimary');
    localStorage.removeItem('colorSecondary');
  }, []);

  React.useEffect(() => {
    const colorsSaved = getColorsInLocalStorage();
    if (colorsSaved && colorsSaved[0]) {
      setPrimary(colorsSaved[0]);
      setSecondary(colorsSaved[1]);
    } else {
      setPrimary(colors.blue[900]);
      setSecondary(colors.blue[500]);
    }
  }, [getColorsInLocalStorage]);

  React.useEffect(() => {
    setIsCustomColor(getThemeActive() === 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = React.useMemo(
    () => ({
      setColors: setCustomColors,
      setSwitchColor: setSwitchColor,
      isCustomColor,
      removeColor,
      theme,
    }),
    [setCustomColors, setSwitchColor, isCustomColor, removeColor, theme]
  );

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => React.useContext(ThemeContext);
export { ThemeContext, useTheme };

export default CustomThemeProvider;
