// External library imports
import {
  Grid,
  lighten,
  Skeleton,
  styled,
  Table,
  TablePagination,
  TextField
} from '@mui/material';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Internal component and function imports
import DeleteIcon from '../DeleteIcon';
import EditIcon from '../EditIcon';
import SettingIcon from '../SettingIcon';
import DownloadIcon from "../DownloadIcon";
import {DatePicker} from "@mui/x-date-pickers";

export const MuiTableBase = styled(Table)(({ theme }) => ({
  minWidth: 650,
}));

export const MuiGridContainerButtons = styled(Grid)(({ theme }) => ({
  minWidth: 60,
  maxWidth: '100%',
  justifyContent: 'flex-center',
  gap: '8px'
}));

export const MuiButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 60,
}));

export const MuiDonutSmallIcon = styled(DonutSmallOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '34px',
  width: '34px',
  padding: 4,
  borderRadius:'50%',
  border: `1px solid ${lighten(theme.palette.primary.main, 0.6)}`,
  align: 'center',
  cursor:'pointer',
}));

export const MuiEditIcon = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '34px',
  width: '34px',
  padding: 4,
  border: `1px solid ${lighten(theme.palette.primary.main, 0.7)}`,
  borderRadius:'50%',
}));

export const MuiMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: '#757575',
  height: '34px',
  width: '34px',
  padding: 4,
  border: `1px solid ${lighten('#757575', 0.7)}`,
  borderRadius:'50%',
}));

export const MuiDownloadIcon = styled(DownloadIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '34px',
  width: '34px',
  padding: 4,
  border: `1px solid ${lighten(theme.palette.primary.main, 0.7)}`,
  borderRadius:'50%',
}));

export const MuiSettingIcon = styled(SettingIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '34px',
  width: '34px',
  padding: 4,
  border: `1px solid ${lighten(theme.palette.primary.main, 0.7)}`,
  borderRadius:'50%',
  align: 'center',
  cursor:'pointer'
}));

export const MuiDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: '#F63B2B',
  height: '34px',
  width: '34px',
  padding: 4,
  borderRadius:'50%',
  border: `1px solid ${lighten('#F63B2B', 0.7)}`,
  align: 'center',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: lighten('#F63B2B', 0.7),
  },
  backgroundColor: 'transparent'
}));

export const MuiAlarmOffIcon = styled(AlarmOffIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height:'34px',
  width:'34px',
  padding: 4,
  marginLeft: theme.spacing(3),
  align: 'center',
}));

export const gridStyle = {
  display: 'none'
}

export const tableCellActionsStyle = {
  padding:0,
}

export const demoContainerStyle = {
  width: '250px !important',
  paddingTop:'0px',
  paddingBottom:'5px',
  overflow:'none'
}
export const MuiDatePicker = styled(DatePicker)(({ theme }) => ({
  width: 145,
  marginLeft:'-10px',
  '& .MuiInputBase-root': {
    height: '28px',
    borderRadius:'4px',
  },
  '& .MuiInputBase-input': {
    padding: '5px 8px 5px 8px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor:'#BDBDBD',
    borderRadius: '4px 0px 0px 4px',
    borderRight:'none',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#BDBDBD',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#BDBDBD',
  },
  '& .MuiInputLabel-root': {
    color: '#BDBDBD',
    top:'-9px',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontFamily:'Montserrat',
    letterSpacing:'1.5px'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    top: '0px !important',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px',
  },
  '& .MuiButtonBase-root:hover': {
    backgroundColor: 'transparent'
  },
  '& input': {
    fontSize: '10px',
    fontFamily:'Montserrat',
  },
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(-0.5),
  marginBottom: theme.spacing(0),
  marginLeft:'-10px',
  '& .MuiInputBase-root': {
    height: '28px',
    borderRadius:'4px',
    borderColor:'#757575'
  },
  '& .MuiInputBase-input': {
    padding: '5px 8px 5px 8px',
  },
  '& input::placeholder': {
    fontSize: '10px',
    textTransform: 'uppercase',
    fontFamily:'Montserrat',
    letterSpacing:'1.5px'
  },
  '& input': {
    fontSize: '16px',
    fontFamily:'Montserrat',
  },
}));

export const MuiTablePagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiSelect-select': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiTablePagination-displayedRows': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiToolbar-root': {
    paddingLeft:'0px',
  }
}));

export const actionsComponentStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight:'650px'
}


export const iconStyle = {
  fontSize:'32px'
}
