// External library imports
import * as React from 'react';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const SettingIcon = ({ onClick, className }) => (
  <IconButton
    color='primary'
    className={className}
    data-cy='table_common_settings_action'
    onClick={onClick}
  >
    <SettingsIcon />
  </IconButton>
);

export default SettingIcon;
