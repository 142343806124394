import {Card, Button, styled} from "@mui/material";

export const MuiCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const boxStyle = {
  flexGrow: 1,
  maxWidth: '100%'
}

export const divStyle = {
  display: "flex",
  marginTop: 30,
  justifyContent: "flex-end"
}
