import {Container, styled} from "@mui/material";
import { TextField } from '../../../../common/ReactHooksFormFields';

export const MuiContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0,2),
  marginLeft: theme.spacing(-3),
}));

export const MuiTexInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '400px',
    height: '56px',
  },
  '& .MuiInputLabel-root': {
    marginTop:'5px',
    fontSize: '16px',
    fontFamily:'Montserrat',
    letterSpacing:'0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily:'Montserrat',
    letterSpacing:'0.4px'
  },
  '& input': {
    fontSize: '16px',
    fontFamily:'Montserrat',
  },
  '& legend': {
    fontSize: '14px',
  },
}));

export const selectRoleStyle = {
  '& .MuiInputBase-root': {
    width: '400px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily:'Montserrat',
    letterSpacing:'0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily:'Montserrat',
    letterSpacing:'0.4px'
  },
  '& legend': {
    fontSize: '12px',
    fontFamily:'Montserrat',
  },
}

export const optionLabelStyle = {
  fontFamily: 'Montserrat',
  fontSize: '16px',
  letterSpacing: 0.5
}
