// External library imports
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// Internal component and function imports
import { useTranslation } from 'components/providers/TranslationProvider';
import useDeleteOrganization from 'api/hooks/useDeleteOrganization';
import ModalDelete from './common/ModalDelete';

const DeleteOrganization = () => {
  const { t } = useTranslation();
  const deleteOrganization = useDeleteOrganization();
  const location = useLocation();

  const handleSubmit = () => {
    const org = {
      data: {
        org_id: location.state._id,
      },
    };
    deleteOrganization.mutateAsync(org);
  };

  return (
    <ModalDelete
      deleteMutation={deleteOrganization}
      onSubmit={handleSubmit}
      content={t('delete_organization')}
      name={location.state?.name}
      isOrg
    />
  );
};

export default DeleteOrganization;
