// External library imports
import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useDispatch, useSelector} from 'react-redux';
import {Grid, Tab, Tabs } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-18-compat-sortable-hoc';
import swap from 'lodash-move';

// Internal component and function imports
import {setActivePoliciesTab} from '../../../../../store/appSlice';
import {getAggrPolicy, getNormalPolicy} from '../../../../../utils/template';
import Policy from './form/Policy';
import {
  customAddPolicyButtonStyle,
  customDeleteIconStyle,
  dragHandleStyle,
  MuiAddIcon,
  MuiBoxTabs,
  MuiButton,
  MuiCommonTypography,
  MuiDeleteIcon,
  MuiDragHandleIcon,
  MuiGridTabContent,
  MuiPaperContainer,
  MuiQuestionText, MuiTabNameText, tabStyle
} from "../styles/policies";
import {useTheme} from "../../../../providers/CustomThemeProvider";
import {useTranslation} from "../../../../providers/TranslationProvider";

const Policies = ({
                    aggregation,
                    policies,
                    variables,
                    setPolicies,
                    setAggregation
                  }) => {
  const [items, setItems] = useState(null);
  const [aggrPolicyAdded, setAggrPolicyAdded] = React.useState(false)
  const [activePolicy, setActivePolicy] = useState(0);
  const dispatch = useDispatch()
  const activeTab = useSelector(state => state.app.activePoliciesTab)

  const {t} = useTranslation();
  const {theme} = useTheme();

  const onChange = (newActiveKey, newValue) => {
    const policyIndex = policies.findIndex(p => p.id === newValue)
    setActivePolicy(policyIndex);
    dispatch(setActivePoliciesTab(newValue))
  };

  const add = () => {
    const id = uuidv4();
    let allPolicies = [...policies];
    let newPolicy = {
      id,
      tree_state: null,
      cost_bar_title: '',
      pos: policies.length + 1,
      type: 'normal',
    };

    if (policies[0] && policies[0].type !== 'aggregation') {
      newPolicy = getNormalPolicy(newPolicy);
      const lastPolicy = policies[policies.length - 1];
      newPolicy = {
        ...newPolicy,
        segmentation_variables: JSON.parse(JSON.stringify(lastPolicy.segmentation_variables)),
        prioritization: JSON.parse(JSON.stringify(lastPolicy.prioritization)),
      }
    } else {
      newPolicy = getNormalPolicy(newPolicy)
    }

    allPolicies.push(newPolicy);
    setPolicies(allPolicies);
    setActivePolicy(allPolicies.length - 1);
    dispatch(setActivePoliciesTab(newPolicy.id));
  };

  const remove = (targetKey) => {
    if (Object.keys(aggregation).length > 0 && aggregation.id === targetKey) {
      setAggrPolicyAdded(false)
      setAggregation({})
      return
    }

    let indexToDelete = policies.findIndex(item => item.id === targetKey);
    let localPolicies = [...policies]
    localPolicies.splice(indexToDelete, 1);
    setPolicies(localPolicies);
    let newActivePolicyIndex = activePolicy;
    if (activePolicy >= localPolicies.length || activePolicy === indexToDelete) {
      newActivePolicyIndex = Math.max(0, localPolicies.length - 1);
      dispatch(setActivePoliciesTab(localPolicies[newActivePolicyIndex]?.id))
      setActivePolicy(newActivePolicyIndex);
    }
    setPolicies(prevPolicies => prevPolicies.filter((item) => item.id !== targetKey));
  };

  const handlePolicyChange = (pol) => {
    const policyIndex = policies.findIndex(p => p.id === pol.id)
    setPolicies(prevPolicies => {
      prevPolicies[policyIndex] = pol

      return [...prevPolicies]
    })
  }

  const handleAggregationPolicyChange = (pol) => setAggregation({...pol});

  const addAggregationPolicy = () => {
    setAggrPolicyAdded(true)
    let templatePolicy = {}
    if (policies && policies.length > 0) {
      templatePolicy.prioritization = policies[0].prioritization
    }
    setAggregation(getAggrPolicy(templatePolicy))
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    let localPolicies = [...policies];
    setPolicies(swap(localPolicies, oldIndex, newIndex));
  };

  const customTabsStyle = {
    height: '100%',
    width: '100%',
    '& .MuiTabs-indicator': {
      maxWidth: 3,
      height: '28px !important',
      marginTop: '15px',
      borderRadius: '2px',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  }

  const DragHandle = sortableHandle(({id}) => (
    <DragHandleIcon
      color='primary'
      style={{
        ...dragHandleStyle,
        color: id === activeTab ? theme.palette.primary.main : '#757575'
      }}
    />
  ));

  const SortableTabs = SortableContainer(({children}) => {
    return (
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeTab || policies[0]?.id || null}
        aria-label="Vertical tabs example"
        sx={customTabsStyle}
      >
        {children}
      </Tabs>
    );
  });

  const SortableTab = SortableElement(({value, label}) => (
    <Tab
      onChange={onChange}
      value={value}
      key={value}
      label={label}
      style={{
        ...tabStyle,
        background: value === activeTab ? '#FAFAFA' : '#FFFFFF'
      }}
    />
  ));

  const renderTabsPolicies = (tab, index) =>
    <SortableTab
      value={tab.id}
      key={tab.id}
      index={index}
      label={
        <Grid container direction='column'
              id={'tab-label-container'}
              alignItems="center">
          <Grid container item direction='row'
                alignItems='center' alignContent='center'>
            <Grid item xs={1}>
              <DragHandle id={tab.id}/>
            </Grid>
            <Grid
              container
              direction='column'
              item
              xs={true}
            >
              <Grid item id='question-name'>
                <MuiTabNameText
                  sx={{color: tab.id === activeTab ? theme.palette.primary.main : '#757575'}}
                >
                  {tab.name}
                </MuiTabNameText>
              </Grid>
            </Grid>
            <Grid container item xs={2} justifyContent='center'
                  alignContent='center'>
              <MuiDeleteIcon
                sx={customDeleteIconStyle}
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  remove(tab.id)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    />

  const renderTabAggregation = () =>
    <SortableTab
      value={aggregation.id}
      key={aggregation.id}
      index={policies.length}
      label={
        <Grid container direction='column'
              id={'tab-label-container'}
              alignItems="center">
          <Grid container item direction='row'
                alignItems='center' alignContent='center'>
            <Grid item xs={1}>
              <MuiDragHandleIcon/>
            </Grid>
            <Grid
              container
              direction='column'
              item
              xs={true}
            >
              <Grid item id='question-name'>
                <MuiTabNameText
                  sx={{
                    color: aggregation.id === activeTab ? theme.palette.primary.main : '#757575'
                  }}
                >
                  {aggregation.name}
                </MuiTabNameText>
              </Grid>
            </Grid>
            <Grid container item xs={2} justifyContent='center'
                  alignContent='center'>
              <MuiDeleteIcon
                sx={customDeleteIconStyle}
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  remove(aggregation.id)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    />

  React.useEffect(() => {
    const renderPolicies = policies.length > 0 && activePolicy !== -1
    const renderAggregationPolicy = aggregation && Object.keys(aggregation).length > 0 && activePolicy === -1

    if (renderPolicies) {
      setItems(
        <Policy
          policy={policies[activePolicy]}
          policyType={'normal'}
          variables={variables}
          handlePolicyChange={(p) => handlePolicyChange(p)}
        />
      )
    } else if (renderAggregationPolicy) {
      setItems(
        <Policy
          policy={aggregation}
          policyType={'aggr'}
          variables={variables}
          handlePolicyChange={(p) => handleAggregationPolicyChange(p)}
        />
      )
      setAggrPolicyAdded(true)
    }
  }, [aggregation, policies, activePolicy]);

  return (
    <MuiPaperContainer id={'open-question-global-cont'}>
      {policies.length > 0 ? (
        <Grid id={'boxContainer'} container item direction={'row'} xs={12}
              wrap={'nowrap'} sx={{width: '100%', maxWidth: '100%'}}>
          <Grid item xs={'auto'} sx={{width: '342px !important'}}>
            <MuiQuestionText>
              {t('policy')}
            </MuiQuestionText>
            <MuiBoxTabs id={'box-tabs'}>
              <SortableTabs onSortEnd={onSortEnd} useDragHandle>
                {policies.map((tab, index) => (renderTabsPolicies(tab, index)))}
                {aggregation && Object.keys(aggregation).length > 0 && (renderTabAggregation())}
              </SortableTabs>
              <MuiButton
                variant='outlined'
                color='primary'
                startIcon={<MuiAddIcon/>}
                onClick={add}
              >
                <MuiCommonTypography variant='body2'>
                  {t('add_policy')}
                </MuiCommonTypography>
              </MuiButton>
              <MuiButton
                sx={{marginTop: '10px'}}
                disabled={!(policies.length > 0 && !aggrPolicyAdded)}
                data-cy='btn-add-aggregation-policy'
                variant="outlined"
                color="primary"
                startIcon={<MuiAddIcon/>}
                type="Submit"
                onClick={() => addAggregationPolicy()}>
                <MuiCommonTypography variant='body2'>
                  {t('add_aggregation_policy')}
                </MuiCommonTypography>
              </MuiButton>
            </MuiBoxTabs>
          </Grid>
          <MuiGridTabContent container item id={'tab-content'} xs={true}>
            {items}
          </MuiGridTabContent>
        </Grid>
      ) : (
        <MuiButton
          sx={customAddPolicyButtonStyle}
          variant='outlined'
          color='primary'
          startIcon={<MuiAddIcon/>}
          onClick={add}
        >
          <MuiCommonTypography variant='body2'>
            {t('add_policy')}
          </MuiCommonTypography>
        </MuiButton>
      )}
    </MuiPaperContainer>
  );
};

export default Policies;
